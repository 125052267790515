import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { of } from 'rxjs';
import { UserType } from '../../../../../../../types/misc';
import { confirmPasswordValidator } from '../../../common/utils';
import { BaseDetailsCoreInfoComponent } from '../base-details/base-details-core-info/base-details-core-info.component';
import { QuickbooksCardData } from '../base-details/base-details-quickbooks-sync/base-details-quickbooks-sync.component';
import { UserManagementService } from '../services/user-management.service';

interface IUserUpdateForm {
  userId: number;
  username: string;
  user_type: UserType;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}
@Component({
  selector: 'app-user-details-page',
  templateUrl: './user-details-page.component.html',
  styleUrls: ['./user-details-page.component.scss']
})
export class UserDetailsPageComponent implements OnInit {
  mode: 'read' | 'edit' = 'read';
  username: string;
  userType: UserType;
  updateUserForm: FormGroup = this.formBuilder.group({});
  isSyncingWithQuickbooks = false;

  userData: IUserUpdateForm;

  quickbooksData: QuickbooksCardData;

  @ViewChild(BaseDetailsCoreInfoComponent)
  baseDetailsCore: BaseDetailsCoreInfoComponent;

  constructor(
    private userManagementService: UserManagementService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private nzMessageService: NzMessageService
  ) {}

  ngOnInit(): void {
    this.username = this.activeRoute.snapshot.params.username;

    this.updateUserForm = this.formBuilder.group({
      username: ['', Validators.required],
      user_type: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: [''],
      email: [''],
      password: [undefined],
      confirmPassword: [undefined, [confirmPasswordValidator]]
    });

    this.updateUserForm.disable();

    this.loadUserData();
    this.setFormFieldsToRequired();
  }

  deleteUser() {
    if (confirm('Are you sure you want to delete this user?')) {
      this.userManagementService.deleteUser(this.userData.userId).subscribe(
        (isUserDeleted: boolean) => {
          if (isUserDeleted) {
            this.nzMessageService.success(
              `Delete user ${this.username} success`
            );
            this.router.navigate(['../..'], { relativeTo: this.activeRoute });
          } else {
            this.nzMessageService.error('Unable to delete user');
          }
        },
        (error) => {
          this.nzMessageService.error('Unable to delete user');
        }
      );
    }
  }

  loadUserData() {
    this.userManagementService.getUser(this.username).subscribe(
      (userDetails) => {
        const userData = userDetails?.data;

        this.userData = {
          userId: userData?.user_id,
          username: userData?.username,
          user_type: userData?.user_type,
          firstName: userData?.first_name,
          lastName: userData?.last_name,
          email: userData?.email,
          phoneNumber: userData?.phone_number
        };

        this.userType = userData?.user_type || null;

        this.resetUpdateUserDataForm();
      },
      (error) => {
        this.router.navigate(['../..'], { relativeTo: this.activeRoute });
        this.nzMessageService.error('The user does not exist');
      }
    );
  }

  setFormFieldsToRequired() {
    if (this.userType === 'buyer') {
      this.updateUserForm.get('email').setValidators(Validators.required);
    }
  }

  synchronizeWithQuickbooks() {
    this.isSyncingWithQuickbooks = true;
    this.userManagementService
      .synchronizeUserWithQuickbooks({
        ...this.userData,
        username: this.username
      })
      .subscribe(() => {
        this.nzMessageService.success('Sync success');
        this.loadUserData();
        this.isSyncingWithQuickbooks = false;
      });
  }

  resetUpdateUserDataForm() {
    this.updateUserForm.get('username').setValue(this.userData.username);
    this.updateUserForm.get('user_type').setValue(this.userData.user_type);
    this.updateUserForm.get('firstName').setValue(this.userData.firstName);
    this.updateUserForm.get('lastName').setValue(this.userData.lastName);
    this.updateUserForm.get('email').setValue(this.userData.email);
    this.updateUserForm.get('phoneNumber').setValue(this.userData.phoneNumber);
  }

  updateUserData() {
    if (this.updateUserForm.valid) {
      let username: string;
      let userType: UserType;
      let password: string;
      let firstName: string;
      let lastName: string;
      let email: string;
      let phoneNumber: string;
      let quickbooksVendorUrl: string;

      if (this.userData.username !== this.updateUserForm.value.username) {
        username = this.updateUserForm.value.username;
      }

      if (this.userData.user_type !== this.updateUserForm.value.user_type) {
        userType = this.updateUserForm.value.user_type;
      }

      if (this.updateUserForm.value.password) {
        password = this.updateUserForm.value.password;
      }

      if (this.userData.firstName !== this.updateUserForm.value.firstName) {
        firstName = this.updateUserForm.value.firstName;
      }

      if (this.userData.lastName !== this.updateUserForm.value.lastName) {
        lastName = this.updateUserForm.value.lastName;
      }

      if (this.userData.email !== this.updateUserForm.value.email) {
        email = this.updateUserForm.value.email;
      }

      if (this.userData.phoneNumber !== this.updateUserForm.value.phoneNumber) {
        phoneNumber = this.updateUserForm.value.phoneNumber;
      }

      const allInputFields = [
        username,
        userType,
        password,
        firstName,
        lastName,
        email,
        phoneNumber,
        quickbooksVendorUrl
      ];
      if (allInputFields.every((element) => element === undefined)) {
        // No data has changed. Do not make any backend invokation
        return of({});
      }

      return this.userManagementService
        .updateUser(this.userData.userId, {
          username,
          user_type: userType,
          password,
          firstName,
          lastName,
          email,
          phoneNumber
        })
        .subscribe((_result) => {
          this.nzMessageService.success(`User ${this.username} Updated`);
          this.baseDetailsCore.switchCoreInformationMode();
        });
    } else {
      if (this.updateUserForm.errors) {
        this.nzMessageService.error('Invalid Form');
        return;
      }

      for (const controlName in this.updateUserForm.controls) {
        const control = this.updateUserForm.controls[controlName];
        if (control.errors) {
          this.nzMessageService.error(`${controlName} is invalid`);
          break;
        }
      }
    }
  }

  // updateUserData() {
  //   this.userManagementService.updateUser(this.userData.userId, this.updateUserForm.value).subscribe(() => {
  //     this.nzMessageService.success('Update user success');
  //   });
  // }
}
