import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerInvoiceForAdminReport } from '../../../../../../../types/invoice';
import { environment } from '../../../../environments/environment';
import { AbstractApiService } from '../../../services/abstract-api.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerInvoicesService extends AbstractApiService {
  adminApiUrl = environment.baseUrl + environment.apiUrl + '/admin';

  constructor(private http: HttpClient) {
    super();
  }

  getCustomerServicesReport(_page = 0) {
    return this.http.get<CustomerInvoiceForAdminReport[]>(
      this.adminApiUrl + '/invoices',
      {
        headers: this.headers
      }
    );
  }
}
