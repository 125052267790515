import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { CanActivateRouteGuardConfig } from './auth.types';

@Injectable()
export class CanActivateUserRoute implements CanActivate {
  constructor(
    @Inject('CAN_ACTIVATE_ROUTE_GUARD_CONFIG')
    private config: CanActivateRouteGuardConfig,
    private authService: AuthService,
    private router: Router
  ) {
    //this.authService.getUserState$().subscribe((userState) => {});
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const userState = this.authService.getUserState$().value;

    if (!userState) {
      this.router.navigate([this.config.signInRoute], {
        queryParams: { returnUrl: state.url }
      });
      return false;
    }

    const userType = userState.userType;

    // const isValidRoute = this.config.isValidUserRoute
    //   ? this.config.isValidUserRoute(userType, route.routeConfig.path)
    //   : route.routeConfig.path === userType;

    // if (!isValidRoute) {
    //   const userRoute = this.config.getUserRoute(userType);
    //   this.router.navigateByUrl(userRoute);
    //   return false;
    // }

    if (
      userType &&
      route.routeConfig.path !== userType

      // route.routeConfig.path !== userType &&
      // route.routeConfig.path !== userType + '-desktop'
    ) {
      this.router.navigateByUrl('user/' + userType);
    } else {
      return true;
    }
  }
}
