import { Component, Injector, OnInit } from '@angular/core';
import { Deal } from '../../../../../../types/deal';
// import { PurchaseOrderSummary } from '../../../../types/purchase-order';
import { AbstractOkaTable } from '../abstract-oka-table';

@Component({
  selector: 'oka-active-deals-table',
  templateUrl: './active-deals-table.component.html',
  styleUrls: ['../oka-tables-styles.scss']
})
export class ActiveDealsTablesComponent
  extends AbstractOkaTable<Deal>
  implements OnInit
{
  navPath = 'deals';
  currentSearchColumn: keyof Deal = 'deal_id';

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
