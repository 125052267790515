import { inject } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

export const isUserNotAuthenticated = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const userState = authService.getUserState$().value;
  if (userState) {
    // router.navigate(['/']);
    return false;
  } else {
    return true;
  }
};
