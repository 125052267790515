import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UserType } from '../../../../../../../../types/misc';
import { ModalComponent } from '../../../../common/modal-common/modal.component';
import { UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'app-create-new-user-modal',
  templateUrl: './create-new-user-modal.component.html',
  styleUrls: ['./create-new-user-modal.component.scss']
})
export class CreateNewUserModalComponent
  extends ModalComponent
  implements OnInit, AfterViewInit
{
  title = 'Create New User';
  createNewUserForm: FormGroup = this._formBuilder.group({});
  userTypes = ['admin', 'shadchan'];
  genderTypes = ['male', 'female'];
  highlightInvalidFields = false;

  constructor(
    nzModal: NzModalService,
    private nzMessageService: NzMessageService,
    private _formBuilder: FormBuilder,
    private userManagementService: UserManagementService
  ) {
    super(nzModal);
  }

  ngAfterViewInit(): void {
    this.openModal({}, () => {
      if (this.createNewUserForm.valid) {
        this.onCreate().subscribe(() => {
          this.nzMessageService.success(
            `New user ${this.createNewUserForm.get('username').value} Created`
          );
          this.modal.close();
        });
      } else {
        const errorControls = this.getFormValidationErrors();
        this.nzMessageService.error(
          `Invalid fields ${errorControls.join(', ')}`
        );
      }
    });
  }

  ngOnInit(): void {
    this.createNewUserForm = this._formBuilder.group({
      userType: ['', Validators.required],
      gender: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', [Validators.required, confirmPasswordValidator]],
      email: [undefined, Validators.email],
      phoneNumber: [
        undefined,
        [Validators.minLength(10), Validators.pattern('[0-9]*$')]
      ]
    });
  }

  onCreate() {
    const userType: UserType = this.createNewUserForm.value
      .userType as UserType;
    const username = this.createNewUserForm.value.username;
    const password = this.createNewUserForm.value.password;
    const firstName = this.createNewUserForm.value.firstName;
    const lastName = this.createNewUserForm.value.lastName;
    const email = this.createNewUserForm.value.email;
    const phoneNumber = this.createNewUserForm.value.phoneNumber;
    const gender = this.createNewUserForm.value.gender;

    return this.userManagementService.createUser(
      username.toLocaleLowerCase(),
      password,
      userType,
      firstName,
      lastName,
      email,
      phoneNumber,
      gender
    );
  }

  getFormValidationErrors() {
    const errorControls: string[] = [];

    Object.keys(this.createNewUserForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors =
        this.createNewUserForm.get(key).errors;
      if (controlErrors != null) {
        errorControls.push(key);
      }
    });

    return errorControls;
  }
}

const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('confirmPassword');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};
