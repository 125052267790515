import { Component, Injector, OnInit } from '@angular/core';
import { Profile } from '../../../../../../../../types/profile';
import { GenderToggleService } from '../../../../services/gender-toggle.service';
import { SearchService } from '../../../../services/search-service.service';
import { SortService } from '../../../../services/sort.service';
import { ShadchanService } from '../../services/shadchan.service';
import { BasePage } from '../../../page';
import { Subject, Subscription } from 'rxjs';
import { SortByCategory } from '../../../../../../../../common/constants';

@Component({
  selector: 'app-profiles-page-base',
  template: ''
})
export class ProfilesPageBaseComponent extends BasePage implements OnInit {
  isLoading = false;

  profiles: Profile[] = [];
  profilesFiltered: Profile[] = [];
  searchTerm = '';

  private searchInputSubject = new Subject<string>();

  // FILTERS
  filterAge = [0, 100];

  // Services
  protected genderService: GenderToggleService;
  protected searchService: SearchService;
  protected sortService: SortService;
  protected shadchanService: ShadchanService;

  // Subscriptions
  searchServiceSubscription: Subscription;
  sortServiceSubscription: Subscription;
  genderServiceSubscription: Subscription;

  constructor(protected injector: Injector) {
    super(injector);

    this.genderService = injector.get(GenderToggleService);
    this.searchService = injector.get(SearchService);
    this.sortService = injector.get(SortService);
    this.shadchanService = injector.get(ShadchanService);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.shadchanService.getProfiles().subscribe((p) => {
      this.profiles = p;
      this.profilesFiltered = p;
      this.isLoading = false;
    });

    this.searchServiceSubscription = this.searchService.searchEvent.subscribe(
      (e) => {
        if (e.type === 'cancel') {
          this.profilesFiltered = this.profiles;
        }
        if (e.type === 'submit') {
          this.shadchanService
            .generalSearch(e.data)
            .subscribe((results) => (this.profilesFiltered = results));
        }
      }
    );

    this.sortServiceSubscription = this.sortService.sortEvent.subscribe((e) => {
      this.sortProfiles(e.category, e.direction);
    });

    this.genderServiceSubscription =
      this.genderService.genderToggleEvent.subscribe((e) => {
        console.log('Gender service!', e);
        this.refilterProfilesByGender(e.category);
      });
  }

  onGenderFilterValueChange() {}

  ngOnDestroy(): void {
    this.searchInputSubject.complete(); // Complete the subject to avoid memory leaks
    this.genderServiceSubscription.unsubscribe();
    this.searchServiceSubscription.unsubscribe();
    this.sortServiceSubscription.unsubscribe();
  }

  onSearchInputChange(): void {
    this.searchInputSubject.next(this.searchTerm);
  }

  protected refilterProfilesByGender(genderMode: 'male' | 'female' | 'all') {
    if (genderMode === 'all') {
      this.profilesFiltered = this.profiles;
    } else {
      this.profilesFiltered = this.profiles.filter(
        (profile) => profile.gender === genderMode || !profile.gender
      );
    }
  }

  sortProfiles(sortCategory: SortByCategory, direction: 'asc' | 'dsc') {
    this.profilesFiltered = this.profilesFiltered.sort((a, b) => {
      if (sortCategory === 'name') {
        const lastNameComparison = a.last_name.localeCompare(b.last_name);

        // If last names are the same, compare first names
        if (lastNameComparison === 0) {
          return direction === 'asc'
            ? a.first_name.localeCompare(b.first_name)
            : b.first_name.localeCompare(a.first_name);
        }

        // Otherwise, return comparison result based on direction
        return direction === 'asc' ? lastNameComparison : -lastNameComparison;
      } else if (sortCategory === 'age') {
        // Convert date_of_birth to Date objects for comparison
        const dateA = new Date(a.date_of_birth);
        const dateB = new Date(b.date_of_birth);

        // Compare dates
        if (dateA < dateB) {
          return direction === 'asc' ? -1 : 1;
        } else if (dateA > dateB) {
          return direction === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      }
    });
  }
}
