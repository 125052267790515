import { Component, Injector, OnInit } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { ProfilesPageBaseComponent } from '../profiles-page-base/profiles-page-base.component';

@Component({
  selector: 'shdchn-profiles-page-desktop',
  templateUrl: './profiles-page-desktop.component.html',
  styleUrl: './profiles-page-desktop.component.scss',
  animations: [
    trigger('fadeInSlideIn', [
      state(
        'void',
        style({
          opacity: 0,
          transform: 'translateX(-20px)' // Slide in from slightly to the left
        })
      ),
      transition(':enter, :leave', [
        animate('300ms ease-out') // Fade in and slide in quickly
      ])
    ]),
    trigger('fadeInOut', [
      state(
        '*',
        style({
          opacity: 1
        })
      ),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [animate('200ms ease-out', style({ opacity: 0 }))])
    ])
  ]
})
export class ProfilesPageDesktopComponent
  extends ProfilesPageBaseComponent
  implements OnInit
{
  isSiderCollapsed = false;

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
