import { Component } from '@angular/core';
import { ShadchanService } from '../../services/shadchan.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ActivatedRoute, Router, RouterConfigOptions } from '@angular/router';
import { ProfileFull } from '../../../../../../../../types/profile';

// profile.component.ts
import { OnInit } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { EditableField } from '../../../../../../../core/src/lib/models/core.types';

interface Profile {
  name: string;
  age: number;
  location: string;
  occupation: string;
  education: string;
  about: string;
  photos: string[];
  interests: string[];
  preferences: {
    ageRange: [number, number];
    location: string;
    education: string;
    religiousLevel: string;
  };
}

@Component({
  selector: 'shdchn-profile-page-desktop',
  templateUrl: './profile-page-desktop.component.html',
  styleUrl: './profile-page-desktop.component.scss'
})
export class ProfilePageDesktopComponent implements OnInit {
  profile: Profile = {
    name: 'John Doe',
    age: 28,
    location: 'New York, NY',
    occupation: 'Software Engineer',
    education: 'Masters in Computer Science',
    about: 'I love hiking and exploring new places...',
    photos: [
      'https://as2.ftcdn.net/jpg/00/65/21/89/1000_F_65218950_8gGgy60Q1BFQvz13RLAJOCBtydFwJxFH.jpg',
      'assets/photo2.jpg'
    ],
    interests: ['Hiking', 'Reading', 'Travel'],
    preferences: {
      ageRange: [25, 35],
      location: 'New York Area',
      education: "Bachelor's or higher",
      religiousLevel: 'traditional'
    }
  };

  editingName = false;
  editingOccupation = false;
  editingEducation = false;
  editingAbout = false;
  uploadUrl = 'your-upload-endpoint';
  fileList: NzUploadFile[] = [];

  profileId: number;
  profileFull: ProfileFull = {} as ProfileFull;

  personalFields: EditableField[] = [
    {
      key: 'occupation',
      title: 'Occupation',
      type: 'text',
      value: 'Software Engineer',
      maxLength: 100
    },
    {
      key: 'education',
      title: 'Education',
      type: 'select',
      value: 'masters',
      options: [
        { value: 'highschool', label: 'High School' },
        { value: 'bachelors', label: "Bachelor's Degree" },
        { value: 'masters', label: "Master's Degree" },
        { value: 'phd', label: 'PhD' }
      ]
    },
    {
      key: 'about',
      title: 'About',
      type: 'textarea',
      value: 'I love hiking and exploring new places...',
      maxLength: 500,
      rows: 4
    }
  ];

  constructor(
    private message: NzMessageService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    // private injector: Injector,
    private apiService: ShadchanService,
    private nzMessageService: NzMessageService
  ) {}

  ngOnInit() {
    this.profileId = this.activeRoute.snapshot.params.profileId;
    this.loadProfile();
  }

  loadProfile() {
    this.apiService.getFullProfile(this.profileId).subscribe(
      (result) => {
        console.log('Profile result:', result);
        this.profileFull = result;
      },
      (error) => {
        console.error('Error', error);
      }
    );
  }

  onOk(event) {}
  onDismiss() {}

  handlePhotoChange(info: any) {
    if (info.file.status === 'done') {
      this.message.success(`${info.file.name} uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.message.error(`${info.file.name} upload failed.`);
    }
  }

  handleFieldSave(event: { key: string; value: any }) {
    console.log(`Saving ${event.key} with value:`, event.value);
    // Implement your save logic here
  }

  savePreferences() {
    // Implement save logic here
    this.message.success('Preferences saved successfully');
  }
}
