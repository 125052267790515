import { Component, Input, OnInit } from '@angular/core';
import { ProductInstanceDetails } from '../../../../../../types/product';

@Component({
  selector: 'oka-purchase-order-product-item',
  templateUrl: './purchase-order-product-item.component.html',
  styleUrls: ['./purchase-order-product-item.component.scss']
})
export class PurchaseOrderProductItemComponent implements OnInit {
  @Input() purchaseOrderItem: ProductInstanceDetails;

  constructor() {}

  ngOnInit(): void {
    console.log('purchaseOrderItem', this.purchaseOrderItem);
  }
}
