import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { HttpResponse, UserType } from '../../../../../../../types/misc';
import { environment } from '../../../../environments/environment';

const BASE_URL = environment.baseUrl + environment.apiUrl + '/admin';

@Injectable({ providedIn: 'root' })
export class UserManagementService {
  userManagementApiUrl = BASE_URL + '/user';

  headers = {
    'Content-Type': 'application/json'
  };

  constructor(private http: HttpClient) {}

  createUser(
    username: string,
    password: string,
    userType: UserType,
    firstName: string,
    lastName: string,
    email?: string,
    phoneNumber?: string,
    gender?: string
  ) {
    const data = {
      username: username,
      password: password,
      user_type: userType,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      gender: gender
    };

    return this.http.put<any>(this.userManagementApiUrl, data, {
      headers: this.headers
    });
  }

  updateUser(userId: number, formData: any) {
    let data = {
      id: userId,
      username: formData?.username,
      user_type: formData?.user_type,
      password: formData?.password,
      first_name: formData?.firstName,
      last_name: formData?.lastName,
      email: formData?.email,
      phone_number: formData?.phone_number,
      gender: formData?.gender
    };

    // Remove undefined keys from the obejct
    Object.keys(data).forEach((key: string) => {
      if (data[key] === undefined) {
        delete data[key];
      }
    });

    return this.http.post<any>(this.userManagementApiUrl, data, {
      headers: this.headers
    });
  }

  getUser(username: string) {
    return this.http.get<any>(
      this.userManagementApiUrl + `?username=${username}`,
      { headers: this.headers }
    );
  }

  getUsers() {
    return this.http.get(BASE_URL + '/users');
  }

  deleteUser(userId: number): Observable<boolean> {
    const url = this.userManagementApiUrl + '/' + userId.toString();
    return this.http
      .delete<HttpResponse<boolean>>(url, {
        headers: this.headers
      })
      .pipe(map((result) => result.data));
  }

  getBuyers() {
    return this.http
      .get(BASE_URL + '/buyers')
      .pipe(
        map(
          (b) =>
            b as { user_id: number; first_name: string; last_name: string }[]
        )
      );
  }

  synchronizeUserWithQuickbooks(userData: any) {
    // Remove undefined keys from the obejct
    Object.keys(userData).forEach((key: string) => {
      if (userData[key] === undefined) {
        delete userData[key];
      }
    });

    return this.http.put(BASE_URL + '/vendor', userData).pipe();
  }
}
