<nz-card [nzTitle]="cardTitle">
  <nz-descriptions [nzColumn]="1">
    <nz-descriptions-item *ngFor="let field of fields" [nzTitle]="field.title">
      <ng-container *ngIf="!editingField[field.key]; else fieldEdit">
        <span (click)="startEditing(field.key)" class="editable-value">
          {{ getDisplayValue(field) }}
        </span>
      </ng-container>

      <ng-template #fieldEdit>
        <div class="edit-field-container">
          <!-- Text Input -->
          <ng-container *ngIf="field.type === 'text'">
            <nz-input-group [nzSuffix]="suffixTemplate">
              <input
                nz-input
                [[(ngModel)]="field.value"
                [maxlength]="field.maxLength"
                (blur)="saveField(field)"
              />
            </nz-input-group>
          </ng-container>

          <!-- Textarea -->
          <ng-container *ngIf="field.type === 'textarea'">
            <nz-textarea-count [nzMaxCharacterCount]="field.maxLength || 500">
              <textarea
                nz-input
                [[(ngModel)]="field.value"
                [rows]="field.rows || 4"
                (blur)="saveField(field)"
              ></textarea>
            </nz-textarea-count>
          </ng-container>

          <!-- Select -->
          <ng-container *ngIf="field.type === 'select'">
            <nz-select
              [[(ngModel)]="field.value"
              (ngModelChange)="saveField(field)"
              style="width: 100%"
            >
              <nz-option
                *ngFor="let opt of field.options"
                [nzValue]="opt.value"
                [nzLabel]="opt.label"
              >
              </nz-option>
            </nz-select>
          </ng-container>

          <!-- Number -->
          <ng-container *ngIf="field.type === 'number'">
            <nz-input-number
              [[(ngModel)]="field.value"
              (ngModelChange)="saveField(field)"
              style="width: 100%"
            >
            </nz-input-number>
          </ng-container>

          <!-- Date -->
          <ng-container *ngIf="field.type === 'date'">
            <nz-date-picker
              [[(ngModel)]="field.value"
              (ngModelChange)="saveField(field)"
              style="width: 100%"
            >
            </nz-date-picker>
          </ng-container>
        </div>
      </ng-template>
    </nz-descriptions-item>
  </nz-descriptions>

  <ng-template #suffixTemplate>
    <i nz-icon nzType="check" class="save-icon" (click)="saveField(field)"></i>
  </ng-template>
</nz-card>
