import { RouterModule, Routes } from '@angular/router';
import { SignInPageComponent } from './sign-in-page/sign-in-page.component';
import { ForgotPasswordPageComponent } from './forgot-password-page/forgot-password-page.component';
import { ResetPasswordPageComponent } from './reset-password-page/reset-password-page.component';
import { RegisterPageComponent } from './register-page/register-page.component';
import { VerifyPageComponent } from './verify-page/verify-page.component';
import { NgModule } from '@angular/core';
import { isUserNotAuthenticated } from './can-activate-auth-route.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [isUserNotAuthenticated],
    children: [
      {
        path: 'sign-in',
        component: SignInPageComponent,
        canActivate: [isUserNotAuthenticated]
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordPageComponent,
        canActivate: [isUserNotAuthenticated]
      },
      {
        path: 'reset-password',
        component: ResetPasswordPageComponent,
        canActivate: [isUserNotAuthenticated]
      },
      {
        path: 'register',
        component: RegisterPageComponent,
        canActivate: [isUserNotAuthenticated]
      },
      {
        path: 'verify',
        component: VerifyPageComponent,
        canActivate: [isUserNotAuthenticated]
      },
      {
        path: '',
        redirectTo: 'sign-in',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: []
})
export class AuthRoutingModule {}
