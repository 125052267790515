<core-item-details-page-desktop
  [headerTitle]="profileFull.first_name + ' ' + profileFull.last_name"
  [headerSubtitle]="
    (profileFull.date_of_birth | age) +
    ' years • ' +
    profileFull.city +
    ', ' +
    profileFull.country
  "
>
  <core-item-photo-display-desktop
    photos
    [photos]="[profileFull.profile_photo_url]"
    [uploadUrl]="''"
    [fileList]="fileList"
    (photoChange)="handlePhotoChange($event)"
  >
  </core-item-photo-display-desktop>

  <core-item-info-display-desktop
    personalInfo
    [cardTitle]="'Personal Information'"
    [fields]="personalFields"
    (fieldSaved)="handleFieldSave($event)"
  >
  </core-item-info-display-desktop>
</core-item-details-page-desktop>
