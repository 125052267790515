import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { IUserState } from '../../../../../types/auth';
import { LocalStorageService } from '../services/local-storage.service';
import {
  RegisterUserRequestBody,
  RegisterUserResponseBody
} from '../../../../../types/user';
import { AuthConfig } from './auth.types';
import { HttpResponse } from '../models/http.types';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userState$ = new BehaviorSubject<IUserState | null>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private localStorageService: LocalStorageService,
    @Inject('AUTH_CONFIG') private config: AuthConfig
  ) {}

  register(payload: RegisterUserRequestBody) {
    return this.http.put<HttpResponse<RegisterUserResponseBody>>(
      this.config.baseUrl + '/auth/register',
      payload
    );
  }

  resetPassword(username: string, password: string, token: string) {
    return this.http.post<HttpResponse<any>>(
      this.config.baseUrl + '/auth/reset-password',
      {
        username,
        password,
        token
      }
    );
  }

  forgotPassword(username: string) {
    return this.http.post<HttpResponse<any>>(
      this.config.baseUrl + '/auth/forgot-password',
      {
        username
      }
    );
  }

  reverify(username: string) {
    return this.http.post<HttpResponse<any>>(
      this.config.baseUrl + '/auth/reverify',
      {
        username: username.toLocaleLowerCase()
      }
    );
  }

  verify(username: string, verificationCode: string) {
    return this.http.post<HttpResponse<any>>(
      this.config.baseUrl + '/auth/verify',
      {
        username: username.toLocaleLowerCase(),
        verification_code: verificationCode
      }
    );
  }

  signIn(username: string, password: string): Observable<IUserState> {
    if (this.config.env_name === 'frontend-dev') {
      // const mockUserState: IUserState = {
      //   isLoggedIn: true,
      //   userType: 'standard',
      //   username: username,
      //   sessionId: 'mock-session-id'
      // };
      // this.userState$.next(mockUserState);
      // return of(mockUserState);

      const mockUserState: IUserState = {
        isLoggedIn: true,
        userType: 'standard',
        username: 'user1',
        sessionId: '1234567890'
      };
      this.localStorageService.storeInLocalStorage(
        this.config.userStateKey,
        mockUserState
      );
      console.log('Before storing:', this.userState$.value);
      this.userState$.next(mockUserState);
      console.log('After updating:', this.userState$.value);
      return of(mockUserState);
    }

    // FIRST MAKE SURE NO OLD TOKEN IS HANGING AROUND
    this.localStorageService.deleteFromLocalStorage(this.config.userStateKey);
    return this.http
      .post<HttpResponse<any>>(this.config.baseUrl + '/auth/sign-in', {
        username: username.toLocaleLowerCase(),
        password
      })
      .pipe(
        map((loginResponse: any) => {
          let userState: IUserState;
          if (
            'verified_at' in loginResponse.data &&
            loginResponse.data.verified_at !== null
          ) {
            // Set the user type as null
            loginResponse.data.user_type = null;
            userState = {
              isLoggedIn: false,
              userType: null,
              username: username,
              sessionId: null
            };
          } else {
            userState = {
              isLoggedIn: true,
              userType: loginResponse.data.user_type,
              username: username,
              sessionId: loginResponse.data.session_id
            };
            this.localStorageService.storeInLocalStorage(
              this.config.userStateKey,
              userState
            );
            this.userState$.next(userState);
          }
          return userState;
        }),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return throwError(err.error.message);
        })
      );
  }

  signOut() {
    this.http.post(this.config.baseUrl + '/auth/sign-out', {}).subscribe(() => {
      this.localStorageService.deleteFromLocalStorage(this.config.userStateKey);
      this.userState$.next(null);
      this.router.navigate(['/auth']);
    });
  }

  loadUserState() {
    console.log('Loading user state....');
    this.userState$.next(this.getUserState());
  }

  getUserState$() {
    return this.userState$;
  }

  getUserHomePath(userState: IUserState): string {
    return this.config.userHomeUrl(userState);
  }

  private getUserState(): IUserState | null {
    return this.localStorageService.getFromLocalStorage<IUserState>(
      this.config.userStateKey
    );
  }
}
