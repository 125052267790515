import { Component, Injector } from '@angular/core';
import { BasePage } from '../../../page';

@Component({
  selector: 'app-profile-page-wrapper',
  styleUrl: './profile-page-wrapper.component.scss',
  template: `
    <ng-container *ngIf="isMobileScreen$ | async; else desktop">
      <shdchn-profile-page-mobile></shdchn-profile-page-mobile>
    </ng-container>
    <ng-template #desktop>
      <shdchn-profile-page-desktop></shdchn-profile-page-desktop>
    </ng-template>
  `
})
export class ProfilePageWrapperComponent extends BasePage {
  constructor(protected injector: Injector) {
    super(injector);
  }
}
