import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { findKey } from '@common/third-party/micro-dash';
import { Product } from '../../../../../../../types/product';
import { ColumnDefinitions } from '../../../../types-frontend/aggrid-types';
import { ColumnDefs, SearchMeta } from '../../../../types-frontend/misc';
import { ProductColumnHeaders } from '../../../../types-frontend/product';
import { Cacheable, CacheService } from '../../../common/cache.service';
import { OkaModalService } from '../../../common/modal-common/oka-modals.service';
import { tableSortFn } from '../../../common/utils';
import { CreateNewProductCategoryModalComponent } from '../modals/create-new-product-category-modal/create-new-product-category-modal.component';
import { CreateNewProductModalComponent } from '../modals/create-new-product-modal/create-new-product-modal.component';
import { ProductService } from '../services/product.service';

const ADMIN_PRODUCT_PAGE_CACHE = 'ADMIN_PRODUCT_PAGE_CACHE';
@Component({
  selector: 'app-products-page',
  templateUrl: './products-page.component.html',
  styleUrls: ['./products-page.component.scss']
})
export class ProductsPageComponent implements OnInit, Cacheable {
  cache: Product[];
  dataView: 'table' | 'grid' = 'table';

  productsData: Product[] = [];
  productsDataForDisplay: Product[] = [];

  columnHeaders: ProductColumnHeaders[] = [
    'product_id',
    'product_name',
    'product_category',
    'product_primary_photo',
    'product_secondary_photos',
    'upc',
    'sku',
    'product_description',
    'created_at'
  ];
  columnDefs: ColumnDefs<ProductColumnHeaders>[] = [];

  searchValues: SearchMeta<Product> = {};
  searchValue = '';
  currentSearchColumn: keyof Product = 'product_name';
  isSearchFilterApplied = false;

  defaultColDef = { resizable: true };

  showSpinner = false;

  constructor(
    private modalService: OkaModalService,
    private productService: ProductService,
    private cacheService: CacheService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.columnHeaders.forEach((ch) => {
      this.searchValues[ch] = { value: '', visible: false };
    });
  }

  ngOnInit(): void {
    this.generateColumnDefs();
    this.loadProducts();
  }

  createProduct() {
    this.modalService
      .openModal(CreateNewProductModalComponent)
      .subscribe((modalRef) => {
        modalRef.afterClose.subscribe((r) => {
          this.loadProducts();
        });
      });
  }

  createProductCategory() {
    this.modalService
      .openModal(CreateNewProductCategoryModalComponent)
      .subscribe((modalRef) => {});
  }

  loadProducts() {
    const cached = this.cacheService.retrieveFromCache(
      ADMIN_PRODUCT_PAGE_CACHE
    );
    if (cached) {
      this.productsData = cached;
      this.productsDataForDisplay = [...cached];
    } else {
      this.showSpinner = true;
      this.productService.getProducts().subscribe((products) => {
        this.cacheService.addToCache(ADMIN_PRODUCT_PAGE_CACHE, products);
        this.productsData = products;
        this.productsDataForDisplay = [...products];
        this.showSpinner = false;
      });
    }
  }

  // TODO extract to a superclass for reports
  generateColumnDefs() {
    for (const fieldName of this.columnHeaders) {
      this.columnDefs.push({
        headerName: fieldName,
        sortFn: tableSortFn(fieldName)
      });
    }
  }

  onRowClick(productUpc: string) {
    this.router.navigate([productUpc], { relativeTo: this.route });
  }

  refreshCache() {
    this.cacheService.clearCache(ADMIN_PRODUCT_PAGE_CACHE);
    this.loadProducts();
  }

  searchColumn() {
    const header = findKey<SearchMeta<Product>>(
      this.searchValues,
      (i) => i.visible
    );
    this.productsDataForDisplay = [
      ...this.productsDataForDisplay.filter(
        (i) =>
          String(i[header as keyof Product])
            .toLocaleLowerCase()
            .indexOf(this.searchValues[header].value.toLocaleLowerCase()) >= 0
      )
    ];
    this.searchValues[header].visible = false;
    this.isSearchFilterApplied = true;
  }

  searchValuesOpened(isOpened: boolean, headerName: keyof Product) {
    if (isOpened) {
      this.currentSearchColumn = headerName;
      this.searchValues[headerName].visible = true;
    } else {
      this.searchValues[headerName].visible = false;
    }
  }

  resetSearchColumn() {
    this.searchValues[this.currentSearchColumn] = { value: '', visible: false };
    this.productsDataForDisplay = [...this.productsData];
  }

  resetAllSearchFilters() {
    Object.keys(this.searchValues).forEach((k) => {
      this.searchValues[k] = { value: '', visible: false };
    });
    this.productsDataForDisplay = [...this.productsData];
    this.isSearchFilterApplied = false;
  }
}
