import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService<K extends string = string> {
  constructor() {}

  storeInLocalStorage<T>(key: K, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getFromLocalStorage<T>(key: K): T | null {
    const item = localStorage.getItem(key);
    return item ? (JSON.parse(item) as T) : null;
  }

  deleteFromLocalStorage(key: K): void {
    localStorage.removeItem(key);
  }
}
