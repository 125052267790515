import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { getAdvancedSettings } from '../../../../../../../server/controllers/admin';
import { AdvancedSettingName } from '../../../../../../../types/advanced-settings';
import { AdvancedSettingsService } from '../services/advanced-settings.service';

@Component({
  selector: 'app-admin-advanced-settings-page',
  templateUrl: './admin-advanced-settings-page.component.html',
  styleUrls: ['./admin-advanced-settings-page.component.scss']
})
export class AdminAdvancedSettingsPageComponent implements OnInit {
  advancedSettings = {
    quickbooks_optional: false
  };

  constructor(
    private advancedSettingsService: AdvancedSettingsService,
    private notificationService: NzMessageService
  ) {}

  ngOnInit(): void {
    this.loadAdvancedSettings();
  }

  loadAdvancedSettings(forceRefresh = false) {
    this.advancedSettingsService
      .getAdvancedSettings(forceRefresh)
      .subscribe((result) => {
        this.advancedSettings = result;
      });
  }

  onAdvancedSettingUpdate(settingName: AdvancedSettingName, val: any) {
    this.advancedSettingsService
      .setAdvancedSetting(settingName, val)
      .subscribe((result) => {
        this.notificationService.success(
          `${settingName} has been set to ${val}`
        );
      });
  }

  refreshCache() {
    this.loadAdvancedSettings(true);
  }
}
