import { NgModule } from "@angular/core";
import { IconDefinition, IconModule } from "@ant-design/icons-angular";
import {
  LeftOutline,
  LockOutline,
  LockTwoTone,
  LoginOutline,
  LogoutOutline,
  RightOutline,
  UserOutline
} from "@ant-design/icons-angular/icons";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzAutocompleteModule } from "ng-zorro-antd/auto-complete";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzCarouselModule } from "ng-zorro-antd/carousel";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzDescriptionsModule } from "ng-zorro-antd/descriptions";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzListModule } from "ng-zorro-antd/list";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzMessageModule } from "ng-zorro-antd/message";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzNotificationModule } from "ng-zorro-antd/notification";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm"; // warehouse
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { NzSliderModule } from "ng-zorro-antd/slider";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzTypographyModule } from "ng-zorro-antd/typography";
import { NzUploadModule } from "ng-zorro-antd/upload";

export const ngZorroIcons: IconDefinition[] = [
  LockTwoTone,
  UserOutline,
  LeftOutline,
  LoginOutline,
  LockOutline,
  LogoutOutline,
  RightOutline
];

const NGZORRO_MODULES = [
  NzAutocompleteModule,
  NzAlertModule,
  NzAvatarModule,
  NzButtonModule,
  NzCarouselModule,
  NzDatePickerModule,
  NzDrawerModule,
  NzFormModule,
  NzCardModule,
  NzCollapseModule,
  NzDescriptionsModule,
  NzDividerModule,
  NzDropDownModule,
  IconModule,
  NzIconModule,
  NzInputModule,
  NzTypographyModule,
  NzLayoutModule,
  NzPageHeaderModule,
  NzPopconfirmModule,
  NzNotificationModule,
  NzMenuModule,
  NzMessageModule,
  NzModalModule,
  NzPopconfirmModule,
  NzRadioModule,
  NzTabsModule,
  NzToolTipModule,
  NzTableModule,
  NzUploadModule,
  NzSelectModule,
  NzSpaceModule,
  NzGridModule,
  NzCheckboxModule,
  NzInputNumberModule,
  NzListModule,
  NzSliderModule,
  NzSpinModule,
  NzSwitchModule
];
@NgModule({
  declarations: [],
  imports: NGZORRO_MODULES,
  exports: NGZORRO_MODULES
})
export class NgzorroCommonModule {}
