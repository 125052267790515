import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNil } from '@common/third-party/micro-dash';
import { map } from 'rxjs/operators';
import {
  Deal,
  DealAvailableAdmin,
  DealCreation
} from '../../../../../../../types/deal';
import { HttpResponseBody } from '../../../../../../../types/misc';
import { environment } from '../../../../environments/environment';
import { AbstractApiService } from '../../../services/abstract-api.service';

@Injectable({
  providedIn: 'root'
})
export class AdminDealService extends AbstractApiService {
  adminApiUrl = environment.baseUrl + environment.apiUrl + '/admin';

  constructor(private http: HttpClient) {
    super();
  }

  getDealsForAdmin() {
    return this.http.get<Deal[]>(this.adminApiUrl + '/deals', {
      headers: this.headers
    });
    // .pipe(catchError(this.handleApiError));
  }

  getDealForAdmin(dealId: number) {
    if (isNil(dealId)) {
      throw 'Deal Id cannot be undefined';
    }

    return this.http
      .get<HttpResponseBody<DealAvailableAdmin>>(
        this.adminApiUrl + '/deals' + '/' + dealId,
        {
          headers: this.headers
        }
      )
      .pipe(map((d) => d.data));
  }

  createOrEditDeal(dealCreation: DealCreation, dealId?: number) {
    // if dealId is defined, we are editing a deal
    if (dealId) {
      return this.http.post(this.adminApiUrl + '/deal', {
        ...dealCreation,
        id: dealId
      });
    } else {
      return this.http.put(this.adminApiUrl + '/deal', dealCreation);
    }
  }

  deleteDeal(dealId: number) {
    return this.http.delete(this.adminApiUrl + `/deal/${dealId}`);
  }
}
