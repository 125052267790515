import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@core/auth';

@Component({
  selector: 'core-nav-bar',
  templateUrl: './nav-bar-desktop.component.html',
  styleUrls: ['./nav-bar-desktop.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavBarDesktopComponent implements OnInit {
  @Input() navBarMenu: string[] = [];
  username: string;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.authService.getUserState$().subscribe((userState) => {
      if (userState) {
        this.username = userState.username;
      }
    });
  }

  isMobileScreen() {
    return window.innerWidth < 600;
  }

  formatForMenu(menuItem: string) {
    return menuItem
      .split('-')
      .map((i) => i[0].toUpperCase() + i.substring(1))
      .join(' ');
  }

  signout() {
    this.authService.signOut();
  }
}
