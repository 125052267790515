import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PurchaseOrderForAdminReports } from '../../../../../../../../types/purchase-order';
import { AdminPurchaseOrdersService } from '../../services/purchase-orders.service';

@Component({
  selector: 'app-purchase-orders-reports-page',
  templateUrl: './purchase-orders-reports-page.component.html'
})
export class PurchaseOrdersReportsPageComponent implements OnInit {
  purchaseOrdersData: BehaviorSubject<PurchaseOrderForAdminReports[]> =
    new BehaviorSubject([]);

  columnHeaders = [
    'purchase_order_id',
    'name',
    'buyer_name',
    'notes',
    'calculated_total',
    'products_count',
    'created_at',
    'quickbooks_po_url'
  ];

  showSpinner = false;

  constructor(private adminApiService: AdminPurchaseOrdersService) {}

  ngOnInit() {
    this.showSpinner = true;
    this.adminApiService.getPurchaseOrdersReport().subscribe((reportData) => {
      this.purchaseOrdersData.next(reportData);
      this.showSpinner = false;
    });
  }

  exportAsCSV() {}
}
