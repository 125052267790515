import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { AgGridModule } from 'ag-grid-angular';
import { NgzorroCommonModule } from '../../common/ngzorro-common.module';
import { OkaCommonModule } from '../../common/oka-common.module';
import { OkaTablesModule } from '../../tables/oka-tables.module';
import { WarehouseBaseComponent } from './warehouse-base.component';
import { WarehouseHomepageComponent } from './warehouse-homepage/warehouse-homepage.component';
import { TurnOfflineModeOffModalModule } from './modals/turn-offline-mode-off-modal/turn-offline-mode-off-modal.module';
import { TurnOfflineModeOnModalModule } from './modals/turn-offline-mode-on-modal/turn-offline-mode-on-modal.module';
import { RecentScannedPackagesComponent } from './recent-scanned-packages/recent-scanned-packages.component';
import { ScanPackagesComponent } from './scan-packages/scan-packages.component';
import { ScannedUpcSerialComponent } from './scanned-upc-serial/scanned-upc-serial.component';

const WAREHOUSE_COMPONENTS = [
  RecentScannedPackagesComponent,
  ScannedUpcSerialComponent,
  ScanPackagesComponent,
  WarehouseBaseComponent,
  WarehouseHomepageComponent
];
@NgModule({
  declarations: [...WAREHOUSE_COMPONENTS],
  imports: [
    AgGridModule,
    RouterModule,
    CommonModule,
    CoreModule,
    FormsModule,
    NgzorroCommonModule,
    ReactiveFormsModule,

    // Oka Modules
    OkaCommonModule,
    OkaTablesModule,

    /* Modals */
    TurnOfflineModeOffModalModule,
    TurnOfflineModeOnModalModule
  ],
  exports: []
})
export class WarehouseModule {}
