import { ModuleWithProviders, NgModule } from '@angular/core';
import { ItemDetailsPageDesktopComponent } from './item-details-page-desktop/item-details-page-desktop.component';
import { NgzorroCommonModule } from './ngzorro-common.module';
import { ItemInfoDisplayDesktopComponent } from './item-info-display-desktop/item-info-display-desktop.component';
import { CommonModule } from '@angular/common';
import { ItemPhotoDisplayDesktopComponent } from './item-photo-display-desktop/item-photo-display-desktop.component';
import { CoreModuleConfig } from './models/core.types';
import {
  LOCAL_PROVIDER_TOKEN,
  en_US as en_US_mobile
} from 'ng-zorro-antd-mobile';

import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NavBarModule } from './nav-bar/nav-bar.module';

@NgModule({
  imports: [
    // TODO selectively use only the NgZorror modules needed
    NgzorroCommonModule,
    CommonModule,
    NavBarModule
  ],
  declarations: [
    ItemDetailsPageDesktopComponent,
    ItemInfoDisplayDesktopComponent,
    ItemPhotoDisplayDesktopComponent
  ],
  exports: [
    ItemDetailsPageDesktopComponent,
    ItemInfoDisplayDesktopComponent,
    ItemPhotoDisplayDesktopComponent,
    NavBarModule
  ]
})
export class CoreModule {
  static forRoot(config: CoreModuleConfig): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: NZ_I18N, useValue: en_US },
        { provide: LOCAL_PROVIDER_TOKEN, useValue: en_US_mobile }
      ]
    };
  }
}
