import { EventEmitter, Injectable } from '@angular/core';
import { SortByCategory, SortState } from '../../../../../common/constants';

export interface GenderToggleEvent {
  category: 'male' | 'female' | 'all';
}

@Injectable({
  providedIn: 'root'
})
export class GenderToggleService {
  genderToggleEvent = new EventEmitter<GenderToggleEvent>();

  constructor() {}

  emitGenderToggleEvent(genderToggleEvent: GenderToggleEvent) {
    this.genderToggleEvent.emit(genderToggleEvent);
  }
}
