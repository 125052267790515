import { Component, Input } from '@angular/core';
import { SortByCategory, SortState } from '../../../../../../common/constants';
import { SortService } from '../../services/sort.service';

@Component({
  selector: 'common-sort-popover',
  templateUrl: './sort-popover.component.html',
  styleUrl: './sort-popover.component.scss'
})
export class SortPopoverComponent {
  @Input() show = true;

  constructor(private sortService: SortService) {}

  sortStateAlphabet: SortState = 'asc';
  sortStateAge: SortState = 'asc';

  swapState(inputState: SortState): SortState {
    if (inputState === 'asc') {
      return 'dsc';
    } else {
      return 'asc';
    }
  }

  onSelect(event) {
    const sortBy = extractSortButtonName(event);
    let direction;
    if (sortBy.toUpperCase() === 'NAME') {
      direction = this.sortStateAlphabet = this.swapState(
        this.sortStateAlphabet
      );
    } else if (sortBy.toUpperCase() === 'AGE') {
      direction = this.sortStateAge = this.swapState(this.sortStateAge);
    }

    this.sortService.emitSortEvent({ category: sortBy, direction });
  }

  onVisibleChange(event) {}
}

function extractSortButtonName(element: HTMLElement): SortByCategory | null {
  // Find the element with class "am-popover-item-content" inside the given element
  const contentElement = element.querySelector('.am-popover-item-content');
  if (contentElement) {
    return contentElement.textContent
      .trim()
      .toLocaleLowerCase() as SortByCategory;
  }
  return null;
}
