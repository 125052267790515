import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PurchaseOrderFull } from '../../../../../../../types/purchase-order';
import { BuyerApiService } from '../services/buyer.api.service';

@Component({
  selector: 'app-buyer-purchase-order-details',
  templateUrl: './buyer-purchase-order-details.component.html',
  styleUrls: ['./buyer-purchase-order-details.component.scss']
})
export class BuyerPurchaseOrderDetailsComponent implements OnInit {
  purchaseOrderId: string;
  purchaseOrderDetails: PurchaseOrderFull;

  constructor(
    private activeRoute: ActivatedRoute,
    private buyerApiService: BuyerApiService,
    private nzMessageService: NzMessageService
  ) {}

  ngOnInit(): void {
    this.purchaseOrderId = this.activeRoute.snapshot.params.poId;
    this.buyerApiService
      .getPurchaseOrder(this.purchaseOrderId)
      .subscribe((po) => {
        this.purchaseOrderDetails = po;
      });
  }

  approvePurchaseOrder() {
    this.buyerApiService.approvePurchaseOrder(this.purchaseOrderId).subscribe(
      (responseJson: any) => {
        this.nzMessageService.success('The purchase order has been approved');
      },
      (error: any) => {
        this.nzMessageService.error('Unable to approve purchase order');
        console.log(error);
      }
    );
  }

  openPDF(pdf: any) {
    if (pdf?.data) {
      const binaryData = new Uint8Array(pdf.data).buffer;
      this.anchorButtonOperation(binaryData, '_blank');
    } else {
      this.nzMessageService.error('No PDF found on the server');
    }
  }

  anchorButtonOperation(
    binaryData: ArrayBufferLike,
    operation: 'download' | '_blank'
  ) {
    const anchorButton = document.createElement('a');
    anchorButton.href = window.URL.createObjectURL(
      new Blob([binaryData], { type: 'application/pdf' })
    );
    anchorButton.setAttribute('hidden', '');

    if (operation === '_blank') {
      anchorButton.setAttribute('target', '_blank');
    } else if (operation === 'download') {
      anchorButton.setAttribute('download', this.purchaseOrderId + '.pdf');
    }
    document.body.appendChild(anchorButton);
    anchorButton.click();
    anchorButton.remove(); // Delete the temporary element
  }
}
