import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EditableField } from '../models/core.types';

@Component({
  selector: 'core-item-info-display-desktop',
  templateUrl: './item-info-display-desktop.component.html',
  // styleUrl: './item-info-display-desktop.component.scss'

  styles: [
    `
      .editable-value {
        cursor: pointer;
        padding: 4px 8px;
        border-radius: 4px;
      }

      .editable-value:hover {
        background-color: #f5f5f5;
      }

      .edit-field-container {
        width: 100%;
      }

      .save-icon {
        cursor: pointer;
        color: #52c41a;
      }

      .save-icon:hover {
        color: #73d13d;
      }
    `
  ]
})
export class ItemInfoDisplayDesktopComponent {
  @Input() cardTitle: string = 'Information';
  @Input() fields: EditableField[] = [];
  @Output() fieldSaved = new EventEmitter<{ key: string; value: any }>();

  editingField: { [key: string]: boolean } = {};

  startEditing(key: string): void {
    this.editingField[key] = true;
  }

  saveField(field: EditableField): void {
    this.editingField[field.key] = false;
    this.fieldSaved.emit({ key: field.key, value: field.value });
  }

  getDisplayValue(field: EditableField): string {
    if (field.type === 'select' && field.options) {
      const option = field.options.find((opt) => opt.value === field.value);
      return option ? option.label : field.value;
    }
    if (field.type === 'date' && field.value) {
      return new Date(field.value).toLocaleDateString();
    }
    return field.value?.toString() || '';
  }
}
