import { Component, Injector, Input, OnInit } from '@angular/core';
import { CustomerInvoiceForAdminReport } from '../../../../../../types/invoice';
import { AbstractOkaTable } from '../abstract-oka-table';

@Component({
  selector: 'oka-customer-invoices-table',
  templateUrl: './customer-invoices-table.component.html',
  styleUrls: ['../oka-tables-styles.scss']
})
export class CustomerInvoicesTableComponent
  extends AbstractOkaTable<CustomerInvoiceForAdminReport>
  implements OnInit
{
  @Input() currentUserType: 'accountant' | 'admin' = 'accountant';
  currentSearchColumn: keyof CustomerInvoiceForAdminReport = 'created_at';

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
