import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthPage } from '../auth-page';
import { absAnimations } from '@core/animations';

@Component({
  selector: 'app-sign-in-page',
  templateUrl: './sign-in-page.component.html',
  styleUrls: ['../auth-common.scss', './sign-in-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations,
  providers: [{ provide: AuthPage, useExisting: SignInPageComponent }]
})
export class SignInPageComponent extends AuthPage implements OnInit {
  constructor(
    private nzMessage: NzMessageService,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.checkUserState();
    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];
  }

  submit() {}

  // TODO rename "submit", and get username and password values programatically
  signIn(username: string, password: string) {
    this.errorMsg = '';
    this.authService.signIn(username, password).subscribe(
      (result) => {
        if (!result.userType) {
          this.router.navigate(['../verify'], {
            relativeTo: this.router.routerState.root,
            state: { username: result.username }
          });
        } else {
          if (this.returnUrl) {
            this.router.navigateByUrl(this.returnUrl);
          } else {
            this.router.navigate([this.authService.getUserHomePath(result)]);
          }
        }
      },
      (error) => {
        this.nzMessage.error(error);
        // Make the error reappear more noticeably
        setTimeout(() => {
          this.errorMsg = 'Invalid username or password';
        }, 500);
      }
    );
  }
}
