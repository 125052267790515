import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { findKey } from '@common/third-party/micro-dash';
import { RowNode } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { Deal, DealColumnHeaders } from '../../../../../../../types/deal';
import { ColumnDefs, SearchMeta } from '../../../../types-frontend/misc';
import { Cacheable, CacheService } from '../../../common/cache.service';
import { OkaModalService } from '../../../common/modal-common/oka-modals.service';
import { tableSortFn } from '../../../common/utils';
import { CreateOrEditActiveDealModalComponent } from '../modals/create-new-active-deal-modal/create-or-edit-active-deal-modal.component';
import { AdminDealService } from '../services/deal.service';

const ADMIN_DEALS_PAGE_CACHE = 'ADMIN_DEALS_PAGE_CACHE';
@Component({
  selector: 'app-active-deals',
  templateUrl: './active-deals.component.html',
  styleUrls: ['./active-deals.component.scss']
})
export class ActiveDealsComponent implements OnInit, Cacheable {
  columnDefs: ColumnDefs<Deal>[] = [];
  columnHeaders: DealColumnHeaders[] = [
    'deal_id',
    'sku',
    'product_description',
    'created_at',
    'start_time',
    'end_time',
    'price',
    'max_quantity',
    'committed_quantity',
    'deleted_at',
    'url',
    'notes'
  ];
  dealsDataForDisplay: Deal[] = [];

  searchValues: SearchMeta<Deal> = {};
  searchValue = '';
  currentSearchColumn: keyof Deal = 'deal_id';
  isSearchFilterApplied = false;

  dealsData: BehaviorSubject<Deal[]> = new BehaviorSubject([]);

  showSpinner = false;

  constructor(
    private modalService: OkaModalService,
    private adminDealService: AdminDealService,
    private cacheService: CacheService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.columnHeaders.forEach((ch) => {
      this.searchValues[ch] = { value: '', visible: false };
    });
  }

  ngOnInit(): void {
    this.load();
    this.generateColumnDefs();
  }

  load(cache = true) {
    const cached = this.cacheService.retrieveFromCache(ADMIN_DEALS_PAGE_CACHE);
    if (cached) {
      this.dealsData.next(cached);
      this.dealsDataForDisplay = [...cached];
    } else {
      this.showSpinner = true;
      this.adminDealService.getDealsForAdmin().subscribe((deals) => {
        this.cacheService.addToCache(ADMIN_DEALS_PAGE_CACHE, deals);
        this.showSpinner = false;
        this.dealsData.next(deals);
        this.dealsDataForDisplay = [...deals];
      });
    }
  }

  createActiveDeal() {
    this.modalService
      .openModal(CreateOrEditActiveDealModalComponent, { mode: 'new' })
      .subscribe((modalRef) => {
        modalRef.afterClose.subscribe(() => {
          this.load(false);
        });
      });
  }

  rowDoubleClicked(event: RowNode) {
    const deal: Deal = event.data;
    this.modalService
      .openModal(CreateOrEditActiveDealModalComponent, { mode: 'edit', deal })
      .subscribe((modalRef) => {
        modalRef.afterClose.subscribe((r) => {
          this.load();
        });
      });
  }

  generateColumnDefs() {
    for (const fieldName of this.columnHeaders) {
      this.columnDefs.push({
        headerName: fieldName,
        sortFn: tableSortFn(fieldName)
      });
    }
  }

  onRowClick(dealId: string) {
    this.router.navigate([dealId], { relativeTo: this.route });
  }

  refreshCache() {
    this.cacheService.clearCache(ADMIN_DEALS_PAGE_CACHE);
    this.load();
  }

  searchColumn() {
    const header = findKey<SearchMeta<Deal>>(
      this.searchValues,
      (i) => i.visible
    );
    this.dealsDataForDisplay = [
      ...this.dealsDataForDisplay.filter(
        (i) =>
          String(i[header as keyof Deal])
            .toLocaleLowerCase()
            .indexOf(this.searchValues[header].value.toLocaleLowerCase()) >= 0
      )
    ];
    this.searchValues[header].visible = false;
    this.isSearchFilterApplied = true;
  }

  searchValuesOpened(isOpened: boolean, headerName: keyof Deal) {
    if (isOpened) {
      this.currentSearchColumn = headerName;
      this.searchValues[headerName].visible = true;
    } else {
      this.searchValues[headerName].visible = false;
    }
  }

  resetSearchColumn() {
    this.searchValues[this.currentSearchColumn] = { value: '', visible: false };
    this.dealsDataForDisplay = [...this.dealsData.getValue()];
  }

  resetAllSearchFilters() {
    Object.keys(this.searchValues).forEach((k) => {
      this.searchValues[k] = { value: '', visible: false };
    });
    this.dealsDataForDisplay = [...this.dealsData.getValue()];
    this.isSearchFilterApplied = false;
  }
}
