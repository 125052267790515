import { ActivityFeedAction } from '../../../../../../../types/activity-feed';

interface EventLogFrontendAttributes {
  color: string;
}

export const eventsCatalogue = new Map<
  ActivityFeedAction,
  EventLogFrontendAttributes
>();

eventsCatalogue.set('committed_deal', { color: '#50c509' });
eventsCatalogue.set('uncommitted_deal', { color: '#ff7373' });
eventsCatalogue.set('added_tracking_number', { color: '#c973ff' });
eventsCatalogue.set('deleted_tracking_number', { color: '#ff7373' });
eventsCatalogue.set('created_user', { color: '#abff73' });
eventsCatalogue.set('package_checked_in', { color: '#abff73' });
eventsCatalogue.set('approve_purchase_order', { color: '#51912a' });
