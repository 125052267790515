import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { uniq } from '@common/third-party/micro-dash';
import { RowNode, SelectionChangedEvent } from 'ag-grid-community';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject } from 'rxjs';
import {
  GroupedPackagedProductForAccountant,
  PackagedProductForAccountantHeaders
} from '../../../../../../../types/packaged-product';
import { ColumnDefinitions } from '../../../../types-frontend/aggrid-types';
import { OkaModalService } from '../../../common/modal-common/oka-modals.service';
import { currencyFormatter, dateFormatter } from '../../../common/utils';
import { QuickbooksService } from '../../../services/quickbooks.service';
import { BaseUserPage } from '../../user-page';
import { InvoiceCreationDrawerComponent } from '../invoice-creation-drawer/invoice-creation-drawer.component';
import { SetPriceModalComponent } from '../modals/set-price-modal/set-price-modal.component';
import { AccountantApiService } from '../services/accountant.service';
import { PackagedProductForAccountant } from '../../../../../../../types/packaged-product';

@Component({
  selector: 'oka-accountant-inventory-page',
  templateUrl: './accountant-inventory-page.component.html',
  styleUrls: ['./accountant-inventory-page.component.scss']
})
export class AccountantInventoryPageComponent
  extends BaseUserPage
  implements OnInit
{
  columnDefs: ColumnDefinitions<PackagedProductForAccountantHeaders>[] = [];
  rowData: PackagedProductForAccountant[] = [];

  quikbookResponse: any;
  draftPurchaseOrder: PackagedProductForAccountant[] = [];
  draftInvoice: PackagedProductForAccountant[] = [];

  documentCreationMode: 'purchase_order' | 'invoice' = 'invoice';
  inStock = false;
  groupBy: undefined | string = undefined;

  inventoryHeaders = ['abc', 'def'];
  inventoryData = new BehaviorSubject<GroupedPackagedProductForAccountant[]>(
    []
  );

  constructor(
    private modalService: OkaModalService,
    private router: Router,
    private accountantApiService: AccountantApiService,
    private quickbookService: QuickbooksService,
    private messageService: NzMessageService,
    private drawerService: NzDrawerService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.generateColumnDefs();
    this.loadPackagedProducts(undefined, this.groupBy, this.inStock);
    this.setChildPages();
  }

  groupInventoryBy(value: string) {
    const oldGroupByValue = this.groupBy;

    if (value === 'sku') {
      this.groupBy = value;
    }
    if (value === 'none') {
      this.groupBy = undefined;
    }

    if (oldGroupByValue !== this.groupBy) {
      this.loadPackagedProducts(undefined, this.groupBy, this.inStock);
    }
  }

  load() {}

  loadPackagedProducts(
    buyerUsername?: string,
    groupBy?: string,
    onlyNonProcessed = false
  ) {
    this.accountantApiService
      .getPackagedProducts(buyerUsername, groupBy, onlyNonProcessed)
      .subscribe((data) => {
        this.inventoryData.next(data);
        console.log('inv', data);
      });
  }

  showInvoiceCreationDrawer(): void {
    const drawerRef = this.drawerService.create<
      InvoiceCreationDrawerComponent,
      { value: string },
      { customer: number; draftInvoice: PackagedProductForAccountant[] }
    >({
      nzTitle: 'Create Invoice',
      nzWidth: 600,
      nzContent: InvoiceCreationDrawerComponent,
      nzContentParams: {
        items: this.draftInvoice,
        allNonInvoicedPackagedProducts: this.rowData.filter(
          (packagedProduct) => packagedProduct.purchase_order !== null
        )
      },
      nzClosable: false,
      nzOnCancel: () => {
        console.log('Drawer closing without saving...');
        return Promise.resolve();
      }
    });

    drawerRef.afterOpen.subscribe(() => {});

    drawerRef.afterClose.subscribe((result) => {
      if (result?.customer) {
        const customerId = result.customer;
        const draftInvoice = result?.draftInvoice || [];
        this.draftInvoice = draftInvoice;
        this.createInvoice(customerId);
      }
    });
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    const nodes = event.api
      .getSelectedNodes()
      .map((n) => n.data as PackagedProductForAccountant);
    // this.draftPurchaseOrder = [];
    this.draftInvoice = [];

    if (
      this.documentCreationMode === 'purchase_order' &&
      uniq(nodes.map((i) => i.buyer)).length > 1
    ) {
      event.api.getSelectedNodes()[nodes.length - 1].setSelected(false);
      this.messageService.error('Cannot select items from different buyers');
    } else {
      for (const node of nodes) {
        this.draftInvoice.push(node);
      }
    }
  }

  createInvoice(customerId: number) {
    this.accountantApiService
      .createCustomerInvoice({
        customer: customerId,
        items: this.draftInvoice
      })
      .subscribe(
        (responseJson: any) => {
          //this.router.navigate(['user', 'accountant', 'purchase-orders', String(responseJson.data)]);
          this.draftInvoice = [];
          this.messageService.success('Invoice generated');
        },
        (error) => {
          console.error(error);
          this.messageService.error('Error creating invoice!');
        }
      );
  }

  rowDoubleClicked(event: RowNode) {
    const productSerial = event.data.product_serial;

    this.modalService
      .openModal(SetPriceModalComponent, { productSerial })
      .subscribe((modalRef) => {
        console.log('modalRef', modalRef);
        modalRef.afterClose.subscribe(() => {
          this.loadPackagedProducts(undefined, this.groupBy, this.inStock);
        });
      });
  }

  onStockToggle(event: boolean) {
    this.inStock = event;
    this.loadPackagedProducts(undefined, this.groupBy, this.inStock);
  }

  private generateColumnDefs() {
    const columnDefsOptions = {
      sortable: true,
      filter: true,
      resizable: true
    };
    this.columnDefs.push({
      headerName: 'packaged_product_id',
      field: 'packaged_product_id',
      checkboxSelection: true,
      ...columnDefsOptions
    });
    this.columnDefs.push({
      headerName: 'deal_price',
      field: 'price',
      valueFormatter: currencyFormatter,
      ...columnDefsOptions
    });
    this.columnDefs.push({
      headerName: 'check_in_time',
      field: 'check_in_time',
      valueFormatter: dateFormatter,
      sortingOrder: ['asc', 'desc'],
      ...columnDefsOptions
    });
    const columnHeaders: PackagedProductForAccountantHeaders[] = [
      'tracking_number',
      'buyer',
      'product_serial',
      'product_name',
      'upc',
      'sku',
      'purchase_order',
      'invoice'
    ];
    for (const fieldName of columnHeaders) {
      this.columnDefs.push({
        // headerName:fieldName.replace(/_/g, ' ').toUpperCase(),
        headerName: fieldName,
        field: fieldName,
        ...columnDefsOptions
      });
    }
  }
}
