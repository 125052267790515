import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Profile,
  ReligiousCategoryEnum
} from '../../../../../../types/profile';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ShadchanService } from '../../user-pages/shadchan/services/shadchan.service';
import { Observable, switchMap, tap } from 'rxjs';
import { ToastService } from 'ng-zorro-antd-mobile';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../../services/local-storage.service';
import { LocalStorageKey } from '../../../types-frontend/misc';
import { HttpResponse, ProfileId } from '../../../../../../types/misc';

@Component({
  selector: 'shdchn-profile-form-mobile',
  templateUrl: './profile-form-mobile.component.html',
  styleUrl: './profile-form-mobile.component.scss'
})
export class ProfileFormMobileComponent implements OnInit, OnDestroy {
  @Input() mode: 'new' | 'edit';
  @Input() profile?: Profile;

  private readonly formStorageKey: LocalStorageKey = 'shdchnprofileform';

  // NOTE: it must have the preceding zeroes (i.e. 01 instead of 1), or else it breaks in Safari
  minDate = new Date('1960-01-02');
  maxDate = new Date('2006-12-30');

  profileForm: FormGroup;

  dobValue = new Date();

  religiousCategories = Object.keys(ReligiousCategoryEnum);
  religiousCategoriesValue = [];

  name = '';

  // Image Upload
  fileUploadUrl =
    environment.baseUrl + environment.apiUrl + '/shadchan/image-upload';
  fileList: NzUploadFile[] = [];
  previewImage: string | undefined = '';
  previewVisible = false;
  filePublicIds: string[] = [];
  imageFiles = [];

  constructor(
    private localStorageService: LocalStorageService,
    private shdchnService: ShadchanService,
    private toastSvc: ToastService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.mode === 'edit' && !this.profile) {
      throw 'No profile provided to Profile Form';
    }

    this.profileForm = this.formBuilder.group({
      gender: new FormControl(
        { value: '', disabled: this.mode === 'edit' },
        Validators.required
      ),
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      date_of_birth: [''],
      city: [''],
      country: [''],
      occupation: [''],
      is_college_educated: ['false'],
      description: [''],
      marital_status: [''],
      tehillim_name: [''],
      religious_identity: ['']
      // religious_seeking [''],
    });

    if (this.mode === 'edit') {
      this.profileForm.get('gender').setValue(this.profile.gender);
      this.profileForm.get('gender').disable();
      this.profileForm.patchValue(this.profile);

      if (
        this.profile.date_of_birth &&
        typeof this.profile.date_of_birth === 'string'
      ) {
        const dobDate = new Date(this.profile.date_of_birth);
        this.profileForm.get('date_of_birth').patchValue(dobDate);
        this.dobValue = dobDate;
      }
    }

    // Restore form data from localStorage
    const savedFormData = this.localStorageService.getFromLocalStorage<any>(
      this.formStorageKey
    );
    if (savedFormData) {
      this.profileForm.setValue(savedFormData);
    }

    this.profileForm.valueChanges.subscribe((newValue) => {
      // console.log("profileForm", newValue);
    });
  }

  ngOnDestroy(): void {
    this.localStorageService.storeInLocalStorage(
      this.formStorageKey,
      this.profileForm.value
    );
  }

  currentDateFormat(date, format = 'yyyy-mm-dd HH:MM'): any {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    const pad = (n: number): string => (n < 10 ? `0${n}` : n.toString());
    return format
      .replace('yyyy', date.getFullYear())
      .replace('mm', pad(date.getMonth() + 1))
      .replace('dd', pad(date.getDate()))
      .replace('HH', pad(date.getHours()))
      .replace('MM', pad(date.getMinutes()))
      .replace('ss', pad(date.getSeconds()));
  }

  handleImageUploadResponse(event: NzUploadChangeParam) {
    if (event.file.status === 'done') {
      const uploadResponse = event.file.response;
      const data = uploadResponse.data;
      for (const item of data) {
        this.filePublicIds.push(item);
      }
    }
  }

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };

  onDobOk(event) {
    this.dobValue = event;
    this.profileForm.get('date_of_birth').setValue(this.dobValue);
  }

  save(profileId?: number) {
    let formSubmitted$: Observable<HttpResponse<ProfileId>>;

    const formattedProfileFormValue = {
      ...this.profileForm.value,
      ...this.formatReligiousIdentity(),
      photos: this.filePublicIds
    };

    if (this.mode === 'new') {
      formSubmitted$ = this.shdchnService.createNewProfile(
        formattedProfileFormValue
      );
    } else if (this.mode === 'edit') {
      let pId = profileId ? profileId : this.profile.id;
      if (!pId) {
        console.error('Cannot save without profile id');
      }
      formSubmitted$ = this.shdchnService.updateProfile(
        pId,
        formattedProfileFormValue
      );
    }

    formSubmitted$.pipe(switchMap(() => this.shdchnService.getProfiles(true)));

    formSubmitted$.subscribe((response) => {
      this.localStorageService.deleteFromLocalStorage(this.formStorageKey);
      const msg = `Profile ${this.mode === 'new' ? 'Created' : 'Updated'}`;
      this.toastSvc.success(msg, 3000, () => {});
      this.router.navigate(['/user/shadchan/home']);
    });

    return formSubmitted$;
  }

  // fileChange(params) {
  //   console.log(params);
  //   const { files, type, index } = params;
  //   this.imageFiles = files;
  // }

  imageClick(params) {
    console.log(params);
  }

  private formatReligiousIdentity() {
    let riValue = null;
    const riObj = { religious_identity: riValue };
    const riFormValue = this.profileForm.value.religious_identity;
    if (riFormValue && Array.isArray(riFormValue) && riFormValue.length === 1) {
      riObj['religious_identity'] =
        this.profileForm.value.religious_identity[0];
    }
    return riObj;
  }
}

// TODO make global
const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
