import {
  Directive,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  Input,
  Renderer2
} from "@angular/core";

@Directive({
  selector: "[scrollableDiv]"
})
export class ScrollableDivDirective implements AfterViewInit, OnDestroy {
  private scrollListener: () => void;
  private styleElement?: HTMLStyleElement;
  private uniqueClassName = `scroll-shadow-${Math.random()
    .toString(36)
    .substr(2, 9)}`;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    // Set required styles on the element
    this.renderer.setStyle(this.el.nativeElement, "position", "relative");
    this.renderer.setStyle(this.el.nativeElement, "overflow", "auto");
    this.renderer.addClass(this.el.nativeElement, this.uniqueClassName);

    // Create and inject the styles
    this.styleElement = this.renderer.createElement("style");
    this.styleElement.textContent = `
      .${this.uniqueClassName}.is-scrolled::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 6px;
        pointer-events: none;
        z-index: 1;
        background: linear-gradient(to bottom, 
          rgba(0, 0, 0, 0.1) 0%,
          rgba(0, 0, 0, 0.05) 50%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    `;

    this.renderer.appendChild(document.head, this.styleElement);

    this.scrollListener = () => {
      if (this.el.nativeElement.scrollTop > 0) {
        this.renderer.addClass(this.el.nativeElement, "is-scrolled");
      } else {
        this.renderer.removeClass(this.el.nativeElement, "is-scrolled");
      }
    };

    this.el.nativeElement.addEventListener("scroll", this.scrollListener);
  }

  ngOnDestroy() {
    if (this.scrollListener) {
      this.el.nativeElement.removeEventListener("scroll", this.scrollListener);
    }
    if (this.styleElement) {
      this.styleElement.remove();
    }
  }
}
