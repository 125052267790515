import { Component, OnInit } from '@angular/core';
import { RowNode } from 'ag-grid-community';
import { User } from '../../../../../../../types/user';
import { findKey } from '@common/third-party/micro-dash';
import { ColumnDefinitions } from '../../../../types-frontend/aggrid-types';
import { OkaModalService } from '../../../common/modal-common/oka-modals.service';
import { CreateNewUserModalComponent } from '../modals/create-new-user-modal/create-new-user-modal.component';
import { UpdateUserModalComponent } from '../modals/update-user-modal/update-user-modal.component';
import { UserManagementService } from '../services/user-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnDefs, SearchMeta } from '../../../../types-frontend/misc';
import { tableSortFn } from '../../../common/utils';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-user-management-page',
  templateUrl: './user-management-page.component.html',
  styleUrls: ['./user-management-page.component.scss']
})
export class UserManagementPageComponent implements OnInit {
  usersData: BehaviorSubject<User[]> = new BehaviorSubject([]);
  usersDataForDisplay: User[] = [];
  columnHeaders: (keyof User)[] = [
    'user_type',
    'user_id',
    'first_name',
    'last_name',
    'username'
  ];
  columnDefs: ColumnDefs<User>[] = []; // ColumnDefinitions<keyof User | {searchValue: string}>[] = [];
  paginationPageSize = 100;
  cacheBlockSize = 100;

  searchValues: SearchMeta<User> = {};
  searchValue = '';
  currentSearchColumn: keyof User = 'username';
  isSearchFilterApplied = false;

  constructor(
    private userManagementService: UserManagementService,
    private modalService: OkaModalService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.columnHeaders.forEach((ch) => {
      this.searchValues[ch] = { value: '', visible: false };
    });

    this.generateColumnDefs();
  }

  ngOnInit(): void {
    this.getUsers();
  }

  createNewUser() {
    this.modalService
      .openModal(CreateNewUserModalComponent)
      .subscribe((modalRef) => {
        console.log('modalRef', modalRef);
        modalRef.afterClose.subscribe((r) => {
          this.getUsers();
        });
      });
  }

  onRowClick(username: string) {
    this.router.navigate(['users', username], { relativeTo: this.route });
  }

  updateUser(event: RowNode) {
    const user: User = event.data;
    this.modalService
      .openModal(UpdateUserModalComponent, { user })
      .subscribe((modalRef) => {
        modalRef.afterClose.subscribe((r) => {
          this.getUsers();
        });
      });
  }

  generateColumnDefs() {
    for (const fieldName of this.columnHeaders) {
      this.columnDefs.push({
        headerName: fieldName,
        sortFn: tableSortFn(fieldName)
      });
    }
  }

  onGridReady(event: any) {
    console.log('onFirstDataRendered', event);
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  getUsers() {
    this.userManagementService
      .getUsers()
      .subscribe((response: { status: number; data: User[] }) => {
        const users: User[] = response.data;
        // this.usersData = users;
        this.usersData.next(users);
        this.usersDataForDisplay = [...users];
      });
  }

  searchColumn() {
    const header = findKey<SearchMeta<User>>(
      this.searchValues,
      (i) => i.visible
    );
    this.usersDataForDisplay = [
      ...this.usersDataForDisplay.filter(
        (i) =>
          String(i[header as keyof User])
            .toLocaleLowerCase()
            .indexOf(this.searchValues[header].value.toLocaleLowerCase()) >= 0
      )
    ];
    this.searchValues[header].visible = false;
    this.isSearchFilterApplied = true;
  }

  searchValuesOpened(isOpened: boolean, headerName: keyof User) {
    if (isOpened) {
      this.currentSearchColumn = headerName;
      this.searchValues[headerName].visible = true;
    } else {
      this.searchValues[headerName].visible = false;
    }
  }

  resetSearchColumn() {
    this.searchValues[this.currentSearchColumn] = { value: '', visible: false };
    this.usersDataForDisplay = [...this.usersData.getValue()];
  }

  resetAllSearchFilters() {
    Object.keys(this.searchValues).forEach((k) => {
      this.searchValues[k] = { value: '', visible: false };
    });
    this.usersDataForDisplay = [...this.usersData.getValue()];
    this.isSearchFilterApplied = false;
  }
}
