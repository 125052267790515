import { maxBy } from '@common/third-party/micro-dash';
import { DBEntityName } from '../../../../../types/misc';

export class AbstractApiService {
  headers = {
    'Content-Type': 'application/json'
  };

  /** Cache the last ID fetched, so it can be used to offset follow-up
   * queries for more rows
   */
  lastId: { [entity: string]: number } = {};

  constructor() {}

  handleApiError() {
    // TODO modal popup
  }

  protected getCachedLastId(entityName: DBEntityName) {
    return this.lastId[entityName];
  }

  protected cacheLastId(data: any[], entityName: DBEntityName, idKey: string) {
    const maxId = maxBy(data, (i) => i[idKey]) || 0;
    this.lastId[entityName] = Number(maxId);
  }
}
