import { Component, Injector } from '@angular/core';
import { GenderToggleService } from '../../services/gender-toggle.service';
import { GenderToggleBaseComponent } from '../gender-toggle-base/gender-toggle-base.component';

@Component({
  selector: 'shdchn-gender-toggle-mobile',
  templateUrl: './gender-toggle-mobile.component.html',
  styleUrl: './gender-toggle-mobile.component.scss'
})
export class GenderToggleMobileComponent extends GenderToggleBaseComponent {
  constructor(injector: Injector) {
    super(injector.get(GenderToggleService));
  }
}
