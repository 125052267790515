import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { get } from '@common/third-party/micro-dash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Deal } from '../../../../../../../../types/deal';
import { Product } from '../../../../../../../../types/product';
import { ModalComponent } from '../../../../common/modal-common/modal.component';
import { AdminDealService } from '../../services/deal.service';
import { ProductService } from '../../services/product.service';

type ModalMode = 'new' | 'edit';

@Component({
  selector: 'app-create-new-active-deal-modal',
  templateUrl: './create-or-edit-active-deal-modal.component.html',
  styleUrls: ['./create-or-edit-active-deal-modal.component.scss']
})
export class CreateOrEditActiveDealModalComponent
  extends ModalComponent
  implements OnInit, AfterViewInit
{
  title = 'Create New Deal';
  productList: Product[] = [];
  matchingProductListItems: Product[] = [];
  matchingDealSourceListItems: string[] = [];
  createNewDealForm: FormGroup = new FormGroup({});

  inputs: { mode: ModalMode; deal?: Deal } = { mode: 'new' };

  @ViewChild('tpl') public templateref: TemplateRef<any>;
  formatterDollar = (value: string) => `$ ${value}`;
  parserDollar = (value: string) => value.replace('$ ', '');

  dealSourcesList = ['Amazon', 'Target', 'Best Buy', 'Walmart', 'Other'];

  constructor(
    nzModal: NzModalService,
    private productService: ProductService,
    private adminDealService: AdminDealService,
    private nzMessage: NzMessageService
  ) {
    super(nzModal);
  }

  ngOnInit(): void {
    this.createNewDealForm = new FormGroup(
      {
        productId: new FormControl(get(this.inputs.deal, ['product_id'], ''), [
          Validators.required
        ]),
        maxQuantity: new FormControl(
          get(this.inputs.deal, ['max_quantity'], 0),
          [Validators.required, Validators.pattern('^[0-9]*$')]
        ),
        offerPrice: new FormControl(get(this.inputs.deal, ['price'], 0), [
          Validators.required,
          Validators.pattern('^[1-9][0-9]*(.[0-9]{1,2})?$')
        ]),
        dealSources: this.loadDealSourcesFormGroup(),
        startTime: new FormControl(get(this.inputs.deal, ['start_time'], ''), [
          Validators.required
        ]),
        endTime: new FormControl(get(this.inputs.deal, ['end_time'], ''), [
          Validators.required
        ]),
        isPublic: new FormControl(get(this.inputs.deal, ['is_public'], true), [
          Validators.required
        ])
      },
      { validators: [dateComparisionValidator] }
    );

    this.productService.getProducts().subscribe((pl) => {
      this.productList = pl;
    });

    if (this.inputs.mode === 'edit') {
      this.title = `Update Deal ID ${this.inputs.deal.deal_id}`;
    } else {
      this.title = 'Create New Deal';
    }
  }

  ngAfterViewInit(): void {
    this.openModal({}, () => {
      // The first item in the dealsources form array is a placeholder to add items
      // This needs to be removed from the form array before submiting to the api
      const dealSources = this.getCurrentDealSources();
      if (dealSources.length < 1) {
        this.nzMessage.error('Deal Sources is a required field');
        return;
      } else if (dealSources.length == 1) {
        const dealSource = dealSources[0]['source_name']?.trim() ?? '';
        const dealSourceLink = dealSources[0]['source_link']?.trim() ?? '';
        if (dealSource.length <= 0 || dealSourceLink.length <= 0) {
          this.nzMessage.error('Deal Sources cannot be empty');
          return;
        }
      }

      if (this.createNewDealForm.valid) {
        this.adminDealService
          .createOrEditDeal(
            {
              product_id: this.createNewDealForm.get('productId').value,
              start_time: this.createNewDealForm.get('startTime').value,
              end_time: this.createNewDealForm.get('endTime').value,
              price: this.createNewDealForm.get('offerPrice').value,
              max_quantity: this.createNewDealForm.get('maxQuantity').value,
              dealSources: this.getCurrentDealSources(),
              is_public: true // this.createNewDealForm.get('isPublic').value
            },
            get(this.inputs.deal, ['id'])
          )
          .subscribe(() => {
            if (this.inputs.mode === 'edit') {
              this.nzMessage.success(
                `Deal ${this.inputs.deal.deal_id} Updated `
              );
            } else {
              this.nzMessage.success('New Deal Created');
            }
            this.closeModal();
          });
      } else {
        if (this.createNewDealForm.errors) {
          this.nzMessage.error('Invalid start and end date combination');
          return;
        }

        for (const controlName in this.createNewDealForm.controls) {
          const control = this.createNewDealForm.controls[controlName];
          if (control.errors || control.status == 'INVALID') {
            this.nzMessage.error(`${controlName} is invalid`);
            break;
          }
        }
      }
    });
  }

  get dealSources() {
    return this.createNewDealForm.get('dealSources') as FormArray;
  }

  private generateEmptyDealSourceForm(): FormGroup {
    const dealSourceForm = new FormGroup({
      source_name: new FormControl('', [Validators.required]),
      source_link: new FormControl('', [Validators.required])
    });

    return dealSourceForm;
  }

  addDealSource() {
    for (let i = 0; i < this.dealSources.length; i++) {
      if (!this.dealSources.at(i).valid) {
        this.nzMessage.error(`Please add the value in the empty row`);
        return;
      }
    }

    const dealSourceForm = this.generateEmptyDealSourceForm();
    this.dealSources.push(dealSourceForm);
  }

  removeDealSource(index: number) {
    this.dealSources.removeAt(index);
  }

  loadDealSourcesFormGroup(): FormArray {
    const formArray: FormArray = new FormArray([]);

    if (this.inputs && this.inputs.deal && this.inputs.deal['dealSources']) {
      for (const dealSource of this.inputs.deal['dealSources']) {
        const dealSourceForm = this.generateEmptyDealSourceForm();

        dealSourceForm.patchValue({
          source_name: get(dealSource, ['source_name'], ''),
          source_link: get(dealSource, ['source_link'], '')
        });

        formArray.push(dealSourceForm);
      }
    }

    // Add an empty row to add new Deal Sources
    // The empty row will always be the top row
    const dealSourceForm = this.generateEmptyDealSourceForm();
    formArray.push(dealSourceForm);

    return formArray;
  }

  getCurrentDealSources() {
    const dealSources = this.createNewDealForm.get('dealSources').value;
    if (dealSources.length > 0) {
      return dealSources;
    } else {
      return [];
    }
  }

  onSearch(value: string) {
    const valueLowercase = value.toLocaleLowerCase();
    this.matchingProductListItems = this.productList.filter(
      (i) =>
        i.sku.toLocaleLowerCase().indexOf(valueLowercase) >= 0 ||
        i.upc.toLocaleLowerCase().indexOf(valueLowercase) >= 0 ||
        i.product_name.toLocaleLowerCase().indexOf(valueLowercase) >= 0 ||
        i.product_description.toLocaleLowerCase().indexOf(valueLowercase) >= 0
    );
  }

  onSearchForDealSource(value: string) {
    const valueLowercase = value.toLocaleLowerCase();
    this.matchingDealSourceListItems = this.dealSourcesList.filter(
      (i) => i.toLocaleLowerCase().indexOf(valueLowercase) >= 0
    );
  }

  startNow() {
    let val = this.createNewDealForm.get('startTime');
    this.createNewDealForm.get('startTime').setValue(new Date());
  }
}

const dateComparisionValidator: ValidatorFn = (
  control: FormGroup
): ValidationErrors | null => {
  const controls = control.controls;
  if (controls) {
    if ('startTime' in controls && 'endTime' in controls) {
      const startTime = controls['startTime']?.value;
      const endTime = controls['endTime']?.value;

      if (new Date(endTime) < new Date(startTime)) {
        return { dateValidationFailed: true };
      }
    }
  }
};
