import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomerInvoiceFull } from '../../../../../../../types/invoice';
import { AccountantApiService } from '../services/accountant.service';

@Component({
  selector: 'oka-accountant-invoice-reports-page',
  templateUrl: './accountant-invoice-reports-page.component.html',
  styleUrls: ['./accountant-invoice-reports-page.component.scss']
})
export class AccountantInvoiceReportsPageComponent implements OnInit {
  invoiceId: string;
  invoices: BehaviorSubject<CustomerInvoiceFull[]> = new BehaviorSubject([]);

  columnHeaders = [
    'invoice_id',
    'customer_id',
    'customer_name',
    'created_at',
    'approved_at'
  ];

  constructor(private accountantApiService: AccountantApiService) {}

  ngOnInit(): void {
    this.accountantApiService.getCustomerInvoices().subscribe((invoices) => {
      this.invoices.next(invoices);
    });
  }
}
