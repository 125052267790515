import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { uniq } from '@common/third-party/micro-dash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Buyer } from '../../../../../../../types/buyer';
import { ProductInstanceDetails } from '../../../../../../../types/product';
import { PDF, PurchaseOrder } from '../../../../../../../types/purchase-order';
import { AdminPurchaseOrdersService } from '../../admin/services/purchase-orders.service';

@Component({
  selector: 'oka-purchase-order-details-page',
  templateUrl: './purchase-order-details-page.component.html',
  styleUrls: ['./purchase-order-details-page.component.scss']
})
export class PurchaseOrderDetailsPageComponent implements OnInit {
  // apiService: AdminPurchaseOrdersService;

  purchaseOrderId: string;
  poDetails: PurchaseOrder;
  buyerDetails: Buyer;
  poItems: ProductInstanceDetails[] = [];
  trackingNumbers: [string, number][];
  pdf: PDF;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    // private injector: Injector,
    private apiService: AdminPurchaseOrdersService,
    private nzMessageService: NzMessageService
  ) {
    // this.apiService = this.injector.get(AbstractApiService) as AdminPurchaseOrdersService;
  }

  ngOnInit(): void {
    this.purchaseOrderId = this.activeRoute.snapshot.params.poId;
    this.loadPurchaseOrder();
  }

  emailPDF() {
    this.apiService.emailPDF(this.purchaseOrderId).subscribe(
      () => {
        this.nzMessageService.success('Email sent to the buyer');
      },
      (error) => {
        this.nzMessageService.error('Unable to send email');
        console.error('Unable to send email', error);
      }
    );
  }

  loadPurchaseOrder() {
    this.apiService.getPurchaseOrder(this.purchaseOrderId).subscribe(
      (result) => {
        this.poDetails = result.details;
        this.buyerDetails = result.buyerDetails;
        this.poItems = result.items;
        this.trackingNumbers = this.generateListOfTrackingNumbers(
          result.tracking_numbers
        );
        this.pdf = result.purchase_order_pdf;
      },
      (error) => {
        console.error('Error', error);
        // this.router.navigate(['../'], { relativeTo: this.activeRoute });
      }
    );
  }

  generateListOfTrackingNumbers(trackingNumbers: string[]): [string, number][] {
    const listOfTrackingNumbers = [];
    const noDuplicates = uniq(trackingNumbers);

    for (let i = 0; i < noDuplicates.length; i++) {
      listOfTrackingNumbers.push([
        noDuplicates[i],
        trackingNumbers.filter((tn) => tn === noDuplicates[i]).length
      ]);
    }

    return listOfTrackingNumbers;
  }

  viewPDF() {
    // this.apiService.generatePurchaseOrder(this.purchaseOrderId).subscribe(
    //   (response) => {
    //     const binaryData = [response];
    //     const downloadAnchorButton = document.createElement('a');
    //     downloadAnchorButton.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
    //     downloadAnchorButton.setAttribute('hidden', '');
    //     downloadAnchorButton.setAttribute('download', this.purchaseOrderId + '.pdf');
    //     document.body.appendChild(downloadAnchorButton);
    //     downloadAnchorButton.click();
    //     downloadAnchorButton.remove(); // Delete the temporary element
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
  }

  createQuickbooksPO() {
    // this.apiService.createQuickbooksPurchaseOrder(this.purchaseOrderId).subscribe(
    //   (responseJson: any) => {
    //     this.poDetails.quickbooks_po_id = responseJson.data.PurchaseOrder.Id;
    //     this.poDetails.quickbooks_po_create_at = responseJson.data.PurchaseOrder.MetaData.CreateTime;
    //     this.poDetails.quickbooks_po_url = responseJson.data.quikBookPoUrl;
    //     this.messageService.success('Purchase order created');
    //   },
    //   (error) => {
    //     this.messageService.error('Error creating Quickbooks PO. ' + error.error.message);
    //     console.error(error.error);
    //   }
    // );
  }
}
