import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PurchaseOrder } from '../../../../../../../../types/purchase-order';
import { ModalComponent } from '../../../../common/modal-common/modal.component';
import { BuyerApiService } from '../../../buyer/services/buyer.api.service';

@Component({
  selector: 'oka-request-payment-modal',
  templateUrl: './request-payment-modal.component.html',
  styleUrls: ['./request-payment-modal.component.scss']
})
export class RequestPaymentModalComponent
  extends ModalComponent
  implements OnInit, AfterViewInit
{
  title = 'Request Payment';
  requestPaymentForm: FormGroup = this._formBuilder.group({});
  inputs: { maxWithdrawalAmount: number };

  listOfPurchaseOrders: PurchaseOrder[] = [];

  formatterDollar = (value: number): string => `$ ${value}`;

  constructor(
    nzModal: NzModalService,
    private nzMessageService: NzMessageService,
    private _formBuilder: FormBuilder,
    private buyerApi: BuyerApiService
  ) {
    super(nzModal);
  }

  ngOnInit(): void {
    this.requestPaymentForm = new FormGroup(
      {
        purchaseOrders: new FormControl([]),
        withdrawalAmount: new FormControl(0, [
          Validators.required,
          Validators.pattern('^[1-9][0-9]*(:?\\.[0-9]{1,2})?$')
        ]),
        requestNotes: new FormControl('')
      },
      {
        validators: [
          maxWithdrawlLimitValidator(this.inputs?.maxWithdrawalAmount)
        ]
      }
    );

    this.fetchUnpaidPurchaseOrdersData();
  }

  ngAfterViewInit(): void {
    this.openModal({}, () => {
      if (this.requestPaymentForm.valid) {
        this.modal.close({
          purchaseOrders: this.requestPaymentForm.get('purchaseOrders').value,
          withdrawalAmount: parseFloat(
            this.requestPaymentForm.get('withdrawalAmount').value
          ),
          requestNotes:
            this.requestPaymentForm.get('requestNotes').value.length > 0
              ? this.requestPaymentForm.get('requestNotes').value
              : null
        });
      } else {
        if (this.requestPaymentForm.errors) {
          this.nzMessageService.error(
            'Withdrawal Amount cannot be greater than Balance'
          );
          return;
        }

        for (const controlName in this.requestPaymentForm.controls) {
          const control = this.requestPaymentForm.controls[controlName];
          if (control.errors) {
            this.nzMessageService.error(`${controlName} is invalid`);
            break;
          }
        }
      }
    });
  }

  /**
   * Get purchase orders for current buyer that have not been paid out
   */
  fetchUnpaidPurchaseOrdersData() {
    this.buyerApi.getPurchaseOrders(0, true).subscribe((pos) => {
      console.log('listOfPurchaseOrders', this.listOfPurchaseOrders);
      // this.listOfPurchaseOrders = pos.data.map((po) => `PO #${po.purchase_order_id}  /  $${po.calculated_total}`);
      //this.listOfPurchaseOrders = pos.data.map((po) => String(po.purchase_order_id));
      this.listOfPurchaseOrders = pos.data;
    });
  }

  calculateTotalWithdrawalAmount(purchaseOrderIds: number[]) {
    let totalWithdrawlAmount = 0;
    for (const po of this.listOfPurchaseOrders) {
      if (purchaseOrderIds.indexOf(po.purchase_order_id) !== -1) {
        totalWithdrawlAmount += po.calculated_total;
      }
    }
    totalWithdrawlAmount = parseFloat(totalWithdrawlAmount.toFixed(2));

    this.requestPaymentForm
      .get('withdrawalAmount')
      .patchValue(totalWithdrawlAmount);
  }
}

function maxWithdrawlLimitValidator(maxWithdrawalAmount: number): ValidatorFn {
  return (control: FormGroup): ValidationErrors | null => {
    const controls = control.controls;
    if (controls) {
      if ('withdrawalAmount' in controls && maxWithdrawalAmount) {
        const withdrawalAmount = controls['withdrawalAmount']?.value;

        if (withdrawalAmount > Math.round(maxWithdrawalAmount * 100) / 100) {
          return { withdrawlLimitValidationFailed: true };
        }
      }
    }

    return null;
  };
}
