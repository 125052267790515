import { Component } from '@angular/core';
import { GenderToggleService } from '../../services/gender-toggle.service';

@Component({
  selector: 'shdchn-gender-toggle',
  template: '',
  styles: []
})
export class GenderToggleBaseComponent {
  genderModeIndex = 0;
  genderMode: 'male' | 'female' | 'all' = 'all';

  constructor(private genderToggleService: GenderToggleService) {}

  onChangeGenderMode(event: { selectedIndex?: number; value: string }) {
    console.log('event', event);
    const value = event.value.toLocaleLowerCase();
    if (value === 'men') {
      this.genderMode = 'male';
    } else if (value === 'women') {
      this.genderMode = 'female';
    } else if (value === 'all') {
      this.genderMode = 'all';
    }

    this.genderToggleService.emitGenderToggleEvent({
      category: this.genderMode
    });
  }
}
