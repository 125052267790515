import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Profile } from '../../../../../../types/profile';
import { Router } from '@angular/router';

@Component({
  selector: 'shdchn-profile-card-mobile',
  templateUrl: './profile-card-mobile.component.html',
  styleUrl: './profile-card-mobile.component.scss',
  // TODO extract animation into own file, make it reusable
  animations: [
    trigger('changeHeight', [
      state(
        'open',
        style({
          // height: "100%",
          maxHeight: '2000px',
          opacity: 1
        })
      ),
      state(
        'closed',
        style({
          // height: "0px",
          maxHeight: '0px',
          opacity: 0
        })
      ),
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.3s')])
    ])
  ]
})
export class ProfileCardMobileComponent implements OnInit, AfterViewInit {
  @Input() profileData: Profile;
  @Output() onOpenProfileImageModal = new EventEmitter<number>();

  avatarPath: string;

  gridStyle = {
    width: '25%',
    textAlign: 'center'
  };

  dotPosition = 0;

  isExpanded = true;

  thumbStyle = {
    width: '32px',
    height: '32px'
  };

  constructor(private _router: Router) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.avatarPath = this.profileData.profile_photo_url;
  }

  openProfile() {
    this._router.navigate(['user/shadchan/profile/', this.profileData.id]);
  }

  openProfileImageModal() {
    this.onOpenProfileImageModal.emit(this.profileData.id);
  }
}
