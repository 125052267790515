<nz-layout class="screen-desktop">
  <nz-content class="outer-content">
    <nz-layout class="inner-layout">
      <nz-sider
        nzWidth="400px"
        nzTheme="light"
        nzCollapsible
        [(nzCollapsed)]="isSiderCollapsed"
        nzCollapsedWidth="64"
        [nzTrigger]="null"
      >
        <div
          class="sider-collapse"
          (click)="isSiderCollapsed = !isSiderCollapsed"
        >
          <i
            nz-icon
            [nzType]="isSiderCollapsed ? 'right' : 'left'"
            nzTheme="outline"
          ></i>
        </div>

        <div class="search-filters" *ngIf="!isSiderCollapsed">
          <h3 nz-typography>Profile Filters</h3>

          <div class="filter-gender">
            <shdchn-gender-toggle-desktop></shdchn-gender-toggle-desktop>
          </div>

          <div class="filter-age">
            <h4>Age Range</h4>
            <nz-slider
              [(ngModel)]="filterAge"
              nzRange
              nzMin="18"
              nzMax="120"
              [nzDisabled]="disabled"
            ></nz-slider>
          </div>

          <div class="filter-name">
            <input nz-input placeholder="name" nzSize="large" />
          </div>
        </div>
      </nz-sider>

      <nz-content class="inner-content">
        <div nz-row>
          <div scrollableDiv nz-col nzSpan="24" class="card-grid">
            <ng-container *ngFor="let profile of profilesFiltered">
              <shdchn-profile-card
                class="card"
                [id]="'profile-' + profile.id"
                #profileCard
                [profileData]="profile"
                [@fadeInOut]
                (onOpenProfileImageModal)="showImageModal($event)"
              >
              </shdchn-profile-card>
            </ng-container>
          </div>
        </div>
      </nz-content>
    </nz-layout>
  </nz-content>
</nz-layout>
