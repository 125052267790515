import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { uniq } from '@common/third-party/micro-dash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Buyer } from '../../../../../../../types/buyer';
import { ProductInstanceDetails } from '../../../../../../../types/product';
import { PDF, PurchaseOrder } from '../../../../../../../types/purchase-order';
import { AccountantApiService } from '../services/accountant.service';

@Component({
  selector: 'app-accountant-purchase-order-details-page',
  templateUrl: './accountant-purchase-order-details-page.component.html',
  styleUrls: ['./accountant-purchase-order-details-page.component.scss']
})
export class AccountantPurchaseOrderDetailsPageComponent implements OnInit {
  purchaseOrderId: string;
  poDetails: PurchaseOrder;
  buyerDetails: Buyer;
  poItems: ProductInstanceDetails[] = [];
  trackingNumbers: [string, number][];
  pdf: PDF;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private accountantService: AccountantApiService,
    private messageService: NzMessageService
  ) {}

  ngOnInit(): void {
    this.purchaseOrderId = this.activeRoute.snapshot.params.poId;
    this.loadPurchaseOrder();
  }

  emailPDF() {
    this.accountantService.emailPDF(this.purchaseOrderId).subscribe(
      () => {
        this.messageService.success('Email sent to the buyer');
      },
      (error) => {
        this.messageService.error('Unable to send email');
        console.error('Unable to send email', error);
      }
    );
  }

  loadPurchaseOrder() {
    this.accountantService.getPurchaseOrder(this.purchaseOrderId).subscribe(
      (result) => {
        this.poDetails = result.details;
        this.buyerDetails = result.buyerDetails;
        this.poItems = result.items;
        this.trackingNumbers = this.generateListOfTrackingNumbers(
          result.tracking_numbers
        );
        this.pdf = result.purchase_order_pdf;
      },
      (error) => {
        this.router.navigate(['../'], { relativeTo: this.activeRoute });
      }
    );
  }

  generateListOfTrackingNumbers(trackingNumbers: string[]): [string, number][] {
    const listOfTrackingNumbers = [];
    const noDuplicates = uniq(trackingNumbers);

    for (let i = 0; i < noDuplicates.length; i++) {
      listOfTrackingNumbers.push([
        noDuplicates[i],
        trackingNumbers.filter((tn) => tn === noDuplicates[i]).length
      ]);
    }

    return listOfTrackingNumbers;
  }

  viewPDF(pdf: any) {
    const binaryData = new Uint8Array(pdf.data).buffer;
    this.anchorButtonOperation(binaryData, 'download');
  }

  createQuickbooksPO() {
    this.accountantService
      .createQuickbooksPurchaseOrder(this.purchaseOrderId)
      .subscribe(
        (responseJson: any) => {
          this.poDetails.quickbooks_po_id = responseJson.data.PurchaseOrder.Id;
          this.poDetails.quickbooks_po_create_at =
            responseJson.data.PurchaseOrder.MetaData.CreateTime;
          this.poDetails.quickbooks_po_url = responseJson.data.quikBookPoUrl;

          this.messageService.success('Purchase order created');
        },
        (error) => {
          this.messageService.error(
            'Error creating Quickbooks PO. ' + error.error.message
          );
          console.error(error.error);
        }
      );
  }

  openPDF(pdf: any) {
    const binaryData = new Uint8Array(pdf.data).buffer;
    this.anchorButtonOperation(binaryData, '_blank');
  }

  anchorButtonOperation(
    binaryData: ArrayBufferLike,
    operation: 'download' | '_blank'
  ) {
    const anchorButton = document.createElement('a');
    anchorButton.href = window.URL.createObjectURL(
      new Blob([binaryData], { type: 'application/pdf' })
    );
    anchorButton.setAttribute('hidden', '');

    if (operation === '_blank') {
      anchorButton.setAttribute('target', '_blank');
    } else if (operation === 'download') {
      anchorButton.setAttribute('download', this.purchaseOrderId + '.pdf');
    }
    document.body.appendChild(anchorButton);
    anchorButton.click();
    anchorButton.remove(); // Delete the temporary element
  }
}
