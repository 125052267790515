import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomerInvoice } from '../../../../../../../../types/invoice';
import { CustomerInvoicesService } from '../../services/customer-invoices.service';

@Component({
  selector: 'oka-customer-invoices-reports-page',
  templateUrl: './customer-invoices-reports-page.component.html'
})
export class CustomerInvoicesReportsPageComponent implements OnInit {
  customerInvoicesData: BehaviorSubject<CustomerInvoice[]> =
    new BehaviorSubject([]);

  columnHeaders = [
    'id',
    'customer',
    'customer_username',
    'created_at',
    'approved_at'
  ];

  showSpinner = false;

  constructor(private adminApiService: CustomerInvoicesService) {}

  ngOnInit() {
    this.showSpinner = true;
    this.adminApiService.getCustomerServicesReport().subscribe((reportData) => {
      this.customerInvoicesData.next(reportData);
      this.showSpinner = false;
    });
  }

  exportAsCSV() {
    const rows = [
      this.columnHeaders,
      ...this.customerInvoicesData.getValue().map((rd) => Object.values(rd))
    ];
    let csvContent =
      'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');
    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }
}
