import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountantCommittedDeal } from '../../../../../../../types/deal';
import { DataSourceCommon } from '../../../common/infinite-list/data-source';

@Component({
  selector: 'oka-accountant-deal-commit-reports-page',
  templateUrl: './accountant-deal-commit-reports-page.component.html',
  styleUrls: ['./accountant-deal-commit-reports-page.component.scss']
})
export class AccountantDealCommitReportsPageComponent implements OnInit {
  dataSource = new DataSourceCommon<AccountantCommittedDeal>(
    'accountant/committed-deals',
    this.http
  );

  private destroy$ = new Subject();

  constructor(private http: HttpClient, private nzMessage: NzMessageService) {}

  ngOnInit(): void {
    this.dataSource
      .completed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.nzMessage.warning('Infinite List loaded all');
      });

    console.log(
      'AccountantDealCommitReportsPageComponent datasource',
      this.dataSource
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
