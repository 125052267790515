import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  Component,
  Injector,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';

import { SortByCategory } from '../../../../../../../../common/constants';
import { Profile } from '../../../../../../../../types/profile';
import { ProfileCardComponent } from '../../../../common/profile-card/profile-card.component';
import { ProfilesPageBaseComponent } from '../profiles-page-base/profiles-page-base.component';

@Component({
  selector: 'shdchn-profiles-page',
  templateUrl: './profiles-page-mobile.component.html',
  styleUrl: './profiles-page-mobile.component.scss',
  animations: [
    trigger('fadeInSlideIn', [
      state(
        'void',
        style({
          opacity: 0,
          transform: 'translateX(-20px)' // Slide in from slightly to the left
        })
      ),
      transition(':enter, :leave', [
        animate('300ms ease-out') // Fade in and slide in quickly
      ])
    ]),
    trigger('fadeInOut', [
      state(
        '*',
        style({
          opacity: 1
        })
      ),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [animate('200ms ease-out', style({ opacity: 0 }))])
    ])
  ]
})
export class ProfilesPageMobileComponent extends ProfilesPageBaseComponent {
  @ViewChildren(ProfileCardComponent)
  profileCards: QueryList<ProfileCardComponent>;

  profiles: Profile[] = [];
  profilesFiltered: Profile[] = [];
  showFreeTextSearch = false;

  isCollapsed = false;
  isLoading = false;

  isImageModalOpen = false;
  imageModalTitle = '';
  imageModalImages = [];

  constructor(protected injector: Injector) {
    super(injector);
  }

  toggleSearchInput() {
    this.showFreeTextSearch = !this.showFreeTextSearch;
  }

  openSearchModal() {}

  showImageModal(profileId: number) {
    const profile = this.findProfileById(profileId);
    if (profile) {
      this.isImageModalOpen = true;
      this.imageModalTitle = profile.first_name + ' ' + profile.last_name;
      this.imageModalImages = [
        profile.profile_photo_url,
        ...(profile.photo_urls ?? [])
      ];
    }
  }

  sortProfiles(sortCategory: SortByCategory, direction: 'asc' | 'dsc') {
    this.profilesFiltered = this.profilesFiltered.sort((a, b) => {
      if (sortCategory === 'name') {
        const lastNameComparison = a.last_name.localeCompare(b.last_name);

        // If last names are the same, compare first names
        if (lastNameComparison === 0) {
          return direction === 'asc'
            ? a.first_name.localeCompare(b.first_name)
            : b.first_name.localeCompare(a.first_name);
        }

        // Otherwise, return comparison result based on direction
        return direction === 'asc' ? lastNameComparison : -lastNameComparison;
      } else if (sortCategory === 'age') {
        // Convert date_of_birth to Date objects for comparison
        const dateA = new Date(a.date_of_birth);
        const dateB = new Date(b.date_of_birth);

        // Compare dates
        if (dateA < dateB) {
          return direction === 'asc' ? -1 : 1;
        } else if (dateA > dateB) {
          return direction === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      }
    });
  }

  hideImageModal() {
    this.isImageModalOpen = false;
  }

  toggleCollapseAllCards(): void {
    this.isCollapsed = !this.isCollapsed;
    this.profileCards.forEach((card) => card.toggleCardExpansion());
  }

  private findProfileById(profileId: number) {
    return this.profiles.find((p) => p.id === profileId);
  }
}
