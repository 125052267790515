import { DealAvailable } from '../../../../types/deal';

export const availableDealsMock: DealAvailable[] = [
  {
    deal_id: 1,
    product_id: 100,
    sku: 'sku1',
    start_time: new Date(),
    price: 50.5,
    dealSources: [],
    product_primary_photo: 'assets/mock-assets/cap.jpeg',
    url: '',
    quantity: 0,
    notes: 'We need this product very badly',
    product_name: 'Expos Cap',
    product_description: 'Vintage high comfort level Expos baseball cap',
    available_quantity: 100,
    cancelled_quantity: 0,
    end_time: new Date(2021, 5, 1)
  },
  {
    deal_id: 2,
    product_id: 200,
    sku: 'sku2',
    start_time: new Date(),
    price: 75.99,
    dealSources: [],
    product_primary_photo: 'assets/mock-assets/jersey.jpeg',
    url: '',
    quantity: 0,
    notes: 'We need this product very badly',
    product_name: 'Habs Jersey',
    product_description: 'Vintage high comfort level Montreal Canadiens Jersey',
    available_quantity: 570,
    cancelled_quantity: 0,
    end_time: new Date(2021, 5, 1)
  },
  {
    deal_id: 1,
    sku: 'sku1',
    product_id: 100,
    start_time: new Date(),
    price: 50.5,
    dealSources: [],
    product_primary_photo: 'assets/mock-assets/cap.jpeg',
    url: '',
    quantity: 0,
    notes: 'We need this product very badly',
    product_name: 'Expos Cap',
    product_description: 'Vintage high comfort level Expos baseball cap',
    available_quantity: 100,
    cancelled_quantity: 0,
    end_time: new Date(2021, 5, 1)
  }
];
