import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'core-item-photo-display-desktop',
  templateUrl: './item-photo-display-desktop.component.html',
  styleUrl: './item-photo-display-desktop.component.scss'
})
export class ItemPhotoDisplayDesktopComponent {
  @Input() photos: string[] = [];
  @Input() uploadUrl: string = '';
  @Input() fileList: NzUploadFile[] = [];
  @Output() photoChange = new EventEmitter<NzUploadChangeParam>();

  constructor(private message: NzMessageService) {}

  handlePhotoChange(info: NzUploadChangeParam): void {
    if (info.file.status === 'done') {
      this.message.success(`${info.file.name} uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.message.error(`${info.file.name} upload failed.`);
    }
    this.photoChange.emit(info);
  }

  handleFieldSave(event: { key: string; value: any }) {
    console.log(`Saving ${event.key} with value:`, event.value);
    // Implement your save logic here
  }
}
