import { ActivatedRoute, Router } from '@angular/router';
import { Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastService } from 'ng-zorro-antd-mobile';
import { AuthService } from './auth.service';
import { BasePage } from '../page';

export type AuthPageName =
  | 'sign-in'
  | 'register'
  | 'verify'
  | 'forgot-password'
  | 'forgot-username';

export abstract class AuthPage extends BasePage {
  errorMsg = '';
  authForm: FormGroup;

  authService: AuthService;
  activatedRoute: ActivatedRoute;
  router: Router;
  toastSvc: ToastService;

  protected returnUrl: string;

  constructor(protected injector: Injector) {
    super(injector);
    this.authService = injector.get(AuthService);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.toastSvc = injector.get(ToastService);
  }

  protected checkUserState() {
    this.authService.getUserState$().subscribe((userState) => {
      if (userState && userState?.isLoggedIn) {
        this.router.navigate(['user', userState.userType]);
      }
    });
  }

  goToRegistrationPage() {
    this.router.navigate(['../register'], {
      relativeTo: this.router.routerState.root
    });
  }

  goToSignInPage() {
    this.router.navigate(['../sign-in'], {
      relativeTo: this.router.routerState.root
    });
  }

  goTo = (authPageName: AuthPageName) => {
    // TODO make this properly dynamic
    this.router.navigate([`auth/${authPageName}`], {
      relativeTo: this.router.routerState.root
    });
  };

  abstract submit();
}
