import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseUserPage } from '../user-page';
import {
  NavBarEvent,
  NavBarEventService
} from '@core/nav-bar/nav-bar-event.service';
import { AuthService } from '@core/auth';
import { Subscription } from 'rxjs';

const disabled = true;

@Component({
  selector: 'app-admin-base',
  styleUrls: ['./admin-base.scss'],
  templateUrl: './admin-base.html'
})
export class AdminBaseComponent extends BaseUserPage implements OnInit {
  childPages: string[];

  disabled = disabled;
  showSearchBar = false;
  searchValue = '';

  isMenuOpen = false;

  height: number = document.documentElement.clientHeight;

  navbarServiceSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private navbarService: NavBarEventService,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.setChildPages();

    this.navbarServiceSubscription =
      this.navbarService.navBarEventEmitter.subscribe((e) => {
        this.onNavbarEvent(e);
      });
  }

  closeMenu() {
    this.navbarService.menu(null, 'close');
  }

  onNavbarEvent(navbarEvent: NavBarEvent) {
    if (navbarEvent.type === 'menu') {
      this.isMenuOpen = !this.isMenuOpen;
    }
  }

  isMobileScreen() {
    // TODO refactor into single function throughout the app
    return window.innerWidth < 700;
  }

  signOut() {
    this.authService.signOut();
  }
}
