import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseUserPage } from '../../user-page';

@Component({
  selector: 'app-admin-home-page',
  templateUrl: './admin-home-page.component.html',
  styleUrls: ['./admin-home-page.component.scss']
})
export class AdminHomePageComponent extends BaseUserPage implements OnInit {
  childPages: string[];

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.setChildPages();
  }

  refreshCache() {}
}
