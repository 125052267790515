<nz-layout>
  <nz-content>
    <div class="profile-container">
      <nz-page-header class="profile-header">
        <nz-page-header-title>
          <ng-container *ngIf="!editingTitle; else titleEdit">
            <span (click)="editingTitle = true">{{ headerTitle }}</span>
          </ng-container>
          <ng-template #titleEdit>
            <nz-input-group nzSuffix="Save">
              <input
                nz-input
                [(ngModel)]="headerTitle"
                (blur)="editingTitle = false"
              />
            </nz-input-group>
          </ng-template>
        </nz-page-header-title>
        <nz-page-header-subtitle>
          {{ headerSubtitle }}
        </nz-page-header-subtitle>
      </nz-page-header>

      <!-- Main content with tabs -->
      <nz-tabset>
        <!-- Profile Tab -->
        <nz-tab nzTitle="Profile">
          <div nz-row [nzGutter]="24">
            <!-- Photos Carousel -->
            <div nz-col [nzSpan]="12">
              <ng-content select="[photos]"></ng-content>
            </div>

            <div nz-col [nzSpan]="12">
              <ng-content select="[personalInfo]"></ng-content>
            </div>
          </div>
        </nz-tab>

        <!-- Dating Preferences Tab -->
        <!-- <nz-tab nzTitle="Dating Preferences">
          <nz-card>
            <form nz-form>
              <nz-form-item>
                <nz-form-label>Age Range</nz-form-label>
                <nz-form-control>
                  <nz-slider
                    nzRange
                    [(ngModel)]="profile.preferences.ageRange"
                    [nzMin]="18"
                    [nzMax]="100"
                    name="ageRange"
                  ></nz-slider>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label>Preferred Location</nz-form-label>
                <nz-form-control>
                  <input
                    nz-input
                    [(ngModel)]="profile.preferences.location"
                    name="prefLocation"
                  />
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label>Religious Level</nz-form-label>
                <nz-form-control>
                  <nz-select
                    [(ngModel)]="profile.preferences.religiousLevel"
                    name="religiousLevel"
                  >
                    <nz-option nzValue="secular" nzLabel="Secular"></nz-option>
                    <nz-option
                      nzValue="traditional"
                      nzLabel="Traditional"
                    ></nz-option>
                    <nz-option
                      nzValue="religious"
                      nzLabel="Religious"
                    ></nz-option>
                    <nz-option
                      nzValue="very_religious"
                      nzLabel="Very Religious"
                    ></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>

              <button nz-button nzType="primary" (click)="saveItemInfo()">
                Save Preferences
              </button>
            </form>
          </nz-card>
        </nz-tab> -->
      </nz-tabset>
    </div>
  </nz-content>
</nz-layout>
