import { Component, Input, OnInit } from '@angular/core';
import { PurchaseOrderForAdminReports } from '../../../../../../../types/purchase-order';

@Component({
  selector: 'oka-buyer-purchase-order-item',
  templateUrl: './buyer-purchase-order-item.component.html',
  styleUrls: ['./buyer-purchase-order-item.component.scss']
})
export class BuyerPurchaseOrderItemComponent implements OnInit {
  @Input() purchaseOrder: PurchaseOrderForAdminReports;

  constructor() {}

  ngOnInit(): void {}
}
