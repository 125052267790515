import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AbstractApiService } from '../../../services/abstract-api.service';
import { Profile } from '../../../../../../../types/profile';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../environments/environment';

type ProfileEntityType<T> = T extends string
  ? 'string'
  : T extends number
  ? 'integer'
  : never;

interface ProfileEntity<K extends keyof Profile> {
  var_name: K;
  type: ProfileEntityType<Profile[K]>;
  description: string;
}

type ProfileEntities = {
  [K in keyof Profile]: ProfileEntity<K>;
}[keyof Profile][];

@Injectable({
  providedIn: 'root'
})
export class ShadchanNlpService extends AbstractApiService {
  nlpServiceUrl = 'https://ai-textraction.p.rapidapi.com/textraction';

  profileEntities: ProfileEntities = [
    {
      type: 'string',
      var_name: 'gender',
      description: 'Gender'
    },
    {
      type: 'string',
      var_name: 'first_name',
      description: 'first name'
    },
    {
      type: 'string',
      var_name: 'last_name',
      description: 'last name'
    },
    {
      type: 'string',
      var_name: 'city',
      description: 'City'
    },
    {
      type: 'string',
      var_name: 'country',
      description: 'Country'
    },
    {
      type: 'string',
      var_name: 'date_of_birth',
      description: 'Date of Birth'
    },
    {
      type: 'string',
      var_name: 'marital_status',
      description: 'Marital Status'
    }
  ];

  constructor(private http: HttpClient) {
    super();
  }

  submitRawText(rawText: string): Observable<Partial<Profile>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-RapidAPI-Key': environment.rapidKey,
      'X-RapidAPI-Host': 'ai-textraction.p.rapidapi.com'
    });

    return this.http
      .post<{ results: Partial<Profile>; stats: any }>(
        this.nlpServiceUrl,
        {
          text: rawText,
          entities: this.profileEntities
        },
        { headers }
      )
      .pipe(map((r) => r.results));
  }
}
