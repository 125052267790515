import {
  Component,
  Injector,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ShadchanService } from '../../services/shadchan.service';
import { ActivatedRoute } from '@angular/router';
import {
  GenderEnum,
  Profile,
  ProfileFull
} from '../../../../../../../../types/profile';
import { ActionSheetService, ModalService, en_US } from 'ng-zorro-antd-mobile';
import { ProfileFormMobileComponent } from '../../../../common/profile-form-mobile/profile-form-mobile.component';
import { Subscription, filter, map } from 'rxjs';
import { SearchService } from '../../../../services/search-service.service';
import { AppPageComponent } from '../../../../common/app-page/app-page.component';
import { SHARING_APPS } from '../../../../../../../../common/constants';
import { MatchPopulated } from '../../../../../../../../types/match';

type ProfileKeys = Array<keyof Profile>;
const ProfilePropertiesForIdentitySection: ProfileKeys = [
  'first_name',
  'last_name',
  'date_of_birth',
  'city',
  'country',
  'occupation',
  'religious_identity',
  'tehillim_name'
];

@Component({
  selector: 'shdchn-profile-page-mobile',
  templateUrl: './profile-page-mobile.component.html',
  styleUrl: './profile-page-mobile.component.scss'
})
export class ProfilePageMobileComponent
  extends AppPageComponent
  implements OnInit, OnDestroy
{
  @ViewChild('profileForm') profileFormComponent: ProfileFormMobileComponent;
  @ViewChild('matchList') matchListTemplate: TemplateRef<any>;

  profileId: number;
  profile: ProfileFull;
  images = [];

  isEditMode = false;

  activeTabIndex = 0;

  isProfileLoaded = false;

  activeKey = [0, 1, 2];

  eligibleMatches: Partial<Profile>[] = [];
  isLoadingEligibleMatches = false;
  isLoadingNewMatchCreation = false;

  showPhotoCarousel = false;
  isShowingMatchmakingModal = false;

  ProfilePropertiesForIdentitySection = ProfilePropertiesForIdentitySection;

  appList = SHARING_APPS.map((obj) => ({
    icon: `<img src="assets/icons/${obj.name}.png" style="width:36px"/>`,
    title: obj.title,
    name: obj.name
  }));

  // Subscriptions
  searchServiceSubscription: Subscription;

  constructor(
    injector: Injector,
    private shadchanService: ShadchanService,
    private searchService: SearchService,
    private _actionSheet: ActionSheetService,
    private modalMobileService: ModalService,
    private activeRoute: ActivatedRoute
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();

    // this.validatePage(() => {
    //   this.profileId = parseInt(this.activeRoute.snapshot.params.profileId, 10);
    //   if (isNaN(this.profileId)) {
    //     throw 'Invalid Profile ' + this.activeRoute.snapshot.params.profileId;
    //   }
    // });

    this.profileId = parseInt(this.activeRoute.snapshot.params.profileId, 10);

    this.getFullProfile(this.profileId);

    this.searchServiceSubscription = this.searchService.searchEvent.subscribe(
      (e) => {
        if (e.data && e.type !== 'cancel' && e.type !== 'clear') {
          this.eligibleMatches = this.eligibleMatches.filter(
            (profile) =>
              profile.first_name.includes(e.data) ||
              profile.last_name.includes(e.data)
          );
        }
      }
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.searchServiceSubscription) {
      this.searchServiceSubscription.unsubscribe();
    }
  }

  hideMatchmakingModal() {
    this.isShowingMatchmakingModal = false;
  }

  isDateField(fieldName: string) {
    if (fieldName.indexOf('date') >= 0) {
      return true;
    }

    return false;
  }

  selectEligibleMatch(eligibleProfile: Profile) {
    this.isLoadingNewMatchCreation = true;

    let [maleProfileId, femaleProfileId] = this.prepareIdsByGender(
      this.profile,
      eligibleProfile
    );

    this.shadchanService
      .createNewMatch(
        maleProfileId,
        femaleProfileId,
        `Shidduch created between ${this.profile.first_name} ${this.profile.last_name} and ${eligibleProfile.first_name} ${eligibleProfile.last_name}`
      )
      .subscribe((result) => {
        this.getFullProfile(this.profileId, true);
        this.isLoadingNewMatchCreation = false;
        this.hideMatchmakingModal();
        this.activeTabIndex = 1;
      });
  }

  getFullProfile(profileId: number, refresh = false) {
    this.shadchanService.getFullProfile(profileId, refresh).subscribe((p) => {
      this.profile = p;
      // this.images = [p.profile_photo_url, ...(p.photo_urls || [])];
      this.images = [p.profile_photo_url, p.profile_photo_url];
      this.isProfileLoaded = true;
    });
  }

  cancelProfileEdits() {
    this.isEditMode = false;
  }

  editProfile() {
    this.isEditMode = true;
  }

  onActionClick(event: any) {}

  onAccordionChange(openPanels: string[]) {
    if (openPanels.includes('photos')) {
      setTimeout(() => {
        console.log('Show carousel set ON');
        this.showPhotoCarousel = true;
      }, 1000);
    }
  }

  onTabChange(event: any) {}

  onTabClick(event: any) {}

  saveProfileEdits() {
    this.isEditMode = false;
    this.profileFormComponent.save(this.profileId).subscribe((result) => {
      this.getFullProfile(parseInt(result.data), true);
    });
  }

  showProfileActions(message) {
    let BUTTONS = [];
    if (this.isEditMode) {
      BUTTONS = ['Save Edits', 'Cancel Edits'];
    } else {
      BUTTONS = ['Redt a Shidduch', 'Edit Profile', 'Cancel', 'Delete'];
    }

    this._actionSheet.showActionSheetWithOptions(
      {
        options: BUTTONS,
        destructiveButtonIndex: BUTTONS.length - 1,
        cancelButtonIndex: BUTTONS.length - 2,
        title: 'Profile Options',
        locale: en_US,
        // message: message,
        maskClosable: true
      },
      (buttonIndex) => {
        if (buttonIndex >= 0) {
          if (BUTTONS[buttonIndex] === 'Edit Profile') {
            this.editProfile();
          } else if (BUTTONS[buttonIndex] === 'Save Edits') {
            this.saveProfileEdits();
          } else if (BUTTONS[buttonIndex] === 'Cancel Edits') {
            this.cancelProfileEdits();
          } else if (BUTTONS[buttonIndex] === 'Redt a Shidduch') {
            this.showMatchmakingModal();
          }
        }
      }
    );
  }

  showMatchmakingModal() {
    this.loadEligibleMatches();
    this.isShowingMatchmakingModal = true;
  }

  showShareActions = () => {
    console.log('Here 2?');
    this._actionSheet.showShareActionSheetWithOptions(
      {
        options: this.appList,
        message: 'Send Profile',
        locale: en_US
      },
      (buttonIndex) => {
        if (buttonIndex >= 0) {
          const message = encodeURIComponent(
            this.shadchanService.generateProfileAsText(this.profile)
          );
          if (this.appList[buttonIndex].name === 'whatsapp') {
            window.open(`https://wa.me/?text=${message}`, '_blank');
          } else if (this.appList[buttonIndex].name === 'email') {
            const emailUrl = `mailto:${''}?subject=${encodeURIComponent(
              'Profile of ' +
                this.profile.first_name +
                ' ' +
                this.profile.last_name
            )}&body=${encodeURIComponent(message)}`;
            window.open(emailUrl, '_system');
          }
        }

        return new Promise((resolve) => {
          setTimeout(resolve, 0);
        });

        // return new Promise((resolve) => {
        //   setTimeout(resolve, 500);
        // });
      }
    );
  };

  onShareAction(buttonIndex) {
    if (buttonIndex >= 0) {
      const message = encodeURIComponent(
        this.shadchanService.generateProfileAsText(this.profile)
      );
      if (SHARING_APPS[buttonIndex].name === 'whatsapp') {
        window.open(`https://wa.me/?text=${message}`, '_blank');
      } else if (this.appList[buttonIndex].name === 'email') {
        const emailUrl = `mailto:${''}?subject=${encodeURIComponent(
          'Profile of ' + this.profile.first_name + ' ' + this.profile.last_name
        )}&body=${encodeURIComponent(message)}`;
        window.open(emailUrl, '_system');
      }
    }

    return new Promise((resolve) => {
      setTimeout(resolve, 0);
    });
  }

  getFocusedProfile(match: MatchPopulated) {
    return this.profileId === match.male_profile_id ? match.male : match.female;
  }
  getMatchedProfile(match: MatchPopulated) {
    return this.profileId === match.male_profile_id ? match.female : match.male;
  }

  private loadEligibleMatches() {
    this.isLoadingEligibleMatches = true;
    this.shadchanService
      .getProfiles()
      .pipe(
        map((profiles) =>
          profiles.filter(
            (p) => p.gender !== this.profile.gender && p.id !== this.profile.id
          )
        )
      )
      .subscribe((profilesFiltered) => {
        this.eligibleMatches = profilesFiltered;
        this.isLoadingEligibleMatches = false;
      });
  }

  private prepareIdsByGender(
    profile1: Profile,
    profile2: Profile
  ): [maleProfileId: number, femaleProfileId: number] {
    let maleProfileId, femaleProfileId;

    if (
      profile1.gender === GenderEnum.male &&
      profile2.gender === GenderEnum.female
    ) {
      maleProfileId = this.profile.id;
      femaleProfileId = profile2.id;
    } else if (
      profile1.gender === GenderEnum.female &&
      profile2.gender === GenderEnum.male
    ) {
      maleProfileId = profile2.id;
      femaleProfileId = this.profile.id;
    } else {
      throw 'Genders are not valid';
    }

    return [maleProfileId, femaleProfileId];
  }
}
