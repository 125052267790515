import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  DealAvailable,
  DealCancelled,
  DealCommitted,
  DealCompleted
} from '../../../../../../types/deal';
import { HttpResponse } from '../../../../../../types/misc';
import { BuyerApiService } from '../../user-pages/buyer/services/buyer.api.service';

type DealItemType = 'available' | 'committed' | 'completed' | 'cancelled';
@Component({
  selector: 'app-buyer-deal-item',
  templateUrl: './deal-item.component.html',
  styleUrls: ['./deal-item.component.scss']
})
export class DealItemComponent implements OnInit {
  @Input() dealItem:
    | DealAvailable
    | DealCommitted
    | DealCompleted
    | DealCancelled;
  @Input() mode: DealItemType;

  isDealCommitted = false;
  isAdmin = false;
  buyQuantity = 1;
  modifiedBuyQty = 0;

  message = '';

  showModifyQuantify = false;

  constructor(
    private buyerApiService: BuyerApiService,
    private nzMessageService: NzMessageService
  ) {}

  ngOnInit() {
    // TODO make this work. "Available Deals" tab should know if a deal already has some quantity committed
    this.isDealCommitted = !!this.dealItem.quantity;
  }

  edit() {}

  commitToDeal() {
    if (this.buyQuantity <= 0) {
      this.nzMessageService.error('Quantity must be more than zero');
      return;
    }

    this.buyerApiService
      .commitToDeal(this.dealItem.deal_id, this.buyQuantity)
      .subscribe(
        (_responseJson: any) => {
          this.nzMessageService.success(
            `Successfully committed to purchase ${this.buyQuantity} ${this.dealItem.product_name}s`
          );
          this.buyQuantity = 0;
        },
        (error: HttpResponse) => {
          this.nzMessageService.error(error.message);
          console.log(error);
        }
      );
  }

  cancelCommitment() {
    // For now, we will not allow cancellations when deal is partially filled
    if ((this.dealItem as DealCommitted).completed_quantity) {
      return;
    }

    let enRouteQuantityPrompt = prompt(
      'Please enter the amount of items that are already en route'
    );
    if (enRouteQuantityPrompt === null || enRouteQuantityPrompt.length === 0) {
      return;
    }

    let enRouteQuantity = parseInt(enRouteQuantityPrompt);
    if (isNaN(enRouteQuantity)) {
      enRouteQuantity = 0;
    }

    const dealItem = this.dealItem as DealCommitted;
    this.buyerApiService
      .cancelCommitment(dealItem.buyer_deal_id, enRouteQuantity)
      .subscribe(() => {
        this.isDealCommitted = false;
        this.message = 'Deal Commitment has been cancelled';
      });
  }

  toggleShowModifyQuantify() {
    this.showModifyQuantify = !this.showModifyQuantify;
  }

  modifyCommitment() {
    this.showModifyQuantify = !this.showModifyQuantify;

    const dealItem = this.dealItem as DealCommitted;
    this.buyerApiService
      .modifyDealCommitment(dealItem.buyer_deal_id, this.modifiedBuyQty)
      .subscribe(
        (_responseJson: any) => {
          this.dealItem.quantity = this.modifiedBuyQty;
          this.nzMessageService.success(
            `Successfully modified the deal. Now committed to purchase ${this.modifiedBuyQty} ${dealItem.product_name}s`
          );
        },
        (error: HttpResponse) => {
          this.nzMessageService.error(error.message);
          console.log(error);
        }
      );
  }
}
