import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CustomQuery } from '../../../../../../../types/admin';
import { HttpResponseBody } from '../../../../../../../types/misc';
import { environment } from '../../../../environments/environment';

const BASE_URL = environment.baseUrl + '' + environment.apiUrl + '/admin';

@Injectable()
export class CustomQueryService {
  constructor(private http: HttpClient) {}

  loadQueries() {
    return this.http.get(BASE_URL + '/custom-queries').pipe(
      map((r) => {
        console.log('r', r);
        return r as Omit<CustomQuery, 'sql'>[];
      })
    );
  }

  runQuery(queryName: string) {
    return this.http
      .get(BASE_URL + '/custom-queries/query/' + queryName)
      .pipe(map((r: HttpResponseBody<any>) => r.data));
  }
}
