import { isNil } from '@common/third-party/micro-dash';
import { Directive, Injector, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { findKey } from '../../../../../common/third-party/micro-dash';
import { BehaviorSubject } from 'rxjs';
import { User } from '../../../../../types/user';
import { ColumnDefs, SearchMeta } from '../../types-frontend/misc';

@Directive()
export class AbstractOkaTable<T> implements OnInit {
  @Input() data$: BehaviorSubject<T[]>;
  @Input() columnHeaders: Extract<keyof T, string>[] = [];

  columnDefs: ColumnDefs<T>[] = [];
  currentSearchColumn: keyof T = this.columnHeaders[0];
  dataForDisplay: T[];
  isSearchFilterApplied = false;
  searchValues: SearchMeta<T> = {};

  private route: ActivatedRoute;
  private router: Router;

  constructor(injector: Injector) {
    this.route = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
  }

  ngOnInit(): void {
    this.data$.subscribe((data) => {
      this.dataForDisplay = data;
    });

    this.columnHeaders.forEach((ch) => {
      this.searchValues[ch] = { value: '', visible: false };
    });

    this.generateColumnDefs();
  }

  generateColumnDefs() {
    for (const fieldName of this.columnHeaders) {
      this.columnDefs.push({
        headerName: fieldName,
        sortFn: this.tableSortFn(fieldName),
        sortOrder: 'descend'
      });
    }
  }

  onRowClick(navParam: string, relativeTo?: ActivatedRoute | null) {
    this.router.navigate(
      [navParam],
      relativeTo === null ? {} : { relativeTo: this.route }
    );
  }

  tableSortFn<RowItem>(fieldName: string) {
    return (a: RowItem, b: RowItem) => {
      if (isNil(a[fieldName]) || isNil(b[fieldName])) {
        return 1;
      } else if (typeof a[fieldName] === 'number') {
        return (a[fieldName] as number) - (b[fieldName] as number);
      } else if (typeof a[fieldName] === 'string') {
        return a[fieldName].toString().localeCompare(b[fieldName].toString());
      } else if (typeof a[fieldName] === 'boolean') {
        return a[fieldName] ? 1 : -1;
      } else {
        throw new Error(
          'Unexpected type for ' + a[fieldName] + ', ' + b[fieldName]
        );
      }
    };
  }

  resetAllSearchFilters() {
    Object.keys(this.searchValues).forEach((k) => {
      this.searchValues[k] = { value: '', visible: false };
    });
    // this.dataForDisplay = [...this.data];
    this.dataForDisplay = [...this.data$.getValue()];
    this.isSearchFilterApplied = false;
  }

  resetSearchColumn() {
    this.searchValues[this.currentSearchColumn] = { value: '', visible: false };
    // this.dataForDisplay = [...this.data];
    this.dataForDisplay = [...this.data$.getValue()];
  }

  searchColumn() {
    const header = findKey<SearchMeta<User>>(
      this.searchValues,
      (i) => i.visible
    );
    this.dataForDisplay = [
      ...this.dataForDisplay.filter(
        (i) =>
          String(i[header as keyof User])
            .toLocaleLowerCase()
            .indexOf(this.searchValues[header].value.toLocaleLowerCase()) >= 0
      )
    ];
    this.searchValues[header].visible = false;
    this.isSearchFilterApplied = true;
  }

  searchValuesOpened(isOpened: boolean, headerName: keyof T) {
    if (isOpened) {
      this.currentSearchColumn = headerName;
      this.searchValues[headerName].visible = true;
    } else {
      this.searchValues[headerName].visible = false;
    }
  }
}
