import {
  ActivatedRoute,
  Event,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { RouteCustom, RoutesCustom } from '../app-routing.module';
import { Injector } from '@angular/core';

export class BaseUserPage {
  activatedRoute: ActivatedRoute;
  routeConfig: RouteCustom;
  childPages: string[];

  constructor(injector: Injector) {
    this.activatedRoute = injector.get(ActivatedRoute);
  }

  setChildPages() {
    if (!this.activatedRoute.snapshot.routeConfig.children) {
      this.activatedRoute.snapshot.routeConfig.children = [];
    }

    this.routeConfig = this.activatedRoute.snapshot.routeConfig;
    this.childPages = this.routeConfig.children
      .filter((i) => !i.hideFromNavBar)
      .filter((i) => i.path.length > 0)
      .filter((i) => i.path.indexOf('/') < 0)
      .map((i) => i.path);
  }
}
