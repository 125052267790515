import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BuyerItemProcessedReport } from '../../../../../../../types/buyer-report';
import { DealCommittedAggregated } from '../../../../../../../types/deal';
import {
  HttpResponse,
  HttpResponseBody
} from '../../../../../../../types/misc';
import { PackageSummary } from '../../../../../../../types/package';
import {
  PaymentsMetadata,
  ProcessedPayment,
  RequestedPayment
} from '../../../../../../../types/payment';
import {
  PurchaseOrder,
  PurchaseOrderFull
} from '../../../../../../../types/purchase-order';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BuyerApiService {
  buyerApiUrl = environment.baseUrl + environment.apiUrl + '/buyer';

  headers = {
    'Content-Type': 'application/json'
  };

  constructor(private http: HttpClient) {}

  getPackagedProductsReport(): Observable<BuyerItemProcessedReport[]> {
    const url = this.buyerApiUrl + '/get-packaged-products-report';
    const headers = {
      'Content-Type': 'application/json'
    };

    return this.http
      .get<HttpResponse<BuyerItemProcessedReport[]>>(url, { headers: headers })
      .pipe(map((d) => d.data));
  }

  getTrackingNumbersSummary(): Observable<HttpResponseBody<PackageSummary[]>> {
    const url = this.buyerApiUrl + '/tracking-numbers-summary';
    const headers = {
      'Content-Type': 'application/json'
    };

    return this.http.get<any>(url, { headers: headers });
  }

  insertTrackingNumbers(trackingNumbers: string[]): Observable<any> {
    const url = this.buyerApiUrl + '/tracking-number';
    const headers = {
      'Content-Type': 'application/json'
    };

    return this.http
      .post<any>(url, { trackingNumbers }, { headers: headers })
      .pipe(
        catchError((err: { error: HttpResponse }) =>
          throwError(err.error.message)
        )
      );
  }

  getAvailableDeals() {
    const headers = {
      'Content-Type': 'application/json'
    };

    return this.http.get<any>(this.buyerApiUrl + '/deals', { headers });
  }

  commitToDeal(dealId: number, quantity: number) {
    const headers = {
      'Content-Type': 'application/json'
    };

    return this.http
      .put<any>(
        this.buyerApiUrl + '/buyer-deal',
        { activeDealId: String(dealId), quantity },
        { headers }
      )
      .pipe(
        catchError((error) => {
          return throwError(error.error);
        })
      );
  }

  cancelCommitment(buyerDealId: number, enRouteQuantity: number) {
    const headers = {
      'Content-Type': 'application/json'
    };

    return this.http.delete<any>(
      this.buyerApiUrl +
        '/buyer-deal/' +
        String(buyerDealId) +
        `?enRouteQuantity=${enRouteQuantity}`,
      { headers }
    );
  }

  modifyDealCommitment(buyerDealId: number, quantity: number) {
    const headers = {
      'Content-Type': 'application/json'
    };

    return this.http
      .post<any>(
        this.buyerApiUrl + '/buyer-deal/' + String(buyerDealId),
        { quantity },
        { headers }
      )
      .pipe(
        catchError((error) => {
          return throwError(error.error);
        })
      );
  }

  deleteTrackingNumber(tn: string) {
    return this.http.delete<any>(this.buyerApiUrl + '/tracking-number/' + tn);
  }

  getCommittedDeals() {
    const headers = {
      'Content-Type': 'application/json'
    };

    return this.http.get<HttpResponse<DealCommittedAggregated[]>>(
      this.buyerApiUrl + '/committed-deals',
      { headers }
    );
  }

  getCompletedDeals() {
    const headers = {
      'Content-Type': 'application/json'
    };

    return this.http.get<any>(this.buyerApiUrl + '/completed-deals', {
      headers
    });
  }

  getCancelledDeals() {
    const headers = {
      'Content-Type': 'application/json'
    };

    return this.http.get<any>(this.buyerApiUrl + '/cancelled-deals', {
      headers
    });
  }

  getPaymentsMetadata() {
    return this.http
      .get<HttpResponse<PaymentsMetadata>>(
        this.buyerApiUrl + '/payments/metadata',
        {
          headers: this.headers
        }
      )
      .pipe(map((result) => result.data));
  }

  getPurchaseOrders(_page = 0, isUnpaid = false) {
    let url = this.buyerApiUrl + '/purchase-orders';
    if (isUnpaid) {
      url += '?isUnpaid=true';
    }

    return this.http.get<HttpResponse<PurchaseOrder[]>>(url, {
      headers: this.headers
    });
  }

  getPurchaseOrder(poId: string): Observable<PurchaseOrderFull> {
    const url = this.buyerApiUrl + '/purchase-order/' + poId;
    return this.http
      .get<HttpResponse<PurchaseOrderFull>>(url, {
        headers: this.headers
      })
      .pipe(map((result) => result.data));
  }

  approvePurchaseOrder(poId: string): Observable<any> {
    const url = this.buyerApiUrl + '/approve-purchase-order';
    const headers = {
      'Content-Type': 'application/json'
    };

    return this.http
      .post<any>(url, { poId }, { headers: headers })
      .pipe(
        catchError((err: { error: HttpResponse }) =>
          throwError(err.error.message)
        )
      );
  }

  requestPayment(
    purchaseOrderIds: string[],
    requestNotes: string | null = null
  ) {
    const url = this.buyerApiUrl + '/payments/request';
    const headers = {
      'Content-Type': 'application/json'
    };

    return this.http
      .post<any>(url, { purchaseOrderIds, requestNotes }, { headers: headers })
      .pipe(
        catchError((err: { error: HttpResponse }) =>
          throwError(err.error.message)
        )
      );
  }

  getBuyerPayments(_page = 0) {
    const headers = {
      'Content-Type': 'application/json'
    };

    return this.http
      .get<
        HttpResponse<{
          requestPayments: RequestedPayment[];
          processedPayments: ProcessedPayment[];
        }>
      >(this.buyerApiUrl + '/payments', { headers })
      .pipe(map((d) => d.data));
  }
}
