import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject } from 'rxjs';
import { Payment } from '../../../../../../../types/payment';
import { OkaModalService } from '../../../common/modal-common/oka-modals.service';
import { GenerateChequeModalComponent } from '../modals/generate-cheque-modal/generate-cheque-modal.component';
import { PaymentService } from '../services/payment.service';

@Component({
  selector: 'oka-admin-payments-page',
  templateUrl: './admin-payments-page.component.html',
  styleUrls: ['./admin-payments-page.component.scss']
})
export class AdminPaymentsPageComponent implements OnInit {
  paymentsColumnHeaders = [
    'status',
    'who',
    'amount',
    'requested_at',
    'cancelled_at',
    'cheque_generated_at',
    'request_notes'
  ];
  paymentsData = new BehaviorSubject<Payment[]>([]);

  paymentStatus: boolean = false;

  selectedPayments: Payment[] = [];

  constructor(
    private paymentService: PaymentService,
    private nzMessageService: NzMessageService,
    private modalService: OkaModalService
  ) {}

  ngOnInit(): void {
    this.loadPaymentData(this.paymentStatus);
  }

  onPaymentStatusToggle(event: boolean) {
    this.paymentStatus = event;
    this.loadPaymentData(this.paymentStatus);
  }

  loadPaymentData(paymentStatus: boolean) {
    let status: 'complete' | 'incomplete';

    if (paymentStatus) {
      status = 'complete';
    } else {
      status = 'incomplete';
    }

    this.paymentService.getPayments(status).subscribe(
      (paymentData: Payment[]) => {
        this.paymentsData.next(paymentData);
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  generateChequeCSV() {
    if (this.selectedPayments.length > 0) {
      this.modalService
        .openModal(GenerateChequeModalComponent, {})
        .subscribe((modalRef) => {
          modalRef.afterClose.subscribe(
            (response: { proposedFirstChequeId: number }) => {
              if (response !== undefined) {
                this.paymentService
                  .generateChequesCSV(
                    response.proposedFirstChequeId,
                    this.selectedPayments
                  )
                  .subscribe((data) => {
                    anchorButtonOperation(data.chequesCSV as any);
                    this.refreshData();
                  });
              }
            }
          );
        });
    } else {
      this.nzMessageService.warning('Plese select atleast one payment id');
    }
  }

  refreshData() {
    this.loadPaymentData(this.paymentStatus);
  }

  updateSelectedPayments(payments: Payment[]) {
    this.selectedPayments = payments;
  }

  paymentOperation(payment: Payment) {
    this.refreshData();
  }
}

function anchorButtonOperation(csvData: ArrayBufferLike) {
  const anchorButton = document.createElement('a');
  anchorButton.href = window.URL.createObjectURL(
    new Blob([csvData], { type: 'text/csv' })
  );
  anchorButton.setAttribute('hidden', '');

  anchorButton.setAttribute('download', 'payment.csv');
  document.body.appendChild(anchorButton);
  anchorButton.click();
  anchorButton.remove(); // Delete the temporary element
}
