import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Injector,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import {
  NavBarEvent,
  NavBarEventService
} from '@core/nav-bar/nav-bar-event.service';
import { SearchService } from '../../../services/search-service.service';
import { Subscription } from 'rxjs';
import { PageNavigationService } from '../../../services/page-navigation.service';
import { ApplicationPages } from '../../../../../../../common/constants';
import { environment } from '../../../../environments/environment';
import { BasePage } from '../../page';
import { AuthService } from '@core/auth';

const disabled = true;
@Component({
  selector: 'shdchn-base-mobile',
  templateUrl: './shadchan-base-mobile.component.html',
  styleUrl: './shadchan-base-mobile.component.scss'
})
export class ShadchanBaseMobileComponent
  extends BasePage
  implements OnInit, AfterViewInit, OnDestroy
{
  childPages: string[];
  disabled = disabled;
  showGenderToggleBar = false;
  showSearchBar = false;
  searchValue = '';
  username = '';

  isMenuOpen = false;

  height: number = document.documentElement.clientHeight;

  actionPopover: TemplateRef<any>;

  // Subscriptions
  searchServiceSearchBarSubscription: Subscription;
  navbarServiceSubscription: Subscription;

  version = environment.version;

  constructor(
    protected injector: Injector,
    private authService: AuthService,
    private navbarService: NavBarEventService,
    private pageNavService: PageNavigationService,
    private searchService: SearchService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.setChildPages();
    this.username = this.authService.getUserState$().value.username;

    this.searchServiceSearchBarSubscription =
      this.searchService.searchEvent.subscribe((r) => {
        if (r.type === 'cancel') {
          this.showSearchBar = false;
        }
      });

    this.navbarServiceSubscription =
      this.navbarService.navBarEventEmitter.subscribe((e) => {
        console.log('Nav Bar Event Happened!!', e);
        this.onNavbarEvent(e);
      });

    this.pageNavService.pageNavigationEvent$.subscribe((navEvent) => {
      this.showGenderToggleBar = this.shouldShowGenderToggle(navEvent.data);
    });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.searchServiceSearchBarSubscription.unsubscribe();
    this.navbarServiceSubscription.unsubscribe();
  }

  closeMenu() {
    this.navbarService.menu(null, 'close');
  }

  onNavbarEvent(navbarEvent: NavBarEvent) {
    if (navbarEvent.type === 'search') {
      this.showSearchBar = !this.showSearchBar;
    } else if (navbarEvent.type === 'menu') {
      this.isMenuOpen = !this.isMenuOpen;
    } else if (navbarEvent.type === 'set') {
      this.actionPopover = navbarEvent.data;
      this.cdr.detectChanges(); // Needed to avoid ExpressionChanged errors
    }
  }

  newProfile() {
    this.router.navigate(['/user/shadchan/new']);
  }

  signOut() {
    this.authService.signOut();
  }

  shouldShowGenderToggle(pageName: ApplicationPages) {
    if (pageName === 'profiles') {
      return true;
    } else {
      return false;
    }
  }
}
