import { Component, OnInit } from '@angular/core';
import { Product } from '../../../../../../../types/product';
import { User } from '../../../../../../../types/user';
import { Package } from '../../../../../../../types/package';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AdminImportDataService } from '../services/import-data.service';

type ImportDataType = 'products' | 'users' | 'packages';
const csvHeadersUsers: Array<keyof Omit<User, 'user_id'>> = [
  'user_type',
  'username',
  'first_name',
  'last_name',
  'email',
  'phone_number'
];
const csvHeadersProducts: Array<keyof Omit<Product, 'product_id'>> = [
  'product_name',
  'quickbooks_product_id',
  'product_category',
  'product_primary_photo',
  'product_secondary_photos',
  'upc',
  'sku',
  'product_description',
  'created_at'
];

const csvHeadersPackage: Array<keyof Package> = [
  'tracking_number',
  'created_at',
  'check_in_time',
  'buyer_id'
];

@Component({
  selector: 'app-import-data-page',
  templateUrl: './import-data-page.component.html',
  styleUrls: ['./import-data-page.component.scss']
})
export class ImportDataPageComponent implements OnInit {
  importData: string;
  importDataType: ImportDataType;
  csvHeaders:
    | Array<keyof Omit<User, 'user_id'>>
    | Array<keyof Omit<Product, 'product_id'>>
    | Array<keyof Package> = [];

  csvHeadersUsers = csvHeadersUsers;
  csvHeadersProducts = csvHeadersProducts;
  csvHeadersPackage = csvHeadersPackage;

  constructor(
    private nzMessageService: NzMessageService,
    private importDataService: AdminImportDataService
  ) {}

  ngOnInit(): void {}

  onImportDataTypeChange(dataType: ImportDataType) {
    switch (dataType) {
      case 'products':
        this.csvHeaders = this.csvHeadersProducts;
        break;
      case 'users':
        this.csvHeaders = this.csvHeadersUsers;
        break;
      case 'packages':
        this.csvHeaders = this.csvHeadersPackage;
    }
  }

  resetPage() {
    this.importDataType = null;
    this.importData = '';
    this.csvHeaders = [];
  }

  submit() {
    this.importDataService
      .importData(this.importDataType, this.importData)
      .subscribe((responseJson: any) => {
        this.nzMessageService.success('Data import success');
      });
    this.nzMessageService.success(`${this.importDataType} data import started`);
    this.resetPage();
  }
}
