import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PackagedProductInventoryItem } from '../../../../../../../../types/packaged-product';
import { AdminInventoryService } from '../../services/inventory.service';

@Component({
  selector: 'app-admin-inventory-reports-page',
  templateUrl: './admin-inventory-reports-page.component.html'
})
export class AdminInventoryReportsPageComponent implements OnInit {
  inventory: BehaviorSubject<PackagedProductInventoryItem[]> =
    new BehaviorSubject([]);

  columnHeaders = [
    'packaged_product_id',
    'product_name',
    'product_serial',
    'product_description',
    'buyer',
    'tracking_number',
    'upc',
    'sku',
    'committed_deal_id',
    'deal_id',
    'committed_deal_price',
    'price',
    'check_in_time',
    'created_at'
  ];

  showSpinner = false;

  constructor(private adminApiService: AdminInventoryService) {}

  ngOnInit() {
    this.showSpinner = true;
    this.adminApiService.getInventoryReport().subscribe((reportData) => {
      this.inventory.next(reportData);
      this.showSpinner = false;
    });
  }

  exportAsCSV() {}
}
