import { Component, Host, Input, OnInit } from '@angular/core';
import { WarehouseHomepageComponent } from '../warehouse-homepage/warehouse-homepage.component';
@Component({
  selector: 'app-scanned-upc-serial',
  templateUrl: './scanned-upc-serial.component.html',
  styleUrls: ['./scanned-upc-serial.component.scss']
})
export class ScannedUpcSerialComponent implements OnInit {
  @Input() index;
  @Input() item: { upc: string; serial: string };

  constructor(@Host() private warehouseHomePage: WarehouseHomepageComponent) {}

  ngOnInit(): void {}

  deleteScannedItem() {
    this.warehouseHomePage.removeProduct(this.index);
  }
}
