import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NavBarDesktopComponent } from './nav-bar-desktop/nav-bar-desktop.component';
import { NavBarMobileComponent } from './nav-bar-mobile/nav-bar-mobile.component';
import { NzMenuModule } from 'ng-zorro-antd/menu';

const NG_ZORRO_DESKTOP_IMPORTS = [NzLayoutModule, NzMenuModule];

import {
  LOCAL_PROVIDER_TOKEN,
  NgZorroAntdMobileModule,
  en_US as en_US_mobile
} from 'ng-zorro-antd-mobile';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [NavBarDesktopComponent, NavBarMobileComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgZorroAntdMobileModule,
    ...NG_ZORRO_DESKTOP_IMPORTS
  ],
  exports: [NavBarDesktopComponent, NavBarMobileComponent]
})
export class NavBarModule {}
