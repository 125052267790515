import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../../../environments/environment';
import { ModalComponent } from '../../../../common/modal-common/modal.component';
import { ProductCategoryService } from '../../services/product-category.service';
import { ProductService } from '../../services/product.service';
import { ProductCategory } from '../../../../../../../../types/product';

@Component({
  selector: 'app-create-new-product-modal',
  templateUrl: './create-new-product-modal.component.html',
  styleUrls: ['./create-new-product-modal.component.scss']
})
export class CreateNewProductModalComponent
  extends ModalComponent
  implements OnInit, AfterViewInit
{
  title = 'Create New Product';
  productList = [];
  createNewProductForm: FormGroup = new FormGroup({});
  categoryList: ProductCategory[] = [];
  isLoadingCategories = false;

  @ViewChild('tpl') public templateref: TemplateRef<any>;
  formatterDollar = (value: number) => `$ ${value}`;
  parserDollar = (value: string) => value.replace('$ ', '');

  constructor(
    nzModal: NzModalService,
    private productService: ProductService,
    private productCategoryService: ProductCategoryService,
    private nzMessageService: NzMessageService
  ) {
    super(nzModal);
  }

  ngAfterViewInit(): void {
    this.openModal({}, () => {
      this.productService
        .createProduct({
          ...this.createNewProductForm.value,
          product_name: this.createNewProductForm.get('sku').value
        })
        .subscribe(
          (cp) => {
            this.nzMessageService.success(
              `Product ${this.createNewProductForm.get('sku').value} Created`
            );
            this.closeModal();
          },
          (errorMsg) => {
            this.nzMessageService.error(errorMsg);
          }
        );
    });
  }

  ngOnInit(): void {
    this.productCategoryService
      .getProductCategories()
      .subscribe((result: ProductCategory[]) => {
        this.categoryList = result;
      });

    const isDevMode = environment.env_name === 'dev';

    this.createNewProductForm = new FormGroup({
      product_category: new FormControl(
        isDevMode ? 'Toys' : null,
        Validators.required
      ),
      sku: new FormControl(isDevMode ? 'Toysku1' : null, Validators.required),
      product_description: new FormControl(
        isDevMode ? 'ToyDescript1' : null,
        Validators.required
      ),
      product_primary_photo: new FormControl(null),
      upc: new FormControl(isDevMode ? 'Toyupc1' : null, Validators.required)
    });
  }

  onSearch(event: any) {}

  private createNewProduct() {}
}
