import { Component, Injector, OnInit } from '@angular/core';
import { RequestedPayment } from '../../../../../../types/payment';
import { AbstractOkaTable } from '../abstract-oka-table';

@Component({
  selector: 'oka-requested-payments-table',
  templateUrl: './requested-payments-table.component.html',
  styleUrls: ['../oka-tables-styles.scss']
})
export class RequestedPaymentsTableComponent
  extends AbstractOkaTable<RequestedPayment>
  implements OnInit
{
  currentSearchColumn: keyof RequestedPayment = 'amount';

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
