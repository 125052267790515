import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output
} from '@angular/core';
import { find } from '@common/third-party/micro-dash';
import { Payment } from '../../../../../../types/payment';
import { PaymentService } from '../../user-pages/admin/services/payment.service';
import { AbstractOkaTable } from '../abstract-oka-table';

@Component({
  selector: 'oka-admin-payments-table',
  templateUrl: './admin-payments-table.component.html',
  styleUrls: ['../oka-tables-styles.scss']
})
export class AdminPaymentsTableComponent
  extends AbstractOkaTable<Payment>
  implements OnInit
{
  @Output() selectionUpdated: EventEmitter<Payment[]> = new EventEmitter();
  @Output() paymentOperation: EventEmitter<Payment> = new EventEmitter();

  currentSearchColumn: keyof Payment = 'amount';

  selectedPayments: Payment[] = [];

  constructor(injector: Injector, private paymentService: PaymentService) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  isSelectedPayment(payment: Payment) {
    return !!find(
      this.selectedPayments,
      (p) => p.payment_id === payment.payment_id
    );
  }

  onPaymentSelected(product: Payment, checked: boolean): void {
    this.updateSelectedPayment(product, checked);
    this.selectionUpdated.emit(this.selectedPayments);
    // this.refreshCheckedStatus();
  }

  updateSelectedPayment(payment: Payment, checked: boolean): void {
    if (checked) {
      this.selectedPayments.push(payment);
    } else {
      this.selectedPayments = this.selectedPayments.filter(
        (p) => p.payment_id !== payment.payment_id
      );
    }

    // this.purchaseOrderPriceTotal = this.selectedPayments.reduce(
    //   (acc, i) => acc + (i.price || i.committed_deal_price || 0),
    //   0
    // );
  }

  acceptPayment(payment: Payment) {
    this.paymentService.acceptPayment(payment).subscribe(() => {
      this.paymentOperation.emit(payment);
    });
  }

  rejectPayment(payment: Payment) {
    this.paymentService.rejectPayment(payment).subscribe(() => {
      this.paymentOperation.emit(payment);
    });
  }
}
