import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Customer } from '../../../../../../../types/invoice';
import { AccountantApiService } from '../services/accountant.service';
import { PackagedProductForAccountant } from '../../../../../../../types/packaged-product';

@Component({
  selector: 'oka-invoice-creation-drawer',
  templateUrl: './invoice-creation-drawer.component.html',
  styleUrls: ['./invoice-creation-drawer.component.scss']
})
export class InvoiceCreationDrawerComponent implements OnInit {
  @Input() items: PackagedProductForAccountant[] = [];
  @Input() allNonInvoicedPackagedProducts: PackagedProductForAccountant[];

  invoiceCreationForm: FormGroup = new FormGroup({});

  customerLookupValue = '';
  skuLookupValue = '';

  matchingCustomerListItems: Customer[] = [];
  matchingSKUs: { sku: string; max_qty: number }[] = [];

  customerList: Customer[] = [];
  skuList: { sku: string; max_qty: number }[] = [];
  maxQty: number[] = [];

  skuQty: number = 0;
  skuPrice: number = 0;

  invoiceSkuItems = [];

  priceTotal = 0;

  constructor(
    private drawerRef: NzDrawerRef<string>,
    private accountantApiService: AccountantApiService
  ) {}

  ngOnInit(): void {
    this.generateCreateInvoiceForm();
    this.loadCustomers();
    this.loadSKUs();

    this.priceTotal = this.items.reduce((acc, i) => acc + i.price, 0);
  }

  get customerName() {
    return this.invoiceCreationForm.get('customerName') as FormControl;
  }

  get skuItems() {
    return this.invoiceCreationForm.get('skuItems') as FormArray;
  }

  generateCreateInvoiceForm() {
    this.invoiceCreationForm = new FormGroup({
      customerName: new FormControl('', [Validators.required]),
      skuItems: new FormArray([])
    });

    this.invoiceCreationForm
      .get('skuItems')
      .valueChanges.subscribe((skuItems) => {
        this.priceTotal = skuItems.reduce(
          (acc, skuItem) => acc + skuItem.quantity * skuItem.price,
          0
        );
      });

    this.invoiceCreationForm
      .get('skuItems')
      .valueChanges.subscribe((skuItems) => {
        // TODO synchronize skuItems with items object
      });
  }

  addInvoiceSkuItem() {
    const dealSourceForm = this.generateEmptySkuItemForm();
    this.skuItems.push(dealSourceForm);
    this.maxQty.push(1000);
  }

  private generateEmptySkuItemForm(): FormGroup {
    const skuItemForms = new FormGroup({
      sku: new FormControl('', [Validators.required]),
      quantity: new FormControl('', [Validators.required]),
      price: new FormControl('', [Validators.required])
    });

    return skuItemForms;
  }

  close(): void {
    this.drawerRef.close({
      customer: this.customerLookupValue,
      draftInvoice: this.items
    });
  }

  createInvoice() {
    this.close();
  }

  loadCustomers() {
    this.accountantApiService.getCustomers().subscribe((customers) => {
      this.customerList = customers;
      this.matchingCustomerListItems = customers;
    });
  }

  loadSKUs() {
    this.accountantApiService
      .getPackagedProductsSku()
      .subscribe((skus: { sku: string; max_qty: number }[]) => {
        this.skuList = skus;
        this.matchingSKUs = skus;
      });
  }

  onCustomerSearch(value: string) {
    const valueLowercase = value.toLocaleLowerCase();

    this.matchingCustomerListItems = this.customerList.filter(
      (i) =>
        i.first_name.toLocaleLowerCase().indexOf(valueLowercase) >= 0 ||
        i.last_name.toLocaleLowerCase().indexOf(valueLowercase) >= 0 ||
        i.user_id.toString().toLocaleLowerCase().indexOf(valueLowercase) >= 0
    );
  }

  onSkuSearch(value: string) {
    const valueLowercase = value.toLocaleLowerCase();
    this.matchingSKUs = this.skuList.filter(
      (i) => i.sku.toLocaleLowerCase().indexOf(valueLowercase) >= 0
    );
  }

  onSkuSelection(value: string, i: number) {
    const valueLowercase = value.toLocaleLowerCase();
    for (const sku of this.skuList) {
      if (sku.sku.toLocaleLowerCase() === valueLowercase) {
        const maxQty = sku.max_qty;
        this.maxQty[i] = maxQty;
        break;
      }
    }
  }

  // makeSelectionOfSKUs() {
  //   // Remove any selected products
  //   const items = this.items;
  //   const filteredPackagedProducts = this.allNonInvoicedPackagedProducts.filter(
  //     function (val) {
  //       return items.indexOf(val) == -1;
  //     }
  //   );

  //   // Sort the packaged products by check in time, with the oldest one at the top
  //   // TODO verify on backend
  //   const packagedProducts = filteredPackagedProducts.sort((d1, d2) => {
  //     return new Date(d1.check_in_time).getTime() <
  //       new Date(d2.check_in_time).getTime()
  //       ? 1
  //       : -1;
  //   });

  //   let buyer = '';
  //   if (this.items.length > 0) {
  //     buyer = this.items[0].buyer;
  //   }

  //   let currentQty = 0;
  //   for (const packagedProduct of packagedProducts) {
  //     if (packagedProduct.sku === this.skuLookupValue) {
  //       if (buyer.length === 0 && currentQty === 0) {
  //         buyer = packagedProduct.buyer;
  //       }

  //       // Items needs to be from the same buyer
  //       if (packagedProduct.buyer === buyer) {
  //         packagedProduct.price = this.skuPrice;
  //         this.items.push(packagedProduct);
  //         currentQty += 1;

  //         if (currentQty === this.skuQty) {
  //           break;
  //         }
  //       }
  //     }
  //   }

  //   if (this.skuQty !== currentQty) {
  //     this.skuQty = currentQty;
  //   }

  //   // TODO
  //   // make network request
  // }
}
