import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PackagedProductInventoryItem } from '../../../../../../../types/packaged-product';
import { environment } from '../../../../environments/environment';
import { AbstractApiService } from '../../../services/abstract-api.service';

@Injectable({
  providedIn: 'root'
})
export class AdminInventoryService extends AbstractApiService {
  adminApiUrl = environment.baseUrl + environment.apiUrl + '/admin';

  constructor(private http: HttpClient) {
    super();
  }

  getInventoryReport() {
    return this.http
      .get<{ data: PackagedProductInventoryItem[] }>(
        this.adminApiUrl + '/inventory-report'
      )
      .pipe(map((i) => i.data as PackagedProductInventoryItem[]));
  }
}
