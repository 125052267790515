import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { AuthPage } from '../auth-page';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { absAnimations } from '@core/animations';

@Component({
  selector: 'auth-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['../auth-common.scss', './reset-password-page.component.scss'],
  providers: [{ provide: AuthPage, useExisting: ResetPasswordPageComponent }],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class ResetPasswordPageComponent extends AuthPage {
  token = '';

  constructor(protected injector: Injector, private formBuilder: FormBuilder) {
    super(injector);

    this.token = this.activatedRoute.snapshot.queryParams['token'];
  }

  ngOnInit(): void {
    this.checkUserState();

    if (!this.token.length) {
      this.goTo('sign-in');
    }

    this.authForm = this.formBuilder.group({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      password_confirmation: new FormControl('', Validators.required)
    });
  }

  submit() {
    if (
      this.authForm.value.password !== this.authForm.value.password_confirmation
    ) {
      this.toastSvc.fail(
        'The password and password confirmation are not the same'
      );
      return;
    }

    this.authService
      .resetPassword(
        this.authForm.value.username,
        this.authForm.value.password,
        this.token
      )
      .subscribe((result) => {
        if (!!result) {
          this.toastSvc.success('Your account has been activated');
          this.router.navigate(['../sign-in'], {
            relativeTo: this.router.routerState.root
          });
        } else {
          this.toastSvc.fail('Verification Code is not correct');
        }
      });
  }
}
