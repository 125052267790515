import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Product } from '../../../../../../../types/product';
import { QuickbooksCardData } from '../base-details/base-details-quickbooks-sync/base-details-quickbooks-sync.component';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-product-details-page',
  templateUrl: './product-details-page.component.html',
  styleUrls: ['./product-details-page.component.scss']
})
export class ProductDetailsPageComponent implements OnInit {
  upc: string;

  productData: Product;
  quickbooksData: QuickbooksCardData;

  updateProductForm: FormGroup = this.formBuilder.group({});

  constructor(
    private productService: ProductService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private nzMessageService: NzMessageService
  ) {}

  ngOnInit(): void {
    this.upc = this.activeRoute.snapshot.params.upc;
    this.loadProductData();

    this.updateProductForm = this.formBuilder.group({
      category: ['', Validators.required],
      productName: ['', Validators.required],
      productDescription: ['', Validators.required],
      productPrimaryPhoto: ['', Validators.required],
      productSecondaryPhoto: [undefined]
    });
  }

  deleteProduct(productId: number) {
    if (confirm('Are you sure you want to delete this product?')) {
      this.productService.deleteProduct(productId).subscribe(
        () => {
          this.router.navigate(['../..'], { relativeTo: this.activeRoute });
          this.nzMessageService.success('The product deleted successfully');
        },
        (error) => {
          this.nzMessageService.error('Unable to delete product');
        }
      );
    }
  }

  loadProductData() {
    this.productService.getProduct(this.upc).subscribe(
      (productDetails: Product) => {
        if (
          typeof productDetails === 'undefined' ||
          Object.keys(productDetails).length === 0
        ) {
          this.nzMessageService.error('This product does not exists');
          this.router.navigate(['../'], { relativeTo: this.activeRoute });
          return;
        }

        this.productData = productDetails;
        this.quickbooksData = {
          quickbooksUrl: String(productDetails.quickbooks_product_id),
          quickbooksLastSync: productDetails.quickbooks_last_sync
        };
        this.resetUpdateUserDataForm();
      },
      (error) => {
        this.router.navigate(['../..'], { relativeTo: this.activeRoute });
        this.nzMessageService.error('The user does not exist');
      }
    );
  }

  private resetUpdateUserDataForm() {
    this.updateProductForm
      .get('category')
      .setValue(this.productData.product_category);
    this.updateProductForm
      .get('productName')
      .setValue(this.productData.product_name);
    this.updateProductForm
      .get('productDescription')
      .setValue(this.productData.product_description);
    this.updateProductForm
      .get('productPrimaryPhoto')
      .setValue(this.productData.product_primary_photo);
    this.updateProductForm
      .get('productSecondaryPhoto')
      .setValue(this.productData.product_primary_photo);
  }

  updateProductData() {
    const secondaryPhotos = this.updateProductForm.get(
      'productSecondaryPhoto'
    ).value;

    const payload = {
      product_name: this.updateProductForm.get('productName').value,
      product_description:
        this.updateProductForm.get('productDescription').value,
      product_category: parseInt(this.updateProductForm.get('category').value),
      product_primary_photo: this.updateProductForm.get('productPrimaryPhoto')
        .value,
      product_secondary_photos: secondaryPhotos
        ? secondaryPhotos.split(',')
        : ''
    };

    this.productService
      .updateProduct(this.productData.product_id, payload)
      .subscribe(
        () => {
          this.nzMessageService.success(
            'The product has been updated successfully'
          );
        },
        (error) => {
          this.nzMessageService.error('Unable to update product');
        }
      );
  }

  synchronizeWithQuickbooks() {
    this.productService
      .synchronizeProductWithQuickbooks({ ...this.productData, upc: this.upc })
      .subscribe(() => {
        this.nzMessageService.success('Sync success');
        this.loadProductData();
      });
  }
}
