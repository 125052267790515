import {
  APP_INITIALIZER,
  ModuleWithProviders,
  NgModule,
  Provider
} from '@angular/core';
import { AuthConfig, CanActivateRouteGuardConfig } from './auth.types';
import { ForgotPasswordPageComponent } from './forgot-password-page/forgot-password-page.component';
import { RegisterPageComponent } from './register-page/register-page.component';
import { ResetPasswordPageComponent } from './reset-password-page/reset-password-page.component';
import { SignInPageComponent } from './sign-in-page/sign-in-page.component';
import { VerifyPageComponent } from './verify-page/verify-page.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { AuthCommonTemplateShellComponent } from './auth-common/auth-template-shell/auth-template-shell.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth-interceptor.service';
import { AuthCommonButtonComponent } from './auth-common/auth-button/auth-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { AuthRoutingModule } from './auth-routing.module';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { AuthService } from './auth.service';
import { JWTInterceptor } from './jwt-interceptor.service';

const NgZorroDesktopModules = [
  NzButtonModule,
  NzFormModule,
  NzIconModule,
  NzInputModule,
  NzLayoutModule,
  NzCardModule,
  NzSelectModule
];

const NgZorroMobileModules = [];

const ANGULAR_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];

const DECLARED_COMPONENTS = [
  AuthCommonButtonComponent,
  AuthCommonTemplateShellComponent
];

const EXPORTED_COMPONENTS = [
  SignInPageComponent,
  ForgotPasswordPageComponent,
  RegisterPageComponent,
  ResetPasswordPageComponent,
  VerifyPageComponent
];

const DEFAULT_GUARD_CONFIG: CanActivateRouteGuardConfig = {
  isValidUserRoute: (userType: string, routePath: string) =>
    routePath === userType,
  getUserRoute: (userType: string) => `user/${userType}`,
  signInRoute: 'auth/sign-in',
  unauthorizedRedirect: 'auth/unauthorized'
};

function initializeAuth(authService: AuthService) {
  return () => authService.loadUserState();
}

@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    ...NgZorroDesktopModules,
    ...NgZorroMobileModules,
    AuthRoutingModule
  ],
  declarations: [...DECLARED_COMPONENTS, ...EXPORTED_COMPONENTS],
  exports: [...EXPORTED_COMPONENTS]
})
export class AuthModule {
  static forRoot(
    config: AuthConfig & { guardConfig: CanActivateRouteGuardConfig }
  ): ModuleWithProviders<AuthModule> {
    const PROVIDERS: Provider[] = [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      },
      {
        provide: 'AUTH_CONFIG',
        useValue: config
      },
      {
        provide: 'CAN_ACTIVATE_ROUTE_GUARD_CONFIG',
        useValue: { ...DEFAULT_GUARD_CONFIG, ...config.guardConfig }
      },
      {
        provide: APP_INITIALIZER,
        useFactory: initializeAuth,
        deps: [AuthService],
        multi: true
      }
    ];

    if (config.env_name !== 'frontend-dev') {
      PROVIDERS.push({
        provide: HTTP_INTERCEPTORS,
        useClass: JWTInterceptor,
        multi: true
      });
    }

    return {
      ngModule: AuthModule,
      providers: PROVIDERS
    };
  }
}
