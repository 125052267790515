import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { ApplicationPagesShadchanEnum } from '../../../../common/constants';
import { AdminBaseComponent } from './user-pages/admin/admin-base.component';
import { AdminHomePageComponent } from './user-pages/admin/admin-home-page/admin-home-page.component';
import { UserDetailsPageComponent } from './user-pages/admin/user-details-page/user-details-page.component';
import { UserManagementPageComponent } from './user-pages/admin/user-management-page/user-management-page.component';
import { MatchPageMobileComponent } from './user-pages/shadchan/match-page-mobile/match-page-mobile.component';
import { NewProfileMobileComponent } from './user-pages/shadchan/new-profile-mobile/new-profile-mobile.component';
import { ProfilePageWrapperComponent } from './user-pages/shadchan/profile-page/profile-page-wrapper/profile-page-wrapper.component';
import { ProfilesPageWrapperComponent } from './user-pages/shadchan/profiles-page/profiles-page-wrapper/profiles-page-wrapper.component';
import { ShadchanBaseMobileComponent } from './user-pages/shadchan/shadchan-base-mobile/shadchan-base-mobile.component';
import { TehillimPageComponent } from './user-pages/shadchan/tehillim-page/tehillim-page.component';
import { UserPagesComponent } from './user-pages/user-pages.component';
import { CanActivateUserRoute } from '@core/auth';

export interface RouteCustom extends Route {
  hideFromNavBar?: boolean;
  children?: RoutesCustom;
}
export type RoutesCustom = RouteCustom[];

const routes: RoutesCustom = [
  {
    path: 'auth',
    loadChildren: () =>
      import('@core/auth').then((m) => {
        return m.AuthModule;
      })
  },

  {
    path: 'user',
    component: UserPagesComponent,
    children: [
      {
        path: 'shadchan',
        component: ShadchanBaseMobileComponent,
        canActivate: [CanActivateUserRoute],
        children: [
          {
            path: '', // Default route
            redirectTo: ApplicationPagesShadchanEnum.home,
            pathMatch: 'full'
          },
          {
            // "home"
            path: ApplicationPagesShadchanEnum.home,
            component: ProfilesPageWrapperComponent
          },
          {
            // "profile/:profileId",
            path: ApplicationPagesShadchanEnum.profile + '/:profileId',
            component: ProfilePageWrapperComponent
          },
          {
            // "match/:matchId",
            path: 'match/:matchId',
            component: MatchPageMobileComponent
          },
          {
            // "new"
            path: ApplicationPagesShadchanEnum.new,
            component: NewProfileMobileComponent
          },
          {
            // "tehillim"
            path: ApplicationPagesShadchanEnum.tehillim,
            component: TehillimPageComponent
          },
          {
            path: '**',
            redirectTo: ApplicationPagesShadchanEnum.home,
            hideFromNavBar: true
          }
        ]
      },
      {
        path: 'admin',
        component: AdminBaseComponent,
        canActivate: [CanActivateUserRoute],
        children: [
          {
            path: 'home',
            component: AdminHomePageComponent
          },
          {
            path: 'user-management',
            component: UserManagementPageComponent
          },
          {
            path: 'user-management/users/:username',
            component: UserDetailsPageComponent
          }
        ]
      }
    ]
  },

  {
    path: '',
    redirectTo: 'user/shadchan',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'auth'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule],
  providers: [CanActivateUserRoute]
})
export class AppRoutingModule {}
