import { Component, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Deal, DealCreation } from '../../../../../../../types/deal';
import { getValuesFromForm } from '../../../common/utils';
import { dealCreationColumnHeaders } from '../admin-common/admin-constants';
import { AdminDealService } from '../services/deal.service';

type DealStatus = 'active' | 'inactive';
@Component({
  selector: 'app-deal-details-page',
  templateUrl: './deal-details-page.component.html',
  styleUrls: ['./deal-details-page.component.scss']
})
export class DealDetailsPageComponent implements OnInit {
  mode: 'read' | 'edit' = 'read';
  dealId: number;
  dealData: Deal;
  dealSourcesMode: 'read' | 'edit' = 'read';
  dealStatus: DealStatus;
  updateDealForm: FormGroup = this.formBuilder.group({});
  isTextMessageModalVisible = false;
  currentDate = new Date();

  constructor(
    private dealService: AdminDealService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private nzMessageService: NzMessageService,
    private clipboard: Clipboard
  ) {}

  ngOnInit(): void {
    this.updateDealForm = this.formBuilder.group({
      start_time: ['', Validators.required],
      end_time: ['', Validators.required],
      price: ['', Validators.required],
      max_quantity: ['', Validators.required],
      is_public: ['', Validators.required]
    });
    this.updateDealForm.disable();

    this.dealId = this.activeRoute.snapshot.params.dealId;
    this.load(Number(this.dealId));
  }

  deleteDeal() {
    this.dealService.deleteDeal(this.dealId).subscribe(() => {
      this.nzMessageService.success('Deal Successfully Deactivated');
      this.load(Number(this.dealId));
    });
  }

  load(dealId: number) {
    this.dealService.getDealForAdmin(dealId).subscribe((deal) => {
      if (typeof deal === 'undefined' || Object.keys(deal).length === 0) {
        this.nzMessageService.error('This deal does not exists');
        this.router.navigate(['../'], { relativeTo: this.activeRoute });
        return;
      }

      this.dealData = deal;
      this.setDealStatus();

      this.resetUpdateDealDataForm();
    });
  }

  loadListOfCommittedBuyers() {}

  endNow() {
    const currentTime = new Date();
    this.updateDealForm.get('end_time').setValue(currentTime);
    this.dealData.end_time = currentTime;
    this.updateDealData();
  }

  generateTextMessage() {
    this.isTextMessageModalVisible = true;
  }
  handleCancelTextMessage() {
    this.isTextMessageModalVisible = false;
  }
  handleCopyTextMessage() {
    const modalBody = document.getElementsByClassName('ant-modal-body');
    if (modalBody.length > 0) {
      const modalContent: any = modalBody[0];
      const textMessage = modalContent.innerText;
      this.clipboard.copy(textMessage);
      this.nzMessageService.success('Copied to clipboard');
    }
  }

  setDealStatus() {
    if (
      this.dealData.deleted_at ||
      new Date(this.dealData.end_time) < new Date()
    ) {
      this.dealStatus = 'inactive';
    } else {
      this.dealStatus = 'active';
    }
  }

  switchDealSourcesInformationMode() {
    if (this.mode === 'read') {
      this.dealSourcesMode = 'edit';
      // this.formGroup.enable();
    } else {
      this.dealSourcesMode = 'read';
      // this.formGroup.disable();
    }
  }

  resetUpdateDealDataForm() {
    this.updateDealForm.get('start_time').setValue(this.dealData.start_time);
    this.updateDealForm.get('end_time').setValue(this.dealData.end_time);
    this.updateDealForm.get('price').setValue(this.dealData.price);
    this.updateDealForm
      .get('max_quantity')
      .setValue(this.dealData.max_quantity);
    this.updateDealForm.get('is_public').setValue(this.dealData.is_public);
  }

  updateDealData() {
    const formValues = getValuesFromForm<DealCreation>(
      this.updateDealForm,
      dealCreationColumnHeaders
    );
    this.dealService.createOrEditDeal(formValues, this.dealId).subscribe(
      (result) => {
        this.nzMessageService.success(`Deal ${this.dealId} Updated`);
        this.setDealStatus();
      },
      (error) => {
        this.nzMessageService.error('Unable to update the deal');
        this.load(Number(this.dealId));
      }
    );
  }
}
