import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpResponse } from '../../../../../../../types/misc';
import { Payment } from '../../../../../../../types/payment';
import { environment } from '../../../../environments/environment';
import { AbstractApiService } from '../../../services/abstract-api.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends AbstractApiService {
  adminApiUrl = environment.baseUrl + environment.apiUrl + '/admin';

  constructor(private http: HttpClient) {
    super();
  }

  getPayments(status?: 'complete' | 'incomplete', _page = 0) {
    let url = this.adminApiUrl + '/payments';
    if (status) {
      url += '?status=' + status;
    }
    return this.http
      .get<HttpResponse<Payment[]>>(url, {
        headers: this.headers
      })
      .pipe(map((d) => d.data));
  }

  acceptPayment(payment: Payment) {
    let url = this.adminApiUrl + `/payments/accept/` + payment.payment_id;
    return this.http.post<HttpResponse<Payment[]>>(url, payment);
  }

  rejectPayment(payment: Payment) {
    let url = this.adminApiUrl + `/payments/reject/` + payment.payment_id;
    return this.http.post<HttpResponse<Payment[]>>(url, payment);
  }

  generateChequesCSV(proposedFirstChequeId: number, payments: Payment[]) {
    let url = this.adminApiUrl + '/payments/generate-cheques-csv';

    const paymentIds = [];
    for (const payment of payments) {
      paymentIds.push(payment.payment_id);
    }

    return this.http
      .post<HttpResponse<{ chequesCSV: object }>>(url, {
        paymentIds,
        proposedFirstChequeId
      })
      .pipe(map((d) => d.data));
  }
}
