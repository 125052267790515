import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Profile } from '../../../../../../types/profile';

@Component({
  selector: 'shdchn-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrl: './profile-card.component.scss',
  animations: [
    trigger('changeHeight', [
      state(
        'open',
        style({
          // height: "100%",
          maxHeight: '2000px',
          opacity: 1
        })
      ),
      state(
        'closed',
        style({
          // height: "0px",
          maxHeight: '0px',
          opacity: 0
        })
      ),
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.3s')])
    ])
  ]
})
export class ProfileCardComponent implements OnInit, AfterViewInit {
  @Input() profileData: Profile;
  avatarPath: string;

  gridStyle = {
    width: '25%',
    textAlign: 'center'
  };

  dotPosition = 0;

  isExpanded = true;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.avatarPath = this.profileData.profile_photo_url;
  }

  collapseCard() {}

  expandCard() {}

  openProfile() {
    this.router.navigate(['user/shadchan/profile/', this.profileData.id]);
  }

  toggleCardExpansion() {
    this.isExpanded = !this.isExpanded;
  }

  goToProfile() {
    this.router.navigate(['/user/shadchan/profile/' + this.profileData.id]);
  }
}
