import { Component, Injector } from '@angular/core';
import { BasePage } from '../../../page';

@Component({
  selector: 'app-profiles-page-wrapper',
  styles: '',
  template: `
    <ng-container *ngIf="isMobileScreen$ | async; else desktop">
      <shdchn-profiles-page></shdchn-profiles-page>
    </ng-container>
    <ng-template #desktop>
      <shdchn-profiles-page-desktop></shdchn-profiles-page-desktop>
    </ng-template>
  `
})
export class ProfilesPageWrapperComponent extends BasePage {
  constructor(protected injector: Injector) {
    super(injector);
  }
}
