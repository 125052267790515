import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HotTableModule } from '@handsontable/angular';
import { AgGridModule } from 'ag-grid-angular';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { environment } from '../environments/environment';
import { MockBackendInterceptor } from '../mock-backend/mock-backend.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CacheService } from './common/cache.service';
import { DealItemComponent } from './common/deal-item/deal-item.component';
import {
  NgzorroCommonModule,
  ngZorroIcons
} from './common/ngzorro-common.module';
import { OkaCommonModule } from './common/oka-common.module';
import { ProfileCardComponent } from './common/profile-card/profile-card.component';
import { DemoPageComponent } from './demo-page/demo-page.component';
import { HttpErrorInterceptor } from './services/http-error-interceptor.service';
import { OkaTablesModule } from './tables/oka-tables.module';
import { AccountantModule } from './user-pages/accountant/accountant.module';
import { AccountingHomepageComponent } from './user-pages/accountant/accounting-home-page/accounting-homepage.component';
import { SetPriceModalComponent } from './user-pages/accountant/modals/set-price-modal/set-price-modal.component';
import { PurchaseOrderDrawerComponent } from './user-pages/accountant/purchase-order-drawer/purchase-order-drawer.component';
import { AdminBaseComponent } from './user-pages/admin/admin-base.component';
import { AdminHomePageComponent } from './user-pages/admin/admin-home-page/admin-home-page.component';
import { AdminModule } from './user-pages/admin/admin.module';
import { CreateOrEditActiveDealModalModule } from './user-pages/admin/modals/create-new-active-deal-modal/create-or-edit-active-deal-modal.module';
import { CreateNewProductCategoryModalModule } from './user-pages/admin/modals/create-new-product-category-modal/create-new-product-category-modal.module';
import { CreateNewProductModalModule } from './user-pages/admin/modals/create-new-product-modal/create-new-product-modal.module';
import { CreateNewUserModalModule } from './user-pages/admin/modals/create-new-user-modal/create-new-user-modal.module';
import { GenerateChequeModalModule } from './user-pages/admin/modals/generate-cheque-modal/generate-cheque-modal.module';
import { UpdateUserModalModule } from './user-pages/admin/modals/update-user-modal/update-user-modal.module';
import { ProductsPageComponent } from './user-pages/admin/products-page/products-page.component';
import { CustomQueryPageComponent } from './user-pages/admin/reports-page/custom-query-page/custom-query-page.component';
import { CustomQueryResultsTableComponent } from './user-pages/admin/reports-page/custom-query-page/custom-query-results-table/custom-query-results-table.component';
import { UserManagementPageComponent } from './user-pages/admin/user-management-page/user-management-page.component';
import { BuyerBaseComponent } from './user-pages/buyer/buyer-base.component';
import { BuyerDealsPageComponent } from './user-pages/buyer/buyer-deals-page/buyer-deals-page.component';
import { BuyerHomepageComponent } from './user-pages/buyer/buyer-home-page/buyer-homepage.component';
import { BuyerReportsPageComponent } from './user-pages/buyer/buyer-reports-page/buyer-reports-page.component';
import { BuyerModule } from './user-pages/buyer/buyer.module';
import { NonBuyerSharedModule } from './user-pages/non-buyer-shared/non-buyer-shared.module';
import { NewProfileComponent } from './user-pages/shadchan/new-profile/new-profile.component';
import { ProfilePageDesktopComponent } from './user-pages/shadchan/profile-page/profile-page-desktop/profile-page-desktop.component';
import { UserPagesComponent } from './user-pages/user-pages.component';
import { WelcomeComponent } from './welcome/welcome.component';

import { AuthModule } from '@core/auth';
import { CoreModule } from '@core/core.module';
import {
  LOCAL_PROVIDER_TOKEN,
  NgZorroAntdMobileModule,
  en_US as en_US_mobile
} from 'ng-zorro-antd-mobile';
import { MatchPageActionPopoverComponent } from './common/action-popovers/match-page-action-popover/match-page-action-popover.component';
import { ProfilePagePopoverComponent } from './common/action-popovers/profile-page-action-popover/profile-page-action-popover.component';
import { AgePipe } from './common/age.pipe';
import { GenderToggleDesktopComponent } from './common/gender-toggle-desktop/gender-toggle-desktop.component';
import { GenderToggleMobileComponent } from './common/gender-toggle-mobile/gender-toggle-mobile.component';
import { JsonPropertyPipe } from './common/json-property.pipe';
import { MatchCardThumbComponent } from './common/match-card-thumb/match-card-thumb.component';
import { MatchCardComponent } from './common/match-card/match-card.component';
import { ProfileCardMobileComponent } from './common/profile-card-mobile/profile-card-mobile.component';
import { ProfileFormMobileComponent } from './common/profile-form-mobile/profile-form-mobile.component';
import { SearchBarMobileComponent } from './common/search-bar-mobile/search-bar-mobile.component';
import { SortPopoverComponent } from './common/sort-popover/sort-popover.component';
import { MatchPageMobileComponent } from './user-pages/shadchan/match-page-mobile/match-page-mobile.component';
import { NewProfileMobileComponent } from './user-pages/shadchan/new-profile-mobile/new-profile-mobile.component';
import { ProfilePageMobileComponent } from './user-pages/shadchan/profile-page/profile-page-mobile/profile-page-mobile.component';
import { ProfilePageWrapperComponent } from './user-pages/shadchan/profile-page/profile-page-wrapper/profile-page-wrapper.component';
import { ProfilesPageDesktopComponent } from './user-pages/shadchan/profiles-page/profiles-page-desktop/profiles-page-desktop.component';
import { ProfilesPageMobileComponent } from './user-pages/shadchan/profiles-page/profiles-page-mobile/profiles-page-mobile.component';
import { ProfilesPageWrapperComponent } from './user-pages/shadchan/profiles-page/profiles-page-wrapper/profiles-page-wrapper.component';
import { ShadchanBaseMobileComponent } from './user-pages/shadchan/shadchan-base-mobile/shadchan-base-mobile.component';
import { TehillimPageComponent } from './user-pages/shadchan/tehillim-page/tehillim-page.component';
import { WarehouseModule } from './user-pages/warehouse/warehouse.module';

registerLocaleData(en);

const AUTH_CONFIG = {
  env_name: environment.env_name,
  baseUrl: environment.baseUrl,
  userStateKey: 'shdchnappuserstate',
  customStyles: {
    primaryColor: '#ff0000',
    logoUrl: 'assets/my-logo.png'
  },
  userHomeUrl: (userState) => `user/${userState.userType}`
};

const CAN_ACTIVATE_ROUTE_GUARD_CONFIG = {
  unauthorizedRedirect: '/',
  getUserRoute: (userType) => `user/${userType}`,
  isValidUserRoute: (userType, currentPath) => {
    // Custom logic for your app
    return currentPath === userType || currentPath === `${userType}-desktop`;
  },
  signInRoute: '/auth/sign-in'
};

const PROVIDERS: Provider[] = [
  CacheService,
  { provide: NZ_I18N, useValue: en_US },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true
  },
  { provide: LOCAL_PROVIDER_TOKEN, useValue: en_US_mobile }
];

if (environment.env_name === 'frontend-dev') {
  PROVIDERS.push({
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: MockBackendInterceptor,
    multi: true
  });
}

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    AccountingHomepageComponent,
    BuyerHomepageComponent,
    AdminHomePageComponent,
    UserPagesComponent,
    DemoPageComponent,
    UserManagementPageComponent,
    AdminBaseComponent,
    BuyerBaseComponent,
    CustomQueryPageComponent,
    BuyerReportsPageComponent,
    BuyerDealsPageComponent,
    DealItemComponent,
    PurchaseOrderDrawerComponent,
    SearchBarMobileComponent,
    ProductsPageComponent,
    SetPriceModalComponent,
    CustomQueryResultsTableComponent,

    // Common
    MatchPageActionPopoverComponent,
    ProfilePagePopoverComponent,
    SortPopoverComponent,

    // Pipes
    AgePipe,
    JsonPropertyPipe,

    // Shadchan
    GenderToggleDesktopComponent,
    GenderToggleMobileComponent,
    MatchCardComponent,
    MatchCardThumbComponent,
    MatchPageMobileComponent,
    NewProfileComponent,
    NewProfileMobileComponent,
    ProfileCardComponent,
    ProfileCardMobileComponent,
    ProfileFormMobileComponent,

    // Profiles Page
    ProfilesPageDesktopComponent,
    ProfilesPageMobileComponent,
    ProfilesPageWrapperComponent,

    // Profile Details Page
    ProfilePageDesktopComponent,
    ProfilePageMobileComponent,
    ProfilePageWrapperComponent,

    ShadchanBaseMobileComponent,
    TehillimPageComponent
  ],
  imports: [
    AgGridModule,
    AppRoutingModule,
    AuthModule.forRoot({
      ...AUTH_CONFIG,
      guardConfig: CAN_ACTIVATE_ROUTE_GUARD_CONFIG
    }),
    BrowserModule.withServerTransition({ appId: 'shadchan-app' }),
    BuyerModule,
    CommonModule,
    CoreModule,
    FormsModule,
    HotTableModule,
    HttpClientModule,
    NgZorroAntdMobileModule,
    OkaCommonModule,
    ReactiveFormsModule,
    // ServiceWorkerModule.register('/../ngsw-worker.js', { enabled: environment.production }),

    /* USER-TYPE MODULES */
    AccountantModule,
    AdminModule,
    WarehouseModule,

    /* MODALS */
    CreateOrEditActiveDealModalModule,
    CreateNewProductModalModule,
    CreateNewProductCategoryModalModule,
    CreateNewUserModalModule,
    UpdateUserModalModule,
    GenerateChequeModalModule,
    BrowserAnimationsModule,

    // UI/UX Modules
    BrowserAnimationsModule,

    NzIconModule.forRoot(ngZorroIcons),
    NgzorroCommonModule,

    // Oka Modules
    OkaTablesModule,
    NonBuyerSharedModule
  ],
  providers: PROVIDERS,
  bootstrap: [AppComponent]
})
export class AppModule {}
