import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpResponse } from '../../../../../../../types/misc';
import {
  Product,
  ProductCreation,
  ProductUpdate
} from '../../../../../../../types/product';
import { environment } from '../../../../environments/environment';

const BASE_URL = environment.baseUrl + '' + environment.apiUrl + '/admin';

@Injectable({ providedIn: 'root' })
export class ProductService {
  constructor(private http: HttpClient) {}

  headers = {
    'Content-Type': 'application/json'
  };

  getProducts() {
    return this.http
      .get(BASE_URL + '/products')
      .pipe(map((p) => p as Product[]));
  }

  getProduct(upc: string) {
    return this.http
      .get(BASE_URL + '/product/' + upc)
      .pipe(map((p) => p as Product))
      .pipe(map((p) => p[0]));
  }

  createProduct(product: ProductCreation) {
    return this.http
      .put(BASE_URL + '/product', product)
      .pipe(
        catchError((err: { error: HttpResponse }) =>
          throwError(err.error.message)
        )
      );
  }

  synchronizeProductWithQuickbooks(product: Product) {
    //const productPruned = omit(product, 'quickbooks_last_sync', 'quickbooks_product_id');
    return this.http
      .put(BASE_URL + '/sync-product', product)
      .pipe(
        catchError((err: { error: HttpResponse }) =>
          throwError(err.error.message)
        )
      );
  }

  deleteProduct(productId: number) {
    return this.http
      .delete(BASE_URL + '/product/' + productId)
      .pipe(
        catchError((err: { error: HttpResponse }) =>
          throwError(err.error.message)
        )
      );
  }

  updateProduct(productId: number, payload: ProductUpdate) {
    return this.http
      .post(BASE_URL + '/product/' + productId.toString(), payload, {
        headers: this.headers
      })
      .pipe(
        catchError((err: { error: HttpResponse }) =>
          throwError(err.error.message)
        )
      );
  }
}
