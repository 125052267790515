import {
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import { ActionSheetService, en_US } from 'ng-zorro-antd-mobile';
import { SHARING_APPS } from '../../../../../../../common/constants';
import { Console } from 'console';
import { ActionPopoverBaseComponent } from '../action-popover/action-popover.component';

@Component({
  selector: 'action-popover-profile-page',
  // standalone: true,
  // imports: [],
  templateUrl: './profile-page-action-popover.component.html',
  styleUrl: './profile-page-action-popover.component.scss'
})
export class ProfilePagePopoverComponent extends ActionPopoverBaseComponent {
  @Output() onEditProfileAction = new EventEmitter<string>();
  @Output() onSaveProfileEditsAction = new EventEmitter<string>();
  @Output() onCancelProfileEditsAction = new EventEmitter<string>();
  @Output() onShowMatchmakingModalAction = new EventEmitter<string>();
  @Output() onShareAction = new EventEmitter<number>();

  isEditMode = false;

  appList = SHARING_APPS.map((obj) => ({
    icon: `<img src="assets/icons/${obj.name}.png" style="width:36px"/>`,
    title: obj.title,
    name: obj.name
  }));

  constructor(injector: Injector, private _actionSheet: ActionSheetService) {
    super(injector);
  }

  onSelect(event) {
    const buttonName = extractButtonName(event);
    let direction;
    if (buttonName.toUpperCase() === 'NAME') {
    } else if (buttonName.toUpperCase() === 'AGE') {
    }
  }

  onVisibleChange(event) {}

  showProfileActions(message) {
    let BUTTONS = [];
    if (this.isEditMode) {
      BUTTONS = ['Save Edits', 'Cancel Edits'];
    } else {
      BUTTONS = ['Redt a Shidduch', 'Edit Profile', 'Cancel', 'Delete'];
    }

    this._actionSheet.showActionSheetWithOptions(
      {
        options: BUTTONS,
        destructiveButtonIndex: BUTTONS.length - 1,
        cancelButtonIndex: BUTTONS.length - 2,
        title: 'Profile Options',
        locale: en_US,
        // message: message,
        maskClosable: true
      },
      (buttonIndex) => {
        if (buttonIndex >= 0) {
          if (BUTTONS[buttonIndex] === 'Edit Profile') {
            this.onEditProfileAction.emit();
          } else if (BUTTONS[buttonIndex] === 'Save Edits') {
            this.onSaveProfileEditsAction.emit();
          } else if (BUTTONS[buttonIndex] === 'Cancel Edits') {
            this.onCancelProfileEditsAction.emit();
          } else if (BUTTONS[buttonIndex] === 'Redt a Shidduch') {
            this.onShowMatchmakingModalAction.emit();
          }
        }
      }
    );
  }

  showShareActions = () => {
    console.log('Here 1?');
    this._actionSheet.showShareActionSheetWithOptions(
      {
        options: this.appList,
        message: 'Send Profile',
        locale: en_US
      },
      (buttonIndex) => {
        this.onShareAction.emit(buttonIndex);

        // return new Promise((resolve) => {
        //   setTimeout(resolve, 500);
        // });
      }
    );
  };
}

function extractButtonName(element: HTMLElement): string | null {
  // Find the element with class "am-popover-item-content" inside the given element
  const contentElement = element.querySelector('.am-popover-item-content');
  if (contentElement) {
    return contentElement.textContent.trim().toLocaleLowerCase();
  }
  return null;
}
