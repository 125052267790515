import { ActivatedRoute } from '@angular/router';
import { Injector, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RouteCustom } from './models/routes.types';

export class BasePage {
  isLoading = false;
  activatedRoute: ActivatedRoute;
  routeConfig: RouteCustom;
  childPages: string[];

  private isMobileSubject = new BehaviorSubject<boolean>(this.isMobileScreen());
  private ngZone: NgZone;
  isMobileScreen$: Observable<boolean> = this.isMobileSubject.asObservable();

  constructor(protected injector: Injector) {
    this.activatedRoute = injector.get(ActivatedRoute);
    this.ngZone = injector.get(NgZone);

    window.addEventListener('resize', () => {
      this.ngZone.run(() => {
        this.isMobileSubject.next(this.isMobileScreen());
      });
    });
  }

  isMobileScreen() {
    // TODO refactor into single function throughout the app
    return window.innerWidth < 700;
  }

  setChildPages() {
    if (!this.activatedRoute.snapshot.routeConfig.children) {
      this.activatedRoute.snapshot.routeConfig.children = [];
    }

    this.routeConfig = this.activatedRoute.snapshot.routeConfig;
    this.childPages = this.routeConfig.children
      .filter((i) => !i.hideFromNavBar)
      .filter((i) => i.path.length > 0)
      .filter((i) => i.path.indexOf('/') < 0)
      .map((i) => i.path);
  }
}
