import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  BuyerReportAll,
  BuyerItemReportFlat
} from '../../../../types-frontend/buyer';
import { NgZorroColumnHeaderInfo } from '../../../../types-frontend/ngzorro-types';
import {
  numberCompare,
  stringCompare,
  dateCompare
} from '../../../common/utils';
import { BuyerApiService } from '../services/buyer.api.service';
import { BaseUserPage } from '../../user-page';
@Component({
  selector: 'app-buyer-reports-page',
  templateUrl: './buyer-reports-page.component.html',
  styleUrls: ['./buyer-reports-page.component.scss']
})
export class BuyerReportsPageComponent extends BaseUserPage implements OnInit {
  cursor = '';

  columnDefs: NgZorroColumnHeaderInfo<BuyerReportAll>[] = [];

  rowData: BuyerItemReportFlat[] = [];

  productSerialFilter = '';

  constructor(
    protected injector: Injector,
    private buyerApiService: BuyerApiService,
    private nzMessageService: NzMessageService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.generateColumnDefs();
    this.load();
  }

  exportData(format: 'csv' | 'xls') {
    const rowHeaders = this.columnDefs.map((cd) => cd.name); //.join(',');

    const rowDataWithBlankFields: Omit<BuyerReportAll, 'description'>[] =
      this.rowData.map((data) => {
        return {
          product_serial: (data as any).product_serial || '',
          upc: (data as any).upc || '',
          sku: (data as any).sku || '',
          tracking_number: (data as any).tracking_number || '',
          created_at: (data as any).created_at || '',
          check_in_time: (data as any).check_in_time || '',
          purchase_order_id: (data as any).purchase_order_id || '',
          // hso_check_number: (data as any).hso_check_number || '',
          product_name: (data as any).product_name || '',
          // description: (data as any).description || '',
          price: (data as any).price || ''
        };
      });

    const rows = [
      rowHeaders,
      ...rowDataWithBlankFields.map((rd) => Object.values(rd))
    ];
    let csvContent =
      'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');
    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  load() {
    this.buyerApiService.getPackagedProductsReport().subscribe(
      (data: BuyerReportAll[]) => {
        if (data.length > 0) {
          this.rowData = data;
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  onCurrentPageDataChange() {}

  generateColumnDefs() {
    this.columnDefs = [
      {
        name: 'product_serial',
        sortFn: stringCompare<BuyerReportAll>('product_serial')
      },
      {
        name: 'upc',
        preferred_name: 'UPC',
        ...sortDetails('upc'),
        ...filterDetails()
      },
      {
        name: 'sku',
        preferred_name: 'SKU',
        ...sortDetails('sku'),
        ...filterDetails()
      },
      {
        name: 'tracking_number',
        ...sortDetails('tracking_number'),
        ...filterDetails()
      },
      {
        name: 'created_at',
        width: '280px',
        preferred_name: 'Tracking # Uploaded',
        ...sortDetails('created_at', 'date'),
        ...filterDetails()
      },
      {
        name: 'check_in_time',
        width: '280px',
        preferred_name: 'Checked In At',
        ...sortDetails('check_in_time', 'date'),
        ...filterDetails()
      },
      { name: 'purchase_order_id' },
      // { name: 'hso_check_number' },
      { name: 'product_name' },
      // { name: 'product_description', preferred_name: 'product_description', width: '460px' },
      { name: 'price', sortFn: numberCompare<BuyerReportAll>('price') }
    ];
  }

  getSortOrder(column: any) {
    if (column && column.sortOrder) {
      return column.sortOrder;
    } else {
      return null;
    }
  }

  resetFilter() {}

  searchFilter() {}
}

function sortDetails(
  propName: keyof BuyerReportAll,
  propType: 'string' | 'number' | 'date' = 'string',
  sortOrder = 'ascend'
) {
  return {
    sortFn:
      propType === 'string'
        ? stringCompare<BuyerReportAll>(propName)
        : propType === 'number'
        ? numberCompare<BuyerReportAll>(propName)
        : dateCompare<BuyerReportAll>(propName),
    sortOrder
  };
}

function filterDetails() {
  return {
    listOfFilter: [{ text: 'yy', value: 'yy' }],
    filterFn: () => false
  };
}
