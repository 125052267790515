import { Component, Input } from '@angular/core';
import { EditableField } from '../models/core.types';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'core-item-details-page-desktop',
  templateUrl: './item-details-page-desktop.component.html',
  styleUrl: './item-details-page-desktop.component.scss'
})
export class ItemDetailsPageDesktopComponent {
  @Input() headerTitle: string;
  @Input() headerSubtitle: string;

  editingTitle = false;
  editingSubtitle = false;

  saveItemInfo() {}

  handlePhotoChange(event) {}
}
