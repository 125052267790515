<nz-card>
  <nz-carousel>
    <div nz-carousel-content *ngFor="let photo of photos">
      <img [src]="photo" style="width: 100%" />
    </div>
  </nz-carousel>
  <nz-upload
    [nzAction]="uploadUrl"
    [nzFileList]="fileList"
    (nzChange)="handlePhotoChange($event)"
  >
    <button nz-button><i nz-icon nzType="upload"></i>Add Photo</button>
  </nz-upload>
</nz-card>
