import { Component, Injector, OnInit } from '@angular/core';
import { PurchaseOrderForAdminReports } from '../../../../../../types/purchase-order';
import { AbstractOkaTable } from '../abstract-oka-table';

@Component({
  selector: 'oka-purchase-orders-tables',
  templateUrl: './purchase-orders-tables.component.html',
  styleUrls: ['../oka-tables-styles.scss']
})
export class PurchaseOrdersTablesComponent
  extends AbstractOkaTable<PurchaseOrderForAdminReports>
  implements OnInit
{
  currentSearchColumn: keyof PurchaseOrderForAdminReports = 'purchase_order_id';

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
